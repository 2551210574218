import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { ConversationStateType } from '../types'

/**
 * TODO: Deprecate this entirely. No reason for this to exist
 * - Move over to react-query, reduce reliance on separate contexts, rely on RQ's context
 *
 *
 * @date 23/11/2023 - 09:05:26
 *
 * @typedef {ConversationContextProps}
 */
type ConversationStateContextType = {
  getConversationState: (conversationId: string) => ConversationStateType
}

type ConversationUpdaterContextType = {
  updateConversationState: ({
    conversationId,
    update,
  }: {
    conversationId: string
    update: Partial<ConversationStateType>
  }) => void
}

const ConversationStateContext = createContext<
  ConversationStateContextType | undefined
>(undefined)
const ConversationUpdaterContext = createContext<
  ConversationUpdaterContextType | undefined
>(undefined)

export const ConversationStateProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [chatsState, setChatsState] = useState(
    new Map<string, ConversationStateType>(),
  )

  const getConversationState = useCallback(
    (conversationId: string): ConversationStateType => {
      return (
        chatsState.get(conversationId) ?? {
          isAppendLoading: false,
          isStreaming: false,
          errorMessage: null,
        }
      )
    },
    [chatsState],
  )

  const updateConversationState = useCallback(
    ({
      conversationId,
      update,
    }: {
      conversationId: string
      update: Partial<ConversationStateType>
    }) => {
      setChatsState((prevState) => {
        const newState = new Map(prevState)
        const existingState = newState.get(conversationId) || {
          isAppendLoading: false,
          isStreaming: false,
          errorMessage: null,
        }
        newState.set(conversationId, { ...existingState, ...update })
        return newState
      })
    },
    [],
  )

  const stateValue = useMemo(
    () => ({ getConversationState }),
    [getConversationState],
  )
  const updaterValue = useMemo(
    () => ({ updateConversationState }),
    [updateConversationState],
  )

  return (
    <ConversationStateContext.Provider value={stateValue}>
      <ConversationUpdaterContext.Provider value={updaterValue}>
        {children}
      </ConversationUpdaterContext.Provider>
    </ConversationStateContext.Provider>
  )
}

export const useConversationState = () => {
  const context = useContext(ConversationStateContext)
  if (context === undefined) {
    throw new Error(
      'useConversationState must be used within a ConversationStateProvider',
    )
  }
  return context
}

export const useConversationUpdater = () => {
  const context = useContext(ConversationUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useConversationUpdater must be used within a ConversationStateProvider',
    )
  }
  return context
}

export const useConversationContext = () => {
  return {
    ...useConversationState(),
    ...useConversationUpdater(),
  }
}
