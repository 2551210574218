import { BiCheck, BiLink } from 'react-icons/bi'
import {
  Button,
  Input,
  InputGroup,
  useClipboard,
  VStack,
} from '@chakra-ui/react'

import { TruncatedAssistantDto } from '~shared/types/assistants.dto'

import { generateAssistantSharingLinkFromId } from '../utils'

type AssistantsCopyContentProps = {
  assistant: TruncatedAssistantDto
}

export const AssistantsShareLinkContent = ({
  assistant,
}: AssistantsCopyContentProps) => {
  const shareLink = generateAssistantSharingLinkFromId(assistant?.id ?? '')

  const { onCopy, hasCopied } = useClipboard(shareLink)

  return (
    <VStack alignItems="center">
      <InputGroup size="xs" color="interaction.support.selected">
        <Input isReadOnly value={shareLink} />
      </InputGroup>
      {hasCopied ? (
        <Button
          width="100%"
          size="xs"
          color="base.content.inverse"
          leftIcon={<BiCheck />}
          isDisabled
        >
          Link copied!
        </Button>
      ) : (
        <Button
          width="100%"
          size="xs"
          color="base.content.inverse"
          leftIcon={<BiLink />}
          onClick={onCopy}
        >
          Copy link
        </Button>
      )}
    </VStack>
  )
}
