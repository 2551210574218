import { useQuery } from '@tanstack/react-query'

import { ListGroupedAssistantsResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

const listGroupedAssistants = async () => {
  return ApiService.get<ListGroupedAssistantsResponseDto>('/assistants').then(
    (response) => {
      return response.data
    },
  )
}

export function useListGroupedAssistants() {
  return useQuery(queries.assistants.list.queryKey, listGroupedAssistants, {
    select: (data) => data.assistants,
    refetchOnWindowFocus: false,
  })
}
