import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const Draft = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.1708 10.5395C19.1106 9.61428 19.8085 8.81399 20.7333 8.7478L35.81 7.6687C36.2574 7.63668 36.6992 7.78422 37.0375 8.07869L43.2955 13.5249C43.6348 13.8201 43.842 14.2387 43.8712 14.6875L45.224 35.4701C45.2845 36.3995 44.5801 37.202 43.6507 37.2625L22.6891 38.6269C21.7597 38.6874 20.9572 37.983 20.8967 37.0536L19.1708 10.5395Z"
      fill="#386EFE"
    />
    <path
      d="M39.0937 13.5732L43.7477 13.8562C41.6099 11.8818 38.8078 9.42344 36.5117 7.61816L37.1996 11.9608C37.2617 12.9149 38.1102 13.6372 39.0937 13.5732Z"
      fill="#1A2644"
    />
    <path
      d="M25.0344 23.0211L39.3729 22.1486C39.5963 22.135 39.7884 22.3051 39.802 22.5285L39.8098 22.6579C39.8234 22.8813 39.6534 23.0734 39.43 23.087L25.0915 23.9595C24.8681 23.9731 24.676 23.803 24.6624 23.5796L24.6545 23.4502C24.6409 23.2268 24.811 23.0347 25.0344 23.0211Z"
      fill="#B6D6F1"
    />
    <path
      d="M24.7336 18.7399L39.0721 17.8674C39.2955 17.8538 39.4876 18.0239 39.5012 18.2472L39.5091 18.3767C39.5226 18.6001 39.3526 18.7922 39.1292 18.8058L24.7907 19.6783C24.5673 19.6918 24.3752 19.5218 24.3616 19.2984L24.3538 19.169C24.3402 18.9456 24.5102 18.7535 24.7336 18.7399Z"
      fill="#B6D6F1"
    />
    <path
      d="M25.0129 27.6274L39.3723 26.5347C39.596 26.5177 39.7911 26.6848 39.8081 26.908L39.8179 27.0373C39.8349 27.2604 39.6673 27.4551 39.4436 27.4721L25.0842 28.5648C24.8605 28.5818 24.6654 28.4147 24.6484 28.1916L24.6386 28.0623C24.6216 27.8391 24.7892 27.6444 25.0129 27.6274Z"
      fill="#B6D6F1"
    />
    <path
      d="M25.3659 20.0904C25.079 19.9965 24.8083 19.8728 24.5569 19.7244C24.2113 19.5214 23.9008 19.2706 23.6323 18.9752L24.6222 18.5078C25.1327 18.2667 25.5944 18.5115 25.5261 18.9869L25.3679 20.0886L25.3659 20.0904Z"
      fill="#1A2644"
    />
    <path
      d="M20.1699 20.6102L20.1358 20.6544C19.8699 24.0477 22.9995 24.2669 24.6189 23.9228L24.727 23.8079L24.8453 23.6466L25.3529 20.0954C25.0666 19.9998 24.7953 19.8779 24.544 19.7294C24.1989 19.5247 23.8879 19.2756 23.6193 18.9802L20.1699 20.6102Z"
      fill="white"
    />
    <path
      d="M13.1599 38.6185L24.7604 23.7382L24.7608 23.7365C24.0995 23.3781 23.998 22.5557 23.998 22.5557C23.998 22.5557 22.9065 23.0439 22.0026 22.5112C21.0987 21.9784 21.5085 20.7828 21.5085 20.7828C21.5085 20.7828 20.5147 20.9515 20.091 20.6591L8.49508 35.534C8.70851 36.8817 9.94028 39.3853 13.1599 38.6185Z"
      fill="#1A2644"
    />
    <rect
      x="5.33203"
      y="8"
      width="8.43471"
      height="5.06083"
      rx="0.843471"
      fill="#B6D6F1"
    />
    <rect
      x="5.33203"
      y="15.5908"
      width="8.43471"
      height="5.06083"
      rx="0.843471"
      fill="#B6D6F1"
    />
    <path
      d="M13.7656 10.9521H15.4526V14.7478M15.4526 14.7478H19.332M15.4526 14.7478V18.1217H13.7656"
      stroke="#1A2644"
      strokeWidth="0.421735"
    />
  </svg>
))
