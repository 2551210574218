import { useCallback } from 'react'

import {
  SharedAssistantDto,
  TruncatedAssistantDto,
} from '~shared/types/assistants.dto'

import {
  ASSISTANT_NO_LONGER_AVAILABLE_MESSAGE,
  EXCEEDS_TOKEN_LIMITS_ERROR_MESSAGE,
  INTERNAL_SERVER_ERROR_MESSAGE,
  LLM_STREAM_TIMEOUT,
  RATE_LIMIT_ERROR_MESSAGE,
} from '~constants/errors'

import { ConversationStateType } from '../types'

export function useChatErrorHandler({
  id,
  assistant,
  updateConversationState,
  chatOrPlaceholderId,
}: {
  id?: string
  assistant: TruncatedAssistantDto | SharedAssistantDto | null
  updateConversationState: ({
    conversationId,
    update,
  }: {
    conversationId: string
    update: Partial<ConversationStateType>
  }) => void
  chatOrPlaceholderId: string
}) {
  return useCallback(
    ({
      finishReason,
      errorCode,
    }: {
      finishReason?: 'timeout' | 'error'
      errorCode?: number
    }) => {
      let errorMessage =
        finishReason == 'timeout'
          ? LLM_STREAM_TIMEOUT
          : INTERNAL_SERVER_ERROR_MESSAGE
      switch (errorCode) {
        case 400:
          errorMessage = EXCEEDS_TOKEN_LIMITS_ERROR_MESSAGE
          break
        case 413:
          errorMessage = EXCEEDS_TOKEN_LIMITS_ERROR_MESSAGE
          break
        case 429:
          errorMessage = RATE_LIMIT_ERROR_MESSAGE
          break
        default: {
          // TODO: In addition to this error, we should indicate to the user
          // somehow that this assistant is no longer shared with it or
          // has been deleted
          // For existing chats with an assistant, if the assistant
          // is no longer available (i.e. deleted 404 or no longer shared with
          // user 403) return this error instead
          if (assistant && id && (errorCode === 403 || errorCode === 404)) {
            errorMessage = ASSISTANT_NO_LONGER_AVAILABLE_MESSAGE
          }
        }
      }

      updateConversationState({
        conversationId: chatOrPlaceholderId,
        update: {
          errorMessage,
          isAppendLoading: false,
          isStreaming: false,
        },
      })

      throw new Error(errorMessage)
    },
    [assistant, updateConversationState, id, chatOrPlaceholderId],
  )
}
