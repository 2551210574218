import {
  isAllowedToAttemptLoginEmailSuffix,
  isGovSgEmail,
  isHealthcareEmail,
} from '../decorators/is-gov-sg-email'

export function getEmailDomain(email: string) {
  if (isAllowedToAttemptLoginEmailSuffix(email)) return email.split('@')[1]
  throw new Error(`Failed to extract domain from ${email}`)
}

export function isCustomDomainSharingEnabled(email: string) {
  return isGovSgEmail(email) || isHealthcareEmail(email)
}
