import { ReactNode } from 'react'
import { BiAlignLeft } from 'react-icons/bi'
import { BeatLoader } from 'react-spinners'
import {
  Avatar,
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { Tag } from '@opengovsg/design-system-react'
import { startCase } from 'lodash'

import { ChatMessageDto } from '~shared/types/chats.dto'

import { useAuth } from '~lib/auth'
import { PairAvatarLogo } from '~components/Icons/brand/PairAvatarLogo'

import { DocumentTag } from '../DocumentTag'

import { ChatMessageSources } from './ChatMessageSources'
import { ChatMessageToolbar } from './ChatMessageToolbar'
import { MarkdownChatContent } from './MarkdownChatContent'

type ChatMessageProps = Pick<
  ChatMessageDto,
  'id' | 'author' | 'content' | 'sources' | 'tool' | 'documents'
> & {
  isLoading?: boolean
  defaultAiAvatar?: ReactNode
  isPreview?: boolean
  isFadedText?: boolean
  assistantId?: string
}

export const ChatMessage = ({
  id,
  author,
  content,
  documents,
  isLoading = false,
  tool,
  sources,
  defaultAiAvatar,
  assistantId,
  isPreview = false,
  isFadedText = false,
}: ChatMessageProps) => {
  const { user } = useAuth()

  const isAi = author === 'assistant'

  return (
    <HStack
      width="100%"
      paddingY="24px"
      spacing="16px"
      alignItems="stretch"
      {...(!isPreview && {
        paddingLeft: { base: '36px', sm: '60px', md: '80px', lg: '120px' },
        paddingRight: { base: '16px', sm: '40px', md: '60px', lg: '100px' },
      })}
      {...(!isPreview && isAi && { backgroundColor: 'brand.secondary.100' })}
    >
      <Box paddingTop="2px">
        {isAi ? (
          defaultAiAvatar || (
            <Avatar
              backgroundColor="transparent"
              icon={<PairAvatarLogo width="24px" />}
              size="2xs"
            />
          )
        ) : (
          <Avatar
            name={user?.email || 'User'}
            backgroundColor={'interaction.main.default'}
            size="2xs"
          />
        )}
      </Box>
      <HStack
        width="100%"
        alignItems="flex-start"
        justifyContent="space-between"
        overflow="hidden"
      >
        <VStack
          alignItems="flex-start"
          flex={1}
          overflow="hidden"
          spacing="16px"
        >
          {isLoading ? (
            <BeatLoader size="10px" />
          ) : (
            <Box
              {...(isFadedText && {
                color: 'base.content.default',
                opacity: '50%',
              })}
              width="100%"
            >
              {isAi && tool !== 'llm' && (
                <Tag
                  size="md"
                  color="interaction.sub.default"
                  backgroundColor="interaction.sub-subtle.default"
                  colorScheme="interaction.sub"
                  textStyle="caption-1"
                >
                  <HStack spacing="4px">
                    <Icon as={BiAlignLeft} boxSize="18px" />
                    <Text>Answer · {startCase(tool)}</Text>
                  </HStack>
                </Tag>
              )}
              {isAi ? (
                <MarkdownChatContent content={content} />
              ) : (
                <VStack alignItems="start" width="100%">
                  <Text whiteSpace="pre-wrap" textStyle="body-2">
                    {content}
                  </Text>
                  {documents && (
                    <Wrap>
                      {documents.map((document, index) => {
                        return (
                          <WrapItem key={index}>
                            <DocumentTag documentName={document.name} />
                          </WrapItem>
                        )
                      })}
                    </Wrap>
                  )}
                </VStack>
              )}
              {sources && sources.length > 0 && isAi && (
                <ChatMessageSources sources={sources} />
              )}
            </Box>
          )}
        </VStack>

        {/* Given the state of previews, the preview component should be a separated feature entirely */}
        {!isPreview && (
          // We use this way of hiding it so that it still takes up space
          // even for non AI messages, so that both the user and AI message
          // will have the same right alignment.
          <Box
            {...(isAi ? {} : { height: '0px' })}
            visibility={isAi ? 'visible' : 'hidden'}
            pointerEvents={isAi ? 'auto' : 'none'}
            aria-hidden={!isAi}
          >
            <ChatMessageToolbar
              messageId={id}
              textToCopy={content}
              assistantId={assistantId}
            />
          </Box>
        )}
      </HStack>
    </HStack>
  )
}
