import { ReactNode } from 'react'
import { Avatar, Box, HStack, Text } from '@chakra-ui/react'

import { PairAvatarLogo } from '~components/Icons/brand/PairAvatarLogo'

export interface ErrorMessageProps {
  content: string
  defaultAiAvatar?: ReactNode
  isPreview?: boolean
}

export const ErrorMessage = ({
  content,
  defaultAiAvatar,
  isPreview = false,
}: ErrorMessageProps) => {
  return (
    <HStack
      width="100%"
      paddingY="24px"
      spacing="24px"
      alignItems="stretch"
      {...(!isPreview && {
        backgroundColor: 'brand.secondary.100',
        paddingLeft: {
          base: '36px',
          sm: '60px',
          md: '80px',
          lg: '120px',
        },
        paddingRight: {
          base: '16px',
          sm: '40px',
          md: '60px',
          lg: '100px',
        },
      })}
    >
      {defaultAiAvatar || (
        <Avatar
          backgroundColor="transparent"
          icon={<PairAvatarLogo width="24px" />}
          size="2xs"
        />
      )}
      <Box
        overflowX="auto"
        backgroundColor="interaction.critical-subtle.default"
        border="1px solid"
        borderColor="interaction.critical.default"
        borderRadius="4px"
        paddingX="16px"
        paddingY="8px"
      >
        <Text textStyle="body-2" color="utility.feedback.critical">
          {content}
        </Text>
      </Box>
    </HStack>
  )
}
