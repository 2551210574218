import { useCallback, useEffect, useMemo, useState } from 'react'
import { BiArrowBack, BiChevronsRight } from 'react-icons/bi'
import { FaRegEye } from 'react-icons/fa'
import { unstable_useBlocker, useNavigate } from 'react-router-dom'
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Spinner,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, IconButton } from '@opengovsg/design-system-react'
import _ from 'lodash'

import {
  ASSISTANT_DOCUMENTS_CONTEXT_LIMIT,
  ASSISTANT_DOCUMENTS_COUNT_LIMIT,
} from '~shared/constants/documents'
import {
  CreateOrUpdateAssistantRequestDto,
  GetAssistantResponseDto,
} from '~shared/types/assistants.dto'

import { useAuth } from '~lib/auth'
import { IconButtonWithTooltip } from '~components/IconButtonWithTooltip'

import { useAssistantForm } from '~features/assistants/hooks/useAssistantForm'
import { useAssistantPreview } from '~features/assistants/hooks/useAssistantPreview'
import { useGetDocuments } from '~features/documents/hooks'

import { ParsedAssistantInputType } from '../../schema/assistant-schema'
import { AssistantForm } from '../AssistantsForm'
import { AssistantSubmitConfirmationDialog } from '../AssistantsForm/AssistantSubmitConfirmationDialog'
import { CloseConfirmationModal } from '../AssistantsForm/CloseConfirmationModal'

import { AssistantPreview } from './AssistantPreview'

// TODO: 20241018 This component is getting bloated, needs a refactor, ideally
// into a hook -
export const AssistantEditorScreen = ({
  onPublish,
  assistant,
  isPublishLoading,
}: {
  onPublish: (data: CreateOrUpdateAssistantRequestDto) => void
  assistant?: GetAssistantResponseDto
  isPublishLoading: boolean
}) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const isCreate = !assistant

  if (!user?.email) {
    throw new Error('Unexpected user error has occurred')
  }

  const {
    formMethods,
    watchedFields,
    transformFormData,
    isValidAssistantType,
    updatedFields,
  } = useAssistantForm({ assistant })

  const {
    formState,
    getValues: getFormValues,
    setValue: setFormValue,
    handleSubmit,
  } = formMethods

  const initialDocumentIds = useMemo(
    () => assistant?.documents.map((doc) => doc.id) ?? [],
    [assistant?.documents],
  )

  const {
    isOpen: isCloseConfirmationDialogOpen,
    onOpen: onCloseConfirmationDialogOpen,
    onClose: onCloseConfirmationDialogClose,
  } = useDisclosure()
  const {
    isOpen: isPublishConfirmationDialogOpen,
    onOpen: onPublishConfirmationDialogOpen,
    onClose: onPublishConfirmationDialogClose,
  } = useDisclosure()

  const {
    name,
    description,
    promptStarters,
    riskCategory,
    prompt,
    documentIds,
  } = watchedFields

  const previewChatInputs = useMemo(
    () => ({
      id: assistant?.id,
      name,
      description,
      promptStarters: promptStarters?.map((starter) => starter.value) ?? [],
      isHighRisk: !!riskCategory && riskCategory !== 'none',
      instructions: prompt,
      documents: documentIds ?? [],
    }),
    [
      assistant?.id,
      name,
      description,
      promptStarters,
      riskCategory,
      prompt,
      documentIds,
    ],
  )

  const [isIgnoreBlocker, setIsIgnoreBlocker] = useState(false)
  // Working as of 11 Oct 2024, should upgrade with caution.
  const blocker = unstable_useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !isIgnoreBlocker &&
      !isPublishConfirmationDialogOpen &&
      !isNoChanges,
  )

  const onConfirmClose = useCallback(() => {
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
    onCloseConfirmationDialogClose()
  }, [blocker, onCloseConfirmationDialogClose])

  const [uploadedDocumentIds, setUploadedDocumentIds] =
    useState<string[]>(initialDocumentIds)
  const {
    completedDocumentIds,
    isBlockSubmission: isBlockDocumentsSubmission,
    blockSubmissionMessage: blockDocumentsSubmissionMessage,
    isFetchedAfterMount: isDocumentStatusFetchedAfterMount,
  } = useGetDocuments({
    documentIds: uploadedDocumentIds,
    countLimit: ASSISTANT_DOCUMENTS_COUNT_LIMIT,
    tokenLimit: ASSISTANT_DOCUMENTS_CONTEXT_LIMIT,
  })

  const isNoChanges = !formState.isDirty
  const isFormDisabled = isBlockDocumentsSubmission || isNoChanges
  const formDisabledMessage = blockDocumentsSubmissionMessage
    ? blockDocumentsSubmissionMessage
    : 'Edit one or more fields'

  const {
    chatStateAndMutation,
    isChatMutating,
    debouncedRefreshPreviewChat,
    currentChatId,
    previewChatHistory,
    setCurrentChatId,
    setPreviewChatHistory,
  } = useAssistantPreview({ assistant })

  // TODO: figure out better way of keeping state in sync
  useEffect(() => {
    const currentIds = getFormValues('document_ids')
    const hasChangesToDocumentIds =
      currentIds.length !== completedDocumentIds.length ||
      _.difference(currentIds, completedDocumentIds).length !== 0
    if (hasChangesToDocumentIds && isDocumentStatusFetchedAfterMount) {
      // Only set shouldDirty to true when there's an actual change
      setFormValue('document_ids', completedDocumentIds, {
        shouldDirty: true,
      })
      debouncedRefreshPreviewChat()
    }
  }, [
    setFormValue,
    getFormValues,
    completedDocumentIds,
    isDocumentStatusFetchedAfterMount,
    debouncedRefreshPreviewChat,
  ])

  // Keep track of all chats tied to this preview
  // So that on create assistant, we can set the assistant id for those
  // preview chats
  const [savedChatIds, setSavedChatIds] = useState<string[]>([])
  const resetChats = () => {
    const chatHistoryIds = _.map(
      previewChatHistory,
      (previewChat) => previewChat.id,
    )
    const existingChatIds = currentChatId
      ? [...chatHistoryIds, currentChatId]
      : [...chatHistoryIds]

    setSavedChatIds([...savedChatIds, ...existingChatIds])
    setCurrentChatId(undefined)
    setPreviewChatHistory([])
  }

  const handleFinalSubmit = useCallback(
    (data: ParsedAssistantInputType) => {
      // NOTE: This only serves for type narrowing
      if (!isValidAssistantType(data)) {
        throw new Error('Risk Category is not provided')
      }

      const chatHistoryIds = _.map(
        previewChatHistory,
        (previewChat) => previewChat.id,
      )
      const allPreviewChatIds = [
        ...savedChatIds,
        ...chatHistoryIds,
        ...(currentChatId ? [currentChatId] : []),
      ]
      onPublish({
        ...data,
        // We only assign preview chats on create, i.e. when assistant
        // is undefined.
        preview_chat_ids: isCreate ? allPreviewChatIds : undefined,
      })
    },
    [
      onPublish,
      isCreate,
      previewChatHistory,
      currentChatId,
      savedChatIds,
      isValidAssistantType,
    ],
  )

  const onPublishAssistantClick = handleSubmit((data) => {
    if (isCreate) {
      setIsIgnoreBlocker(true)
      handleFinalSubmit(transformFormData(data))
    } else {
      onPublishConfirmationDialogOpen()
    }
  })

  if (blocker.state === 'blocked' && !isCloseConfirmationDialogOpen) {
    onCloseConfirmationDialogOpen()
  }

  if (!user) {
    return (
      <Center height="100%" flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <>
      <Flex direction="column" height="full">
        <Flex
          borderBottom="1px"
          borderColor="base.divider.medium"
          shadow="sm"
          justifyContent="space-between"
          align="center"
          width="full"
          px="24px"
          py="4px"
          zIndex={1}
        >
          <IconButton
            icon={<BiArrowBack />}
            aria-label="back"
            variant="clear"
            color="interaction.neutral"
            onClick={() => navigate(-1)}
          />
          <HStack spacing="16px">
            {isMobile && (
              <IconButtonWithTooltip
                icon={<FaRegEye fontSize="20px" />}
                aria-label="View live preview"
                variant="clear"
                onClick={onDrawerOpen}
              />
            )}
            <Tooltip
              label={formDisabledMessage}
              hasArrow
              closeDelay={200}
              placement={'bottom'}
              isDisabled={!isFormDisabled}
            >
              <Button
                color="white"
                size="xs"
                my="10px"
                onClick={() => {
                  void onPublishAssistantClick()
                }}
                isDisabled={isFormDisabled || isPublishLoading}
              >
                {isCreate ? 'Create' : 'Update'}
              </Button>
            </Tooltip>
          </HStack>
        </Flex>
        <Flex overflowY="hidden">
          <Flex
            overflowY="auto"
            justify="center"
            paddingY="32px"
            paddingX="40px"
            scrollBehavior="smooth"
            maxWidth={isMobile ? '100%' : '50%'}
            width={isMobile ? '100%' : '50%'}
          >
            <AssistantForm
              formMethods={formMethods}
              {...(assistant && {
                assistant: {
                  ...assistant,
                  document_ids: assistant.documents.map((doc) => doc.id),
                },
              })}
              uploadedDocumentIds={uploadedDocumentIds}
              setUploadedDocumentIds={setUploadedDocumentIds}
              isInputDisabled={isChatMutating}
              onBehaviorUpdate={debouncedRefreshPreviewChat}
            />
          </Flex>
          {!isMobile && (
            <Flex justify="center" maxWidth="50%" width="50%">
              <AssistantPreview
                chatStateAndMutation={chatStateAndMutation}
                assistantId={assistant?.id}
                previewChatInputs={previewChatInputs}
                // TODO 20241018:
                // We have isBlockSubmission, isSubmissionDisabled,
                // isDisabledSubmission, we should standardize our naming
                isBlockSubmission={isBlockDocumentsSubmission}
                blockSubmissionMessage={blockDocumentsSubmissionMessage}
                resetChats={resetChats}
              />
            </Flex>
          )}
          <AssistantSubmitConfirmationDialog
            isOpen={isPublishConfirmationDialogOpen}
            onClose={onPublishConfirmationDialogClose}
            updatedFields={updatedFields}
            onSubmit={() => {
              handleFinalSubmit(transformFormData(getFormValues()))
              if (blocker.state === 'blocked') blocker.proceed()
            }}
          />
        </Flex>
      </Flex>

      {isMobile && (
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={onDrawerClose}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent overflowY="hidden" height="100%">
            <Box position="absolute" top="16px" left="16px" zIndex="drawer">
              <IconButton
                icon={<BiChevronsRight />}
                aria-label="Close drawer"
                onClick={onDrawerClose}
                variant="clear"
                size="xs"
              />
            </Box>
            <Flex height="100%">
              <AssistantPreview
                chatStateAndMutation={chatStateAndMutation}
                assistantId={assistant?.id}
                previewChatInputs={previewChatInputs}
                // TODO 20241018:
                // We have isBlockSubmission, isSubmissionDisabled,
                // isDisabledSubmission, we should standardize our naming
                isBlockSubmission={isBlockDocumentsSubmission}
                blockSubmissionMessage={blockDocumentsSubmissionMessage}
                resetChats={resetChats}
              />
            </Flex>
          </DrawerContent>
        </Drawer>
      )}

      <CloseConfirmationModal
        isOpen={isCloseConfirmationDialogOpen}
        onClose={() => {
          if (blocker.state === 'blocked') blocker.reset()
          onCloseConfirmationDialogClose()
        }}
        onConfirm={onConfirmClose}
      />
    </>
  )
}
