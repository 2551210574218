import { ElementRef, useCallback } from 'react'
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DrawerCloseButton } from '@opengovsg/design-system-react'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { DAY_MONTH_YEAR_TIME_AM_PM, formatTime } from '~utils/date'

import { useGetAssistantFeedbackMessageLogs } from '~features/assistants/hooks'

import { AssistantDrawerHeader } from './AssistantFeedbackDrawerHeader'
import { AssistantFeedbackMessageReport } from './AssistantFeedbackMessageReport'
import { formatMessageLogs } from './helper'
import { AssistantFeedbacks } from './types'

type AssistantFeedbackDrawerProps = {
  isOpen: boolean
  onClose: () => void
} & CamelCasedPropertiesDeep<AssistantFeedbacks>

// Rating is not used now since there is only thumbs down
export const AssistantFeedbackDrawer = ({
  isOpen,
  onClose,
  id,
  messageId,
  assistantId,
  hashedUserId,
  numericRating,
  isAllowCreatorToView,
  content: feedbackContent,
  createdAt,
  feedbackType,
  message,
  email,
}: AssistantFeedbackDrawerProps) => {
  const isAssistantLevelFeedback = feedbackType === 'assistant_level'
  const isMessageLevelFeedback = feedbackType === 'message_level'
  const {
    assistantFeedbackMessageLogs,
    isLoadingAssistantFeedbackMessageLogs,
  } = useGetAssistantFeedbackMessageLogs({
    feedbackId: id,
    assistantId,
    enabled: isAllowCreatorToView,
  })

  const messageInfo = [
    {
      label: 'Date and Time',
      value: formatTime({
        timestamp: createdAt,
        dateFormat: DAY_MONTH_YEAR_TIME_AM_PM,
      }),
    },
    {
      label: 'User ID',
      value: hashedUserId,
    },
    {
      label: 'User Email',
      value: email,
    },
  ]

  // Callback ref to auto scroll to bottom for drawer body
  const drawerBodyRef = useCallback(
    (node: ElementRef<'div'>) => {
      // Only for message level feedback, we want to scroll to the exact message that was reported
      // For assistant level feedback, the feedback content is more important
      if (node && isMessageLevelFeedback) {
        node.scrollTo(0, node.scrollHeight)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingAssistantFeedbackMessageLogs, assistantFeedbackMessageLogs],
  )

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent width="768px" minW="768px">
        <DrawerCloseButton />
        <DrawerHeader paddingBottom={0}>
          <AssistantDrawerHeader
            isAssistantLevelFeedback={isAssistantLevelFeedback}
            isMessageLevelFeedback={isMessageLevelFeedback}
            numericRating={numericRating}
          />
          <HStack spacing="64px" width="100%" align="start" marginTop="16px">
            {messageInfo.map(({ label, value }) => (
              <Box key={label}>
                <Text fontSize="0.625rem" lineHeight="1.375rem" color="#5E748E">
                  {label}
                </Text>
                <Text textStyle="subhead-2" color="base.content.strong">
                  {value}
                </Text>
              </Box>
            ))}
          </HStack>
          <Divider
            borderColor="base.divider.medium"
            marginTop="12px"
            marginBottom="16px"
          />
        </DrawerHeader>

        <DrawerBody ref={drawerBodyRef} paddingTop={0}>
          <VStack alignItems="stretch" spacing="12px" width="100%">
            <VStack spacing={0} height="100%" overflowY="auto" width="100%">
              {isAssistantLevelFeedback && (
                <Box width="full">
                  <Text color="base.content.strong" textStyle="subhead-2">
                    Feedback
                  </Text>
                  <Text
                    color="base.content.default"
                    textStyle="body-2"
                    lineHeight="1.5rem"
                    letterSpacing="-0.011em"
                  >
                    {feedbackContent || '-'}
                  </Text>
                </Box>
              )}

              {isLoadingAssistantFeedbackMessageLogs && <Spinner size="xl" />}
              {!isLoadingAssistantFeedbackMessageLogs &&
                isAllowCreatorToView &&
                assistantFeedbackMessageLogs?.message_logs && (
                  <>
                    {/* This header section is only required in cases where we have a feedback section */}
                    {isAssistantLevelFeedback && (
                      <Box width="full" marginTop="24px !important">
                        <Text textStyle="subhead-2" color="base.content.strong">
                          View chat log
                        </Text>
                        <Divider
                          borderColor="base.divider.medium"
                          marginTop="12px"
                        />
                      </Box>
                    )}
                    {formatMessageLogs(
                      assistantFeedbackMessageLogs.message_logs,
                    ).map(({ content, id, isAi, documentNames }) => {
                      return (
                        <AssistantFeedbackMessageReport
                          key={id}
                          isAi={isAi}
                          // TODO (@sheikh): figure out what to fill up
                          email={isAi ? undefined : 'User'}
                          content={content}
                          feedback={feedbackContent}
                          documentNames={documentNames}
                          isMessageLevelFeedback={isMessageLevelFeedback}
                          isOffendingMessage={messageId === id}
                        />
                      )
                    })}
                  </>
                )}

              {isMessageLevelFeedback && !isAllowCreatorToView && (
                <AssistantFeedbackMessageReport
                  key={id}
                  isAi={true}
                  email={undefined}
                  content={message.responseData}
                  feedback={feedbackContent}
                  isMessageLevelFeedback={isMessageLevelFeedback}
                  isOffendingMessage={true}
                />
              )}
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
