import { Navigate, Route, Routes } from 'react-router-dom'

import { PublicElement } from '~/app/PublicElement'

import { routes } from '~constants/routes'

import { BasePage } from './basePage'
import { FaqPage } from './faqPage'
import { LandingPage } from './landingPage'
import { NotFoundPage } from './notFoundPage'
import { PrivacyPage } from './privacyPage'
import { TermsOfUsePage } from './termsOfUsePage'

export const StaticPagesRoutes = () => {
  return (
    <Routes>
      <Route index element={<PublicElement element={<LandingPage />} />} />
      <Route
        element={<PublicElement element={<BasePage />} />}
        errorElement={<Navigate to="/" />}
      >
        <Route path={routes.faq} element={<FaqPage />} />
        <Route path={routes.privacy} element={<PrivacyPage />} />
        <Route path={routes.termsOfUse} element={<TermsOfUsePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export const DisabledStaticPagesRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={routes.login} />} />
    </Routes>
  )
}
