import { ReactNode } from 'react'
import { IconType } from 'react-icons'
import { HStack, Icon, MenuItem, MenuItemProps, Text } from '@chakra-ui/react'

interface SidebarNavigationMenuItemProps
  extends Exclude<MenuItemProps, 'children' | 'icon' | 'iconSpacing'> {
  leftIcon: IconType
  text: string
  rightElement?: ReactNode
}

export const SidebarNavigationMenuItem = ({
  leftIcon,
  closeOnSelect = true,
  text,
  rightElement,
  onClick,
  ...props
}: SidebarNavigationMenuItemProps) => {
  return (
    <MenuItem
      {...props}
      onClick={onClick}
      closeOnSelect={closeOnSelect}
      textStyle="body-1"
    >
      <HStack width="100%" alignItems="center" spacing="16px">
        <Icon boxSize="20px" as={leftIcon} />
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <Text>{text}</Text>
          {rightElement}
        </HStack>
      </HStack>
    </MenuItem>
  )
}
