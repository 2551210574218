import { IconBaseProps } from 'react-icons'
import { BiSolidBot } from 'react-icons/bi'
import { Avatar, AvatarProps } from '@chakra-ui/react'

type AssistantAvatarProps = AvatarProps & {
  avatarSize: '2xs' | 'md'
  iconProps?: IconBaseProps
}

const sizeToPropMap = {
  '2xs': {
    iconFontSize: '12px',
  },
  md: {
    iconFontSize: '24px',
  },
}

export const AssistantAvatar = ({
  avatarSize,
  iconProps = {},
  ...avatarProps
}: AssistantAvatarProps) => {
  return (
    <Avatar
      size={avatarSize}
      bgColor="blue.900"
      icon={
        <BiSolidBot
          fontSize={sizeToPropMap[avatarSize].iconFontSize}
          {...iconProps}
        />
      }
      {...avatarProps}
    />
  )
}
