import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

const parts = anatomy('pagination').parts(
  'button',
  'container',
  'separator',
  'stepper',
  'text',
)

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

// TODO: Check with rachel if she is fine with this styling
const baseButtonStyling = defineStyle({
  h: 'auto',
  border: 'none',
  borderRadius: 'base',
  cursor: 'pointer',
  alignSelf: 'center',
  color: 'base.content.default',
  bg: 'transparent',
  _activeLink: {
    bg: 'interaction.main.default',
    color: 'base.content.inverse',
    _hover: {
      bg: 'interaction.main.hover',
    },
    _disabled: {
      bg: 'interaction.support.disabled',
      color: 'interaction.support.disabled-content',
    },
  },
  _active: {
    bg: 'interaction.muted.neutral.active',
  },
  _hover: {
    bg: 'interaction.muted.neutral.hover',
  },
  _focusVisible: {
    boxShadow: `0 0 0 2px var(--chakra-colors-utility-focus-default)`,
  },
  _disabled: {
    cursor: 'not-allowed',
    color: 'interaction.support.disabled-content',
    _hover: {
      bg: 'transparent',
    },
  },
})

const baseStyle = definePartsStyle({
  button: baseButtonStyling,
})

export const Pagination = defineMultiStyleConfig({
  baseStyle,
})
