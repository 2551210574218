import { VStack } from '@chakra-ui/react'

import { useTrackedFlag } from '~lib/feature-flag'

import { IBasicEditor } from '../../types'

import { DefaultHero } from './components/DefaultHero'
import { PromotionalHero } from './components/PromotionalHero'
import { VanillaChatHeader } from './VanillaChatHeader'
import { VanillaPromptHighlights } from './VanillaPromptHighlights'

type EmptyScreenProps = {
  editor: IBasicEditor
}

export const EmptyScreen = ({ editor }: EmptyScreenProps) => {
  const { isEnabled: isPromoHeroEnabled } = useTrackedFlag(
    'promo_assistants_banner',
  )

  return (
    <VStack
      flexGrow={1}
      width="100%"
      paddingX="0px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
    >
      {isPromoHeroEnabled ? (
        <PromotionalHero />
      ) : (
        <>
          <VanillaChatHeader />
          <DefaultHero />
        </>
      )}
      <VanillaPromptHighlights
        onSelect={(content) => editor.setTemplate(content)}
      />
    </VStack>
  )
}
