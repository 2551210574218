import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

export const comboboxParts = anatomy('combobox').parts(
  'list',
  'item',
  'itemDescription',
  'highlight',
  'icon',
  'emptyItem',
)

export const parts = anatomy('singleselect')
  .parts(...comboboxParts.keys)
  .extend('field', 'selected')

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

// Mutating on the md style for current usecases
const sizes = {
  md: definePartsStyle(() => {
    return {
      item: {
        textStyle: 'body-2',
        color: 'base.content.strong',
      },
      list: {
        borderRadius: '4px',
      },
      selected: {
        textStyle: 'body-2',
        // Doesnt exist in our DS yet
        color: '#445072',
      },
    }
  }),
}
export const SingleSelect = defineMultiStyleConfig({
  sizes,
})
