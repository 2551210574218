import { useState } from 'react'
import { BiChevronLeft, BiLeftArrowAlt } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Center,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { FeedbackTypesFilterEnumType } from '~shared/types/assistant-feedback.dto'

import { textStyles } from '~/theme/foundations/textStyles'

import { routes } from '~constants/routes'
import { useTypedParams } from '~hooks/useTypedParams'

import { useAssistantFeedbacksPaginationController } from '~features/assistants/hooks'

import { AssistantFeedbacksStatistics } from '../components/AssistantFeedbacks/AssistantFeedbacksStatistics'
import { AssistantFeedbacksTable } from '../components/AssistantFeedbacks/AssistantFeedbacksTable'
import { FeedbackStatisticsNotAuthorizedToViewSvgr } from '../components/Image'
import {
  useGetAssistant,
  useGetAssistantFeedbacks,
  useGetAssistantFeedbackStatistics,
} from '../hooks'

export const AssistantFeedbacksPage = () => {
  const { assistantId } = useTypedParams(['assistantId'])

  // Logic related to routing
  const navigate = useNavigate()
  const location = useLocation()
  const fromLocation = (location as { state: { from?: string } }).state?.from

  const {
    data: assistant,
    isLoading: isGetAssistantLoading,
    isError: isGetAssistantError,
  } = useGetAssistant({
    assistantId,
  })

  const doesUserHaveAccessToAssistant = !isGetAssistantError

  const { data: feedbackStatistics, isLoading: isFeedbackStatisticsLoading } =
    useGetAssistantFeedbackStatistics({
      assistantId,
      enabled: doesUserHaveAccessToAssistant,
    })

  const {
    page,
    pageSize,
    feedbackTypesFilter: searchParamFeedbackTypeFilter,
    handlePaginationClick,
  } = useAssistantFeedbacksPaginationController({
    allowDynamicPageSize: false,
  })
  const [feedbackTypesFilter, setFeedbackTypesFilter] =
    useState<FeedbackTypesFilterEnumType>(searchParamFeedbackTypeFilter)

  const {
    data: assistantFeedbacks,
    isLoading: isGetAssistantFeedbacksLoading,
  } = useGetAssistantFeedbacks({
    assistantId,
    page,
    pageSize,
    enabled: doesUserHaveAccessToAssistant,
    feedbackTypesFilter,
    handlePaginationClick,
  })

  if (isGetAssistantLoading) {
    return (
      <Center width="100vw" height="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <VStack height="100%" align="stretch" spacing="32px" bg="base.canvas.alt">
      <HStack
        width="100%"
        height="52px"
        alignItems="center"
        padding="8px 24px"
        borderBottom="1px solid"
        borderBottomColor="base.divider.medium"
        justifyContent="space-between"
        bg="white"
      >
        {doesUserHaveAccessToAssistant && (
          <Button
            {...textStyles['subhead-1']}
            height="24px"
            leftIcon={<Icon as={BiChevronLeft} fontSize="24px" />}
            variant="clear"
            color="base.content.strong"
            onClick={() => navigate(fromLocation || routes.assistants)}
          >
            Back
          </Button>
        )}
      </HStack>
      <Center width="100%">
        <Flex
          width="100%"
          paddingX={{
            base: '24px',
            md: '48px',
            lg: '120px',
            xl: '200px',
          }}
          align="stretch"
          flexDirection="column"
          bg="base.canvas.alt"
          paddingBottom="48px"
        >
          {!doesUserHaveAccessToAssistant && (
            <Center height="100%" width="100%">
              <VStack spacing="24px">
                <Text textStyle="h5" color="base.content.default">
                  You&apos;re not authorised to view this page
                </Text>
                <Button
                  leftIcon={<Icon as={BiLeftArrowAlt} fontSize="20px" />}
                  height="40px"
                  variant="outline"
                  onClick={() => navigate('/assistants')}
                >
                  Back to home
                </Button>
                <FeedbackStatisticsNotAuthorizedToViewSvgr />
              </VStack>
            </Center>
          )}

          {doesUserHaveAccessToAssistant && (
            <>
              <Text
                textStyle="h4"
                color="base.content.default"
                marginBottom="24px"
              >
                Feedback
              </Text>

              {isFeedbackStatisticsLoading && <Spinner size="xl" />}
              {!isFeedbackStatisticsLoading &&
                feedbackStatistics &&
                assistantFeedbacks && (
                  <AssistantFeedbacksStatistics
                    feedbackStatistics={feedbackStatistics}
                    feedbackTypeFilter={feedbackTypesFilter}
                    setFeedbackTypeFilter={setFeedbackTypesFilter}
                    handlePaginationClick={handlePaginationClick}
                    totalCount={assistantFeedbacks.total_count}
                  />
                )}

              {assistantFeedbacks && assistant && (
                <AssistantFeedbacksTable
                  assistantName={assistant.name}
                  assistantId={assistant.id}
                  isFetching={isGetAssistantFeedbacksLoading}
                  assistantFeedbacks={assistantFeedbacks.data}
                  filteredCount={assistantFeedbacks.filtered_count}
                  pageSize={pageSize}
                  currentPage={page}
                  handlePaginationClick={handlePaginationClick}
                  totalCount={assistantFeedbacks.total_count}
                />
              )}
            </>
          )}
        </Flex>
      </Center>
    </VStack>
  )
}
