import { VStack } from '@chakra-ui/react'
import {
  ComboboxItem,
  FormErrorMessage,
  SingleSelect,
  SingleSelectProps,
} from '@opengovsg/design-system-react'

import { RiskCategoryType } from '~shared/types/assistants.dto'
type AssistantRiskAcceptanceSelectorProps = Pick<
  SingleSelectProps,
  'name' | 'onChange' | 'value' | 'isDisabled'
> & {
  errorMessage?: string
  id?: string
}

const RISK_CATEGORY_ITEMS: ComboboxItem<RiskCategoryType>[] = [
  {
    value: 'health_safety',
    label: 'Medicine, health, or safety',
    description:
      'e.g. fields with potential to cause physical or psychological harm',
  },
  {
    value: 'finance',
    label:
      'Advice required to be provided by licensed and qualified professionals',
    description:
      'e.g. services in finance, banking, insurance, investments, telecommunications, broadcasting, and others',
  },
  { value: 'legal', label: 'Provision of official legal advice' },
  {
    value: 'national_security',
    label: 'National security and national interest',
    description:
      'e.g. counter-terrorism, counter-espionage, maintaining racial and religious harmony, safeguarding against foreign interference, or security protocols',
  },
  {
    value: 'cybersecurity_critical_infra',
    label: 'Cybersecurity and maintenance of critical infrastructure',
    description:
      'e.g. Critical Information Infrastructure (CII), Significant Information Infrastructure (SII), or Nationally-Essential (NE) critical infrastructure',
  },
  { value: 'none', label: 'None of the above' },
]

export function AssistantRiskAcceptanceSelector({
  onChange,
  value,
  name,
  errorMessage,
  isDisabled,
  id,
}: AssistantRiskAcceptanceSelectorProps) {
  return (
    <VStack spacing="12px" alignItems="stretch" id={id}>
      <SingleSelect
        onChange={onChange}
        value={value}
        name={name}
        isInvalid={!!errorMessage}
        isRequired={false}
        items={RISK_CATEGORY_ITEMS}
        isClearable={false}
        fixedItemHeight={102}
        size="md"
        isDisabled={isDisabled}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </VStack>
  )
}
