import { useQuery } from '@tanstack/react-query'
import { CamelCasedProperties } from 'type-fest'

import {
  GetAssistantFeedbacksRequestDto,
  GetAssistantFeedbacksResponseDto,
} from '~shared/types/assistant-feedback.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

import { AssistantFeedbacksSearchParams } from './useAssistantFeedbacksPaginationController'
interface GetAssistantFeedbacksRequestPaginationDto
  extends GetAssistantFeedbacksRequestDto {
  assistantId: string
}

type CamelCasedGetAssistantFeedbacksRequestPaginationDto =
  CamelCasedProperties<GetAssistantFeedbacksRequestPaginationDto>

const getAssistantFeedbacks = async ({
  assistantId,
  page,
  pageSize,
  feedbackTypesFilter,
}: CamelCasedGetAssistantFeedbacksRequestPaginationDto) => {
  return ApiService.get<GetAssistantFeedbacksResponseDto>(
    `/assistants/${assistantId}/feedbacks`,
    {
      params: {
        page,
        pageSize,
        ...(feedbackTypesFilter && {
          feedback_types_filter: feedbackTypesFilter,
        }),
      },
    },
  ).then((response) => response.data)
}

// TODO: in the future if there is more offset based pagination, can abstract it out as a hook
export const useGetAssistantFeedbacks = ({
  assistantId,
  enabled,
  page,
  pageSize,
  feedbackTypesFilter,
  handlePaginationClick,
}: CamelCasedGetAssistantFeedbacksRequestPaginationDto & {
  enabled: boolean
  handlePaginationClick: ({
    newPage,
    feedbackTypeFilter,
  }: AssistantFeedbacksSearchParams) => void
}) => {
  return useQuery(
    queries.assistants
      .feedbacks(assistantId)
      ._ctx.filter({ page, feedbackTypesFilter }).queryKey,
    async () => {
      const response = await getAssistantFeedbacks({
        assistantId,
        page,
        pageSize,
        feedbackTypesFilter,
      })

      // Side effect to hide the problem of page number overflowing and bringing the user back to correct page
      if (Math.ceil(response.total_count / pageSize) < page) {
        handlePaginationClick({ newPage: 1 })
      }

      return response
    },
    { enabled },
  )
}
