import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools' // import utility for setting light and dark mode props

import { textStyles } from '../foundations/textStyles'

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: 'semibold', // change the font weight
    fontFamily: 'Inter', // change the font family
  },
  tabpanel: {
    fontFamily: 'Inter', // change the font family
  },
})

// define custom sizes
const sizes = {
  xl: definePartsStyle({
    // define the parts that will change for each size
    tab: {
      fontSize: 'xl',
      py: '4',
      px: '6',
    },
    tabpanel: {
      py: '4',
      px: '6',
    },
  }),

  md: definePartsStyle({
    // define the parts that will change for each size
    tab: {
      fontSize: 'md',
      py: '4px',
      px: '12px',
    },
    tabpanel: {
      py: '4px',
      px: '6px',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif', // change the font family
    },
  }),
}

// define custom variants
const variantRoundedSelectors = definePartsStyle((props) => {
  const { colorScheme: c, theme } = props
  return {
    tab: {
      ...textStyles['subhead-2'],
      textTransform: 'none',
      borderRadius: 'full',
      fontWeight: 'semibold',
      fontFamily: 'Inter',
      color: 'gray.600',
      _selected: {
        color: getColor(theme, `${c}.700`),
        bg: getColor(theme, `${c}.100`),
      },
    },
    tablist: {
      gap: '1px',
    },
  }
})

const variants = {
  rounded: variantRoundedSelectors,
}

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'rounded',
  },
})
