import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { PrivateElement } from '~/app/PrivateElement'

import { routes } from '~constants/routes'
import { LeftSidebarTemplatePage } from '~templates/LeftSidebarTemplatePage'

const RouteTemplate = () => {
  return <PrivateElement element={<LeftSidebarTemplatePage />} />
}

/**
 * Redirects share path (/share/asst/:id) to start assistant chat page on chatV2 path (/chatV2?assistant=:id)
 */
const AssistantRedirect = () => {
  const { id } = useParams()
  if (!id) throw new Error('No assistant id provided in params')
  return <Navigate to={`${routes.chat}?assistant=${id}`} replace />
}

export const ShareRoutes = () => {
  return (
    <Routes>
      {/*
        Errors thrown will redirect to base `/chatV2` page.
        TODO: Add better fallback error component. This should be a static element
      */}
      <Route
        element={<RouteTemplate />}
        errorElement={<Navigate to={routes.chat} />}
      >
        <Route path="asst/:id" element={<AssistantRedirect />} />
      </Route>
    </Routes>
  )
}
