import { useState } from 'react'

import { AssistantFeedbackType } from '~shared/types/assistant-feedback.dto'
import { FeedbackRatingType } from '~shared/types/feedbacks.dto'

import { useCreateAssistantFeedback } from './useCreateAssistantFeedback'
type UseAssistantFeedbackDto = {
  onClose: () => void
  assistantId: string
  feedbackRating?: FeedbackRatingType
  feedbackType: AssistantFeedbackType
  messageId: string
}

export const useAssistantFeedback = ({
  assistantId,
  onClose,
  messageId,
  feedbackType,
  feedbackRating,
}: UseAssistantFeedbackDto) => {
  const { createAssistantFeedback, isCreateAssistantFeedbackLoading } =
    useCreateAssistantFeedback(assistantId)

  // States
  const [feedbackContent, setFeedbackContent] = useState('')
  const [numericRating, setNumericRating] = useState(0)
  // Default false to for explicit consent from users
  const [isAllowCreatorToView, setIsAllowCreatorToView] = useState(false)

  const resetStates = () => {
    setNumericRating(0)
    setIsAllowCreatorToView(false)
    setFeedbackContent('')
  }

  const feedbackOnClose = () => {
    onClose()
    resetStates()
  }

  const onSubmit = () => {
    // Submit
    createAssistantFeedback(
      {
        message_id: messageId,
        feedback_type: feedbackType,
        is_allow_creator_to_view: isAllowCreatorToView,
        ...(feedbackContent && { content: feedbackContent }),
        ...(feedbackRating && { rating: feedbackRating }),
        ...(numericRating && { numeric_rating: numericRating }),
      },
      {
        onSuccess: () => {
          feedbackOnClose()
        },
      },
    )
  }

  return {
    feedbackOnClose,
    onSubmit,
    isCreateAssistantFeedbackLoading,
    numericRating,
    setNumericRating,
    isAllowCreatorToView,
    setIsAllowCreatorToView,
    feedbackContent,
    setFeedbackContent,
  }
}
