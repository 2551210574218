import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface IUserSettingsStoreStates {
  browserId: string
}
const useUserSettingsStore = create<IUserSettingsStoreStates>()(
  devtools(
    persist(
      (_set) => ({
        // Assigns a random UUID on store init if not already present
        browserId: crypto.randomUUID(),
      }),
      {
        name: 'user-settings-store',
        version: 1,
      },
    ),
  ),
)

export function useBrowserId() {
  return useUserSettingsStore((state) => state.browserId)
}
