import { useEffect, useRef, useState } from 'react'
import { Box, Text, TextProps, Tooltip } from '@chakra-ui/react'

interface TruncatedTextProps extends TextProps {
  children: string | string[]
  noOfLines: number
}

// Automatically adds a tooltip when the text has been truncated
export const TruncatedText = ({
  children,
  noOfLines,
  ...props
}: TruncatedTextProps) => {
  const ref = useRef<HTMLParagraphElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const text = Array.isArray(children) ? children.join('') : children

  useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current
      if (element) {
        // Check if the content is truncated
        const isContentTruncated = element.scrollHeight > element.clientHeight
        setIsTruncated(isContentTruncated)
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)

    return () => {
      window.removeEventListener('resize', checkTruncation)
    }
  }, [text])

  return (
    <Tooltip
      label={text}
      isDisabled={!isTruncated}
      hasArrow
      placement="top"
      openDelay={100}
    >
      <Box>
        <Text ref={ref} noOfLines={noOfLines} {...props}>
          {text}
        </Text>
      </Box>
    </Tooltip>
  )
}
