import { useNavigate, useParams } from 'react-router-dom'
import { Center, Spinner } from '@chakra-ui/react'

import { CreateOrUpdateAssistantRequestDto } from '~shared/types/assistants.dto'

import { useAuth } from '~lib/auth'
import { queryClient } from '~lib/react-query'
import { queries } from '~constants/queries'
import { routes } from '~constants/routes'
import { useCustomToast } from '~hooks/useCustomToast'

import { AssistantEditorScreen } from '../components/AssistantEditor/AssistantEditorScreen'
import { useGetAssistant, useUpdateAssistant } from '../hooks'
import { generateAssistantPathFromId } from '../utils'

export const AssistantUpdatePage = () => {
  const { user } = useAuth()

  if (!user?.email) {
    throw new Error('Unexpected user error has occurred')
  }

  const { assistantId } = useParams()
  const navigate = useNavigate()

  if (!assistantId) {
    throw new Error('no assistant')
  }

  const {
    data: assistant,
    isLoading: isQueryLoading,
    isFetched,
  } = useGetAssistant({
    assistantId,
  })

  // Navigate to explore page if user does not have edit access to the assistant
  if (isFetched && !assistant) navigate(routes.assistants)

  const handlePublishClose = () =>
    navigate(generateAssistantPathFromId(assistantId))

  const { mutate, isLoading: isUpdateAssistantLoading } = useUpdateAssistant()
  const { createToast } = useCustomToast()

  const onPublish = (data: CreateOrUpdateAssistantRequestDto) => {
    mutate(
      { assistantId, data },
      {
        onError: () => {
          createToast({
            content:
              'An unexpected error has occurred while updating your assistant. Please try again.',
            type: 'error',
          })
        },
        onSuccess: (updatedAssistantData) => {
          createToast({
            content: 'Your Assistant has been successfully updated.',
            type: 'success',
            duration: 2_500,
          })
          void queryClient.resetQueries(
            queries.assistants.detail(updatedAssistantData.id),
          )
          handlePublishClose()
        },
      },
    )
  }

  if (isQueryLoading) {
    return (
      <Center height="100%" flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <AssistantEditorScreen
      onPublish={onPublish}
      isPublishLoading={isUpdateAssistantLoading}
      assistant={assistant}
    />
  )
}
