import { BiTrash } from 'react-icons/bi'
import { Icon, MenuItem, useDisclosure } from '@chakra-ui/react'

import { DeleteAssistantModal } from './DeleteAssistantModal'

type DeleteAssistantButtonProps = {
  assistantId: string
  iconSpacing?: string
  onAssistantDeleted?: () => void
}

export const DeleteAssistantButton = ({
  assistantId,
  iconSpacing,
  onAssistantDeleted,
}: DeleteAssistantButtonProps) => {
  const {
    isOpen: isOpenDeleteAssistantModal,
    onOpen: onOpenDeleteAssistantModal,
    onClose: onCloseDeleteAssistantModal,
  } = useDisclosure()
  return (
    <>
      <DeleteAssistantModal
        assistantId={assistantId}
        isOpenDeleteAssistantModal={isOpenDeleteAssistantModal}
        onCloseDeleteAssistantModal={onCloseDeleteAssistantModal}
        onAssistantDeleted={onAssistantDeleted}
      />
      <MenuItem
        textStyle="body-2"
        iconSpacing={iconSpacing}
        icon={<Icon as={BiTrash} fontSize="16px" verticalAlign="middle" />}
        color="interaction.critical.default"
        onClick={onOpenDeleteAssistantModal}
      >
        Delete Assistant
      </MenuItem>
    </>
  )
}
