import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ChatHistoryResponseDto } from '~shared/types/chats.dto'

import { queries } from '~constants/queries'

import { getFullChatHistory } from '../api'

export function useChatHistory({
  id,
  isNewChat,
  onLoadChatError,
}: {
  id: string
  isNewChat: boolean
  onLoadChatError?: (error: AxiosError) => void
}) {
  return useQuery(
    queries.chat.detail(id).queryKey,
    async () => {
      return {
        ...(await getFullChatHistory(id)),
      }
    },
    {
      initialData: () => {
        if (isNewChat) {
          const initialPlaceholderDate: ChatHistoryResponseDto = {
            chat: {
              id,
              assistant_id: null,
              title: 'Placeholder Title',
              created_at: 'Placholder created_at',
              updated_at: 'Placeholder updated_at',
            },
            messages: [],
          }
          return initialPlaceholderDate
        }
      },
      enabled: !isNewChat,
      onError: (err) => {
        if (err instanceof AxiosError && onLoadChatError) {
          onLoadChatError(err)
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )
}
