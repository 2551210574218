import { useState } from 'react'
import { BiFileBlank, BiX } from 'react-icons/bi'
import { Box, HStack, Icon, StackProps, Text } from '@chakra-ui/react'

interface DocumentTagProps extends StackProps {
  documentName: string
  onDelete?: () => void
  isDeleteButtonDefaultVisible?: boolean
  isDisabled?: boolean
}

// This guarantees that the maximum length of the displayed filename is 24
// characters + 3 (from the "..."), so e.g.
// "test1.pdf" -> "test1.pdf"
// "test1_this_is_a_long_filename.pdf" -> "test1_this_is_a_...name.pdf"
const FILENAME_PREFIX_CHARS = 16
const FILENAME_SUFFIX_CHARS = 8
const MAX_FILENAME_DISPLAY_LENGTH =
  FILENAME_PREFIX_CHARS + FILENAME_SUFFIX_CHARS

export const DocumentTag = ({
  documentName,
  onDelete,
  isDeleteButtonDefaultVisible,
  isDisabled,
  ...props
}: DocumentTagProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const onDocumentDelete = () => {
    if (!isDisabled && onDelete) {
      onDelete()
    }
  }

  return (
    <HStack
      display="inline-flex"
      alignItems="center"
      borderRadius="full"
      background={isDisabled ? 'interaction.support.disabled' : 'white'}
      color={isDisabled ? 'interaction.support.disabled-content' : 'black'}
      borderColor="base.divider.medium"
      borderWidth="1px"
      paddingY="4px"
      paddingLeft="16px"
      paddingRight={isDeleteButtonDefaultVisible ? '12px' : '24px'}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      spacing="8px"
      backgroundColor="interaction.sub-subtle.default"
      {...(isDisabled && { cursor: 'not-allowed' })}
      {...props}
    >
      <Icon
        as={BiFileBlank}
        fontSize="20px"
        color={
          isDisabled
            ? 'interaction.support.disabled-content'
            : 'base.content.medium'
        }
      />
      <HStack
        spacing="0px"
        textStyle="subhead-2"
        alignItems="start"
        color={
          isDisabled
            ? 'interaction.support.disabled-content'
            : 'base.content.strong'
        }
      >
        <Text fontSize="sm" whiteSpace="nowrap">
          {documentName
            .slice(0, documentName.length - 8)
            .slice(0, FILENAME_PREFIX_CHARS)}
        </Text>
        <Text fontSize="sm" whiteSpace="nowrap">
          {documentName.length > MAX_FILENAME_DISPLAY_LENGTH ? '...' : ''}
          {documentName.slice(-FILENAME_SUFFIX_CHARS).trim()}
        </Text>
      </HStack>
      {isDeleteButtonDefaultVisible && (
        <Box
          as="button"
          type="button"
          width="20px"
          height="20px"
          color="base.content.medium"
          _hover={{
            backgroundColor: 'interaction.sub-subtle.hover',
          }}
          borderRadius="full"
          aria-label="Delete document"
          onClick={onDocumentDelete}
        >
          <Icon as={BiX} fontSize="20px" />
        </Box>
      )}
      {isHovered && !!onDelete && !isDeleteButtonDefaultVisible && (
        <Box
          as="button"
          type="button"
          position="absolute"
          top="-5px"
          right="-5px"
          bg="red.500"
          width="20px"
          height="20px"
          color="white"
          backgroundColor="interaction.support.placeholder"
          _hover={{
            backgroundColor: 'grey.200',
          }}
          borderRadius="full"
          onClick={onDocumentDelete}
          aria-label="Delete document"
        >
          <Icon as={BiX} fontSize="16px" />
        </Box>
      )}
    </HStack>
  )
}
