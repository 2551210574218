import { useEffect, useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { BiPaperclip } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import ResizeableTextarea from 'react-textarea-autosize'
import {
  Box,
  HStack,
  Link,
  StackProps,
  Text,
  Textarea as ChakraTextarea,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { Infobox } from '@opengovsg/design-system-react'
import _ from 'lodash'

import {
  MESSAGE_DOCUMENTS_CONTEXT_LIMIT,
  MESSAGE_DOCUMENTS_COUNT_LIMIT,
} from '~shared/constants/documents'

import { useTrackedFlag } from '~lib/feature-flag'
import { routes } from '~constants/routes'
import { IconButtonWithTooltip } from '~components/IconButtonWithTooltip'
import { TruncatedText } from '~components/TruncatedText'

import { DocumentsUploadModal } from '~features/documents/components/DocumentsUploadModal'
import { useDeleteDocument, useGetDocuments } from '~features/documents/hooks'

import { INPUT_TOOLBAR_ICON_SIZE_IN_PX } from '../constants'
import { IBasicEditor } from '../types'

import { DocumentTag } from './DocumentTag'
import { InputToolbarIconButton } from './InputToolbarIconButton'

interface InputToolbarProps extends StackProps {
  editor: IBasicEditor
  isSubmissionDisabled: boolean
  submissionDisabledMessage?: string
  isShowChatTooLongAlert?: boolean
  placeholder?: string
  isAllowDocumentUploads?: boolean
  defaultSensitivityAcknowledged?: boolean
  warningMessage?: string
  newChatUrl?: string
  innerWidth?: string
  additionalToolbarIconButtons?: React.ReactNode[]
}

export const InputToolbar = ({
  editor,
  isSubmissionDisabled,
  submissionDisabledMessage,
  isShowChatTooLongAlert = false,
  isAllowDocumentUploads = false,
  defaultSensitivityAcknowledged = false,
  placeholder,
  warningMessage,
  newChatUrl,
  innerWidth,
  additionalToolbarIconButtons,
  ...props
}: InputToolbarProps) => {
  const { isEnabled: isDocumentsEnabled } = useTrackedFlag('documents_enable')
  const isEnableDocumentsUpload = isDocumentsEnabled && isAllowDocumentUploads
  const navigate = useNavigate()

  // Note: We define a local state here to avoid expensive re-renders to parent
  const [input, setInput] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const {
    isOpen: isDocumentUploadModalOpen,
    onClose: onDocumentUploadModalClose,
    onOpen: onDocumentUploadModalOpen,
  } = useDisclosure()

  const [uploadedDocumentIds, setUploadedDocumentIds] = useState<string[]>([])

  const documentTokenLimit = MESSAGE_DOCUMENTS_CONTEXT_LIMIT
  const documentCountLimit = MESSAGE_DOCUMENTS_COUNT_LIMIT
  const { completedDocuments } = useGetDocuments({
    documentIds: uploadedDocumentIds,
    tokenLimit: documentTokenLimit,
    countLimit: documentCountLimit,
  })

  const { mutate: deleteDocumentMutate } = useDeleteDocument()
  const onDeleteDocument = ({ documentId }: { documentId: string }) => {
    setUploadedDocumentIds(_.without(uploadedDocumentIds, documentId))
    deleteDocumentMutate({ documentId })
  }

  // Effect hook to set template on change
  useEffect(() => {
    if (editor.template) {
      setInput(editor.template)
    }

    // Cleans up input state on every dismount of editor
    return () => setInput('')
  }, [editor])

  const isSubmissionLocked =
    isSubmissionDisabled || !editor.isEditable || input.trim().length === 0

  const handleSubmit = () => {
    editor.submit({
      text: input,
      documents: completedDocuments.length ? completedDocuments : undefined,
    })
    setInput('')
    setUploadedDocumentIds([])
  }

  const toolbarWidth = innerWidth ?? '80%'

  const textAreaPaddingRight =
    52 +
    INPUT_TOOLBAR_ICON_SIZE_IN_PX * (additionalToolbarIconButtons?.length ?? 0)

  return (
    <>
      <DocumentsUploadModal
        isOpen={isDocumentUploadModalOpen}
        onClose={onDocumentUploadModalClose}
        uploadedDocumentIds={uploadedDocumentIds}
        setUploadedDocumentIds={setUploadedDocumentIds}
        tokenLimit={documentTokenLimit}
        countLimit={documentCountLimit}
        defaultSensitivityAcknowledged={defaultSensitivityAcknowledged}
        returnFocusOnClose={false}
        submitButtonLabel="Add to chat"
        // For input toolbar, since unlike assistants we don't have an update
        // option just immediately delete.
        isDeleteDocumentsOnClose={true}
      />
      <VStack
        width="100%"
        alignItems="center"
        spacing="16px"
        padding="16px"
        borderTopColor="base.divider.medium"
        borderTopWidth="1px"
        {...props}
      >
        <VStack spacing="0px" width={toolbarWidth}>
          {(warningMessage || isShowChatTooLongAlert) && (
            <VStack
              paddingX="24px"
              alignItems="center"
              width="100%"
              spacing="-2px"
            >
              {warningMessage && (
                <Infobox
                  size="sm"
                  variant="warning"
                  marginBottom="-1px"
                  borderTopRadius="8px"
                  width="100%"
                >
                  {warningMessage}
                </Infobox>
              )}
              {isShowChatTooLongAlert && (
                <Infobox
                  size="sm"
                  variant="info"
                  marginBottom="-1px"
                  borderTopRadius="8px"
                  width="100%"
                >
                  <HStack
                    spacing="16px"
                    width="100%"
                    justifyContent="space-between"
                    paddingRight="16px"
                  >
                    <TruncatedText color="base.content.strong" noOfLines={2}>
                      Tip: When the chat gets too long, the earlier parts of the
                      chat may be forgotten.
                    </TruncatedText>
                    <Link
                      color="base.content.brand"
                      minWidth="60px"
                      onClick={() => navigate(newChatUrl ?? routes.chat)}
                    >
                      Start a new chat
                    </Link>
                  </HStack>
                </Infobox>
              )}
            </VStack>
          )}
          <VStack
            width="100%"
            height="100%"
            borderRadius="24px"
            borderWidth={isFocused ? '2px' : '1px'}
            borderColor={isFocused ? 'blue.500' : 'base.divider.strong'}
            padding={isFocused ? '0px' : '1px'}
            transition="border-color 0.2s"
            backgroundColor={
              !editor?.isEditable ? 'interaction.support.disabled' : 'white'
            }
          >
            {completedDocuments.length > 0 && (
              <Wrap width="100%" paddingTop="12px" paddingX="16px">
                {completedDocuments.map((completedDocument) => (
                  <WrapItem key={completedDocument.id}>
                    <DocumentTag
                      documentName={completedDocument.name}
                      onDelete={() =>
                        onDeleteDocument({ documentId: completedDocument.id })
                      }
                    />
                  </WrapItem>
                ))}
              </Wrap>
            )}

            <Box
              position="relative"
              width="100%"
              paddingRight={`${textAreaPaddingRight}px`}
              paddingLeft={isEnableDocumentsUpload ? '48px' : '10px'}
              paddingY="2px"
            >
              <ChakraTextarea
                autoFocus
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                css={{
                  resize: 'none',
                  '&::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#BFC2C8',
                    borderRadius: '24px',
                  },
                }}
                _focusVisible={{ outline: 'none' }}
                width="100%"
                paddingY="8px"
                borderWidth="0px"
                as={ResizeableTextarea}
                tabIndex={0}
                maxRows={8}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={placeholder ?? editor.placeholder}
                spellCheck={false}
                onKeyDown={(e) => {
                  if (!e.shiftKey && e.key === 'Enter' && !isSubmissionLocked) {
                    handleSubmit()
                    e.preventDefault()
                  }
                }}
                // Chakra adds a minH to its stock textarea component, needs to be unset to have single-line
                minH="unset"
                isDisabled={!editor.isEditable}
                ref={editor.inputRef}
                backgroundColor="transparent"
                size="md"
              />

              <HStack
                position="absolute"
                bottom="0px"
                right="16px"
                spacing="0px"
              >
                {additionalToolbarIconButtons &&
                  additionalToolbarIconButtons[0]}

                <InputToolbarIconButton
                  tooltipLabel={submissionDisabledMessage ?? ''}
                  aria-label="Send"
                  icon={<AiOutlineSend />}
                  onClick={handleSubmit}
                  isDisabled={isSubmissionLocked}
                  isTooltipDisabled={!isSubmissionLocked}
                  color="interaction.main.default"
                />
              </HStack>

              {/* Separate from the other buttons since this is on the left */}
              {isEnableDocumentsUpload && (
                <IconButtonWithTooltip
                  tooltipText="Attach documents"
                  minHeight="40px"
                  minWidth="40px"
                  position="absolute"
                  bottom="1px"
                  left="12px"
                  display="inline-flex"
                  variant="unstyled"
                  height="fit-content"
                  _disabled={{
                    color: 'interaction.support.disabled-content',
                    cursor: 'not-allowed',
                  }}
                  border="none"
                  background="transparent"
                  color="interaction.links.neutral-default"
                  _hover={{
                    color: 'interaction.links.neutral-hover',
                  }}
                  aria-label="Upload document"
                  fontSize="20px"
                  icon={<BiPaperclip />}
                  zIndex={1}
                  onClick={onDocumentUploadModalOpen}
                />
              )}
            </Box>
          </VStack>
        </VStack>

        <Box width={toolbarWidth}>
          <Text
            textAlign="center"
            textStyle="caption-2"
            color="base.content.medium"
            className="input-toolbar-disclaimer"
          >
            Use Pair only for information up to{' '}
            <strong>Restricted / Sensitive Normal</strong>. Double check
            responses for mistakes.
          </Text>
        </Box>
      </VStack>
    </>
  )
}
