import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Box,
  Button,
  HStack,
  Image,
  ListItem,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'

import DocumentUploadsHero from '~/assets/announcements/document-uploads.png'

import { UnanimatedModal } from '~components/UnanimatedComponents'

type DocumentsAnnouncementModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const DocumentsAnnouncementModal = ({
  isOpen,
  onClose,
}: DocumentsAnnouncementModalProps) => {
  return (
    <UnanimatedModal
      size="md"
      isOpen={isOpen}
      // Not used since we don't close manually
      onClose={onClose}
      isCentered
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent width="600px" maxHeight="600px">
        <Box
          display="flex"
          height="291px"
          maxHeight="291px"
          paddingY="13px"
          justifyContent="center"
          backgroundColor="brand.primary.50"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
        >
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              objectFit="contain"
              src={DocumentUploadsHero}
              alt="Claude Sonnet 35 Hero"
              maxWidth="100%"
            />
          </Box>
        </Box>
        <ModalBody padding="0px">
          <VStack
            spacing="16px"
            paddingY="16px"
            paddingX="32px"
            width="100%"
            alignItems="start"
          >
            <Text textStyle="h5" color="base.content.strong">
              You can now upload files to Pair Chat and Assistants!
            </Text>
            <VStack
              alignItems="flex-start"
              textStyle="body-2"
              color="base.content.default"
              spacing="12px"
            >
              <UnorderedList paddingLeft="16px" spacing="2px">
                <ListItem textStyle="body-2">
                  <strong>Upload files to chat:</strong> Analyse, query,
                  summarise, and refine documents directly in your chat
                </ListItem>
                <ListItem>
                  <strong>Upload files to assistants:</strong> Equip your shared
                  Assistants with context and references to improve responses
                  for all users
                </ListItem>
              </UnorderedList>
            </VStack>

            <HStack justifyContent="flex-end" width="100%" spacing="8px">
              <Button
                color="white"
                onClick={onClose}
                rightIcon={<BiRightArrowAlt fontSize="1.125rem" />}
              >
                Ok, got it
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </UnanimatedModal>
  )
}
