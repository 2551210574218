import { Components } from 'react-markdown'
import { VStack } from '@chakra-ui/react'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import remarkGfm from 'remark-gfm'

import { markdownTheme } from '~lib/markdown'
import { MemoizedReactMarkdown } from '~components/MemoizedReactMarkdown'

export const MarkdownChatContent = ({
  content,
  customMarkdownTheme,
}: {
  content: string
  customMarkdownTheme?: Components
}) => {
  return (
    <VStack spacing="16px" alignItems="stretch" width="100%" overflowX="auto">
      <MemoizedReactMarkdown
        components={ChakraUIRenderer(customMarkdownTheme ?? markdownTheme)}
        remarkPlugins={[remarkGfm]}
      >
        {content}
      </MemoizedReactMarkdown>
    </VStack>
  )
}
