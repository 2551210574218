import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const EmptyAssistantFeedbackSvgr = chakra(
  (props: SVGProps<SVGSVGElement>) => (
    <svg
      width="294"
      height="265"
      viewBox="0 0 294 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.7318 128.429H225.752L201.148 96.9374C198.442 93.4824 191.751 90.6875 186.184 90.6875H6.32178C0.754051 90.6875 -1.57867 93.4824 1.12817 96.9374L25.7318 128.429Z"
        fill="#1A2644"
      />
      <path
        d="M26.3467 126.229H267.321C267.651 126.229 267.937 126.493 267.937 126.845V262.231C267.937 263.441 266.947 264.431 265.737 264.431H27.9312C26.7208 264.431 25.7305 263.441 25.7305 262.231V126.845C25.7305 126.515 25.9946 126.229 26.3467 126.229Z"
        fill="#ACC7FA"
      />
      <path
        d="M267.339 126.314H109.73V264.542H267.339V126.314Z"
        fill="#568DF4"
      />
      <path
        d="M110.033 127.738L267.227 136.902L267.227 128.099L110.033 127.333V127.738Z"
        fill="#1A2644"
      />
      <path
        opacity="0.7"
        d="M50.6133 128.101V227.131L57.5454 221.731L62.4089 227.131L66.6343 221.121L70.9476 227.131L76.9995 222.602L83.6235 227.131V128.101H50.6133Z"
        fill="#0D4FCA"
      />
      <path
        d="M117.313 92.8866C112.324 86.5114 105.921 70.4346 120.224 57.1298C138.102 40.4987 154.318 60.0402 148.913 69.1873C143.975 77.5438 123.134 77.5029 120.224 52.972C117.895 33.3473 133.945 26.2237 142.26 25.1149C151.546 24.422 173.912 29.5803 164.297 45.0723C156.813 57.1298 138.967 37.1571 142.26 21.7887C146.002 4.32597 163.465 1.41553 169.286 1.41553"
        stroke="#072A69"
        strokeWidth="1.66311"
        strokeLinejoin="round"
      />
      <path
        d="M267.886 128.139L109.086 127.333L131.312 107.897C134.019 105.652 140.709 103.826 146.277 103.826H287.691C293.259 103.826 295.57 105.652 292.885 107.897L267.908 128.139H267.886Z"
        fill="#ACC7FA"
      />
    </svg>
  ),
)
