import { useNavigate } from 'react-router-dom'
import {
  Button,
  Center,
  Grid,
  GridItem,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import { PromotionalAssistantLabelType } from '~shared/types/assistants.dto'

import AorBuilderLottie from '~/assets/lottie/mrman.lottie?url'
import QuestionLadyLottie from '~/assets/lottie/questionlady.lottie?url'
import MinutesWriterLottie from '~/assets/lottie/specslady.lottie?url'

import { PAIR_CHAT_OR_ASSISTANTS_URL } from '~constants/config'
import { routes } from '~constants/routes'

import { useListPromotionalAssistants } from '~features/assistants/hooks/useListPromotionalAssistants'

import { AssistantPromoCard } from './AssistantPromoCard'

/**
 * This component can be used to run promotional campaigns on the main chat screen
 * NOTE: Never ask a designer for a responsive mock.
 */

const PromotionalAssistantMapping: Record<
  PromotionalAssistantLabelType,
  string
> = {
  meeting_minutes_writer: MinutesWriterLottie,
  aor_builder: AorBuilderLottie,
  qa_agent: QuestionLadyLottie,
}

export const PromotionalHero = () => {
  const navigate = useNavigate()

  const { data, isLoading } = useListPromotionalAssistants()

  return (
    <VStack
      alignItems="center"
      justifyContent="flex-start"
      spacing="8px"
      flexGrow={1}
      paddingX={{ base: '8px', lg: '20px' }}
      paddingY={{ base: '8px', lg: '32px' }}
      mx="16px"
    >
      <Stack
        paddingX={{ base: '16px', lg: '24px' }}
        paddingY={{ base: '16px', lg: '32px' }}
        backgroundColor="blue.75"
        borderRadius={{ base: '8px', sm: '8px' }}
        spacing="32px"
        direction={{ base: 'column', lg: 'row' }}
        alignItems="center"
      >
        <VStack
          alignItems={{ base: 'flex-start', md: 'center', lg: 'flex-start' }}
          maxWidth={{ base: '600px', lg: '250px' }}
          spacing="8px"
        >
          <Text textStyle={{ base: 'h6', md: 'h5' }}>
            Get hours of repetitive work done in seconds
          </Text>
          <VStack
            alignItems={{ base: 'flex-start', md: 'center', lg: 'flex-start' }}
            spacing="16px"
          >
            <Text
              textStyle="body-2"
              textAlign={{ base: 'start', md: 'center', lg: 'start' }}
            >
              Assistants automate your team&apos;s recurring tasks. Build once,
              share and get consistent outputs everytime.
            </Text>

            <Stack
              spacing={{ base: '12px', md: '36px', lg: '16px' }}
              direction={{ base: 'column', md: 'row-reverse', lg: 'column' }}
              alignItems={{
                base: 'flex-start',
                md: 'center',
                lg: 'flex-start',
              }}
            >
              <Link
                textStyle="body-2"
                href={PAIR_CHAT_OR_ASSISTANTS_URL}
                target="_blank"
              >
                Chat or Assistants: Which to use?
              </Link>
              <Button
                color="white"
                size="xs"
                onClick={() => navigate(routes.assistants)}
              >
                Explore Assistants
              </Button>
            </Stack>
          </VStack>
        </VStack>
        {isLoading && (
          <Center w={{ base: '320px', md: '400px' }}>
            <Spinner size="xl" />
          </Center>
        )}
        <Grid gap="12px" templateColumns="repeat(3, 1fr)">
          {data?.map((dat) => (
            <GridItem
              flex="1 1 0"
              key={dat.id}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <AssistantPromoCard
                title={dat.promotion_info?.name || 'Generic Assistant'}
                description={
                  dat.promotion_info?.description || 'Generic Description'
                }
                lottieAnimationSrc={
                  PromotionalAssistantMapping[
                    dat.promotion_info?.label || 'qa_agent'
                  ]
                }
                id={dat.id}
              />
            </GridItem>
          ))}
        </Grid>
      </Stack>
    </VStack>
  )
}
