import { Link as RouterLink } from 'react-router-dom'
import { Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import DeletedImage from '~/assets/deleted.svg'

import { routes } from '~constants/routes'

export const AssistantNotFound = () => {
  return (
    <VStack
      flexGrow={1}
      width="100%"
      paddingX="0px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
    >
      <VStack
        alignItems="center"
        justifyContent="center"
        spacing="8px"
        flexGrow={1}
      >
        <Image
          htmlWidth="280px"
          src={DeletedImage}
          alt="404 Not Found Image"
          loading="lazy"
          display={{ base: 'none', md: 'initial' }}
        />
        <Text color="base.content.strong" textStyle="h5">
          Assistant deleted
        </Text>
        <Text>Assistant has been deleted by its creator.</Text>
        <Link as={RouterLink} to={routes.assistants}>
          View other assistants instead
        </Link>
      </VStack>
    </VStack>
  )
}
