import { useCallback, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'

import { GetAssistantResponseDto } from '~shared/types/assistants.dto'
import { getEmailDomain } from '~shared/utils/emails'

import { useAuth } from '~lib/auth'

import { DEFAULT_ASSISTANT_PLACEHOLDER_PROMPT } from '~features/assistants/constants'

import {
  AssistantInputType,
  AssistantSchema,
  AssistantSchemaKeys,
  ParsedAssistantInputType,
  ParsedValidFormAssistantType,
} from '../schema/assistant-schema'

export const useAssistantForm = ({
  assistant,
}: {
  assistant?: GetAssistantResponseDto
}) => {
  const { user } = useAuth()
  if (!user?.email) {
    throw new Error('Unexpected user error has occurred')
  }

  const initialDocumentIds = useMemo(
    () => assistant?.documents.map((doc) => doc.id) ?? [],
    [assistant?.documents],
  )

  const initialAssistantValues = useMemo<AssistantInputType>(
    () => ({
      name: assistant?.name ?? '',
      description: assistant?.description ?? '',
      prompt: assistant?.prompt ?? DEFAULT_ASSISTANT_PLACEHOLDER_PROMPT,
      prompt_starters:
        assistant?.prompt_starters.map((prompt) => ({
          value: prompt,
        })) ?? [],
      view_access: assistant?.view_access ?? 'private',
      document_ids: initialDocumentIds,
      access_patterns: assistant?.access_patterns ?? [],
      risk_category: assistant?.risk_category ?? null,
    }),
    [assistant, initialDocumentIds],
  )

  const formMethods = useForm<AssistantInputType>({
    resolver: zodResolver(AssistantSchema),
    defaultValues: {
      ...initialAssistantValues,
      access_patterns: _.isEmpty(initialAssistantValues.access_patterns)
        ? [getEmailDomain(user.email)]
        : initialAssistantValues.access_patterns,
    },
    shouldFocusError: true,
    mode: 'onChange',
  })

  const { control, formState } = formMethods

  // Use useWatch to efficiently watch specific fields
  const name = useWatch({ control, name: 'name' })
  const description = useWatch({ control, name: 'description' })
  const promptStarters = useWatch({ control, name: 'prompt_starters' })
  const riskCategory = useWatch({ control, name: 'risk_category' })
  const prompt = useWatch({ control, name: 'prompt' })
  const documentIds = useWatch({ control, name: 'document_ids' })

  const transformFormData = useCallback(
    (data: AssistantInputType): ParsedAssistantInputType => ({
      ...data,
      access_patterns:
        data.view_access === 'custom' ? data.access_patterns : [],
      prompt_starters: data.prompt_starters.map(
        (promptInput) => promptInput.value,
      ),
    }),
    [],
  )

  const isValidAssistantType = (
    asst: ParsedAssistantInputType,
  ): asst is ParsedValidFormAssistantType => {
    return asst.risk_category !== null
  }

  const updatedFields = _.keys(
    _.pickBy(formState.dirtyFields),
  ) as AssistantSchemaKeys[]

  const watchedFields = {
    name,
    description,
    promptStarters,
    riskCategory,
    prompt,
    documentIds,
  }

  return {
    formMethods,
    watchedFields,
    transformFormData,
    isValidAssistantType,
    updatedFields,
  }
}
