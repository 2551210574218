import { VStack } from '@chakra-ui/react'

import { SharedAssistantDto } from '~shared/types/assistants.dto'

import { IBasicEditor } from '~features/chatv2/types'

import { AssistantInfoDisplay } from './AssistantInfoDisplay'
import { AssistantPromptHighlights } from './AssistantPromptHighlights'

export const AssistantLiveNewChat = ({
  assistant,
  editor,
}: {
  assistant: SharedAssistantDto
  editor: IBasicEditor
}) => {
  return (
    <VStack
      flexGrow={1}
      width="100%"
      paddingX="0px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
    >
      <AssistantInfoDisplay assistant={assistant} />
      <AssistantPromptHighlights
        onSelect={(content) => editor.setTemplate(content)}
        promptStarters={assistant.prompt_starters}
      />
    </VStack>
  )
}
