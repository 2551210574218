import { BiCheck, BiCopy } from 'react-icons/bi'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { Button, HStack, Text, useClipboard, VStack } from '@chakra-ui/react'

import { textStyles } from '~/theme/foundations/textStyles'

interface HighlightedCodeBlockProps {
  language: string
  content: string
}

export const HighlightedCodeBlock = ({
  language,
  content,
}: HighlightedCodeBlockProps) => {
  const { onCopy, hasCopied } = useClipboard(content, {
    // "Copied" appears for extended duration of 5s upon copying
    timeout: 5_000,
  })

  return (
    <VStack
      alignItems="stretch"
      spacing="0px"
      borderRadius="8px"
      overflow="hidden"
      width="100%"
    >
      <HStack
        paddingX="24px"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="base.canvas.overlay"
        color="white"
      >
        <Text textStyle="subhead-2">{language}</Text>
        <Button
          {...textStyles['subhead-2']}
          variant="ghost"
          border="none"
          leftIcon={
            hasCopied ? <BiCheck fontSize="18px" /> : <BiCopy fontSize="16px" />
          }
          iconSpacing="8px"
          paddingRight="0px"
          onClick={onCopy}
        >
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
      </HStack>
      <SyntaxHighlighter
        language={language}
        style={oneDark}
        wrapLines={true}
        // We only wrap long lines for text blocks
        wrapLongLines={language === 'text'}
        customStyle={{
          lineHeight: '1.25rem',
          fontSize: '0.875rem',
          padding: '24px',
          width: '100%',
          height: '100%',
          margin: '0px',
          borderRadius: '0px',
        }}
      >
        {content}
      </SyntaxHighlighter>
    </VStack>
  )
}
