import { APPROXIMATE_CHARACTERS_PER_TOKEN } from './token'

// TODO: Figure out how to nicely share class DTOs for both front/back-ends
export const MAX_PROMPT_STARTERS_SIZE = 4
export const MAX_PROMPT_STARTER_CHARACTER_LENGTH = 1_000
export const MAX_NAME_CHARACTER_LENGTH = 50
export const MAX_DESCRIPTION_CHARACTER_LENGTH = 300
// Calculated on the basis that 1 token = 4 english char average
export const MAX_INSTRUCTION_PROMPT_CHARACTER_LENGTH =
  10_000 * APPROXIMATE_CHARACTERS_PER_TOKEN

// General limits:
export const MIN_GENERAL_INPUT_CHARACTER_LENGTH = 1
