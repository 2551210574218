import { useIsMutating } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'

import {
  ListDocumentsByIdsRequestDto,
  ListDocumentsByIdsResponseDto,
} from '~shared/types/documents.dto'

import { ApiService } from '~lib/api'
import { useTrackedFlag } from '~lib/feature-flag'
import { queries } from '~constants/queries'

import { DOCUMENT_PANEL_REFRESH_PERIOD_MS } from '../constants'

const listDocuments = async (data: ListDocumentsByIdsRequestDto) => {
  return ApiService.post<ListDocumentsByIdsResponseDto>(
    '/documents/list',
    data,
  ).then((response) => response.data)
}

export function useGetDocuments({
  documentIds,
  countLimit,
  tokenLimit,
}: {
  documentIds: string[]
  countLimit: number
  tokenLimit: number
}) {
  const { isEnabled: isDocumentsEnabled } = useTrackedFlag('documents_enable')

  const { data: listDocumentsData, ...useQueryProps } = useQuery(
    queries.documents.listByIds(documentIds).queryKey,
    () => listDocuments({ ids: documentIds }),
    {
      refetchInterval: (currentData) => {
        if (!currentData) return DOCUMENT_PANEL_REFRESH_PERIOD_MS // Initial fetch or error case

        // We only want to poll under these two conditions:
        // 1. the set of ids is different from the previously fetched data
        // 2. one or more documents has not finished processing yet
        const hasNewIds =
          JSON.stringify(currentData.documents.map((d) => d.id).sort()) !==
          JSON.stringify(documentIds.sort())
        const hasProcessingOrQueuedDocs = currentData.documents.some(
          (doc) => doc.status === 'processing' || doc.status === 'queued',
        )

        return hasNewIds || hasProcessingOrQueuedDocs
          ? DOCUMENT_PANEL_REFRESH_PERIOD_MS
          : false
      },
      keepPreviousData: true,
      enabled: isDocumentsEnabled,
    },
  )

  const uploadedDocuments = listDocumentsData?.documents || []
  const processingOrQueuedDocuments = _.filter(
    uploadedDocuments,
    (document) =>
      document.status === 'processing' || document.status === 'queued',
  )
  const completedDocuments = _.filter(
    uploadedDocuments,
    (document) => document.status === 'completed',
  )
  const completedDocumentIds = _.map(
    completedDocuments,
    (document) => document.id,
  )

  const totalTokenCount = _.sumBy(completedDocuments, 'token_count')
  const isDocumentsUploading =
    useIsMutating(queries.documents.upload.queryKey) > 0

  // Disable the form if there are documents still uploading/processing
  // or if token count is exceeded
  const isDocumentsPendingIngestion =
    isDocumentsUploading || processingOrQueuedDocuments.length !== 0

  const isTokenLimitExceeded = totalTokenCount > tokenLimit

  // Note, we should never hit this point since we block uploading documents
  // above the limit, so this is just for DID
  const isDocumentCountLimitExceeded =
    completedDocuments.length + processingOrQueuedDocuments.length > countLimit

  const isBlockSubmission =
    isDocumentsPendingIngestion ||
    isTokenLimitExceeded ||
    isDocumentCountLimitExceeded

  let blockSubmissionMessage = ''
  switch (true) {
    case isDocumentsPendingIngestion:
      blockSubmissionMessage =
        'Please wait for all documents to finish uploading and processing'
      break
    case isDocumentCountLimitExceeded:
      blockSubmissionMessage = `Document limit exceeded. Please reduce the number of documents to ${countLimit} or less to continue.`
      break
    case isTokenLimitExceeded:
      blockSubmissionMessage =
        'Word limit exceeded. Re-upload documents with less text.'
      break
  }

  return {
    ...useQueryProps,
    isDocumentsPendingIngestion,
    uploadedDocuments,
    completedDocuments,
    processingOrQueuedDocuments,
    completedDocumentIds,
    totalTokenCount,
    isBlockSubmission,
    blockSubmissionMessage,
  }
}
