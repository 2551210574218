import { z } from 'zod'

import {
  MAX_DESCRIPTION_CHARACTER_LENGTH,
  MAX_INSTRUCTION_PROMPT_CHARACTER_LENGTH,
  MAX_NAME_CHARACTER_LENGTH,
  MAX_PROMPT_STARTER_CHARACTER_LENGTH,
  MAX_PROMPT_STARTERS_SIZE,
  MIN_GENERAL_INPUT_CHARACTER_LENGTH,
} from '~shared/constants/assistants'

import { SUPPORT_EMAIL } from '~constants/config'

import { DEFAULT_ASSISTANT_PLACEHOLDER_PROMPT } from '../constants'

function maxCharacterCheck({ input, limit }: { input: string; limit: number }) {
  return input.length <= limit
}

function maxCharacterErrorMessage({
  input,
  limit,
}: {
  input: string
  limit: number
}) {
  return {
    message: `Please enter at most ${limit} characters. (${input.length}/${limit})`,
  }
}

export const AssistantSchema = z.object({
  name: z
    .string()
    .trim()
    .min(
      MIN_GENERAL_INPUT_CHARACTER_LENGTH,
      'Please enter the name of your assistant.',
    )
    .refine(
      (input: string) =>
        maxCharacterCheck({ input, limit: MAX_NAME_CHARACTER_LENGTH }),
      (input: string) =>
        maxCharacterErrorMessage({ input, limit: MAX_NAME_CHARACTER_LENGTH }),
    ),
  description: z
    .string()
    .trim()
    .min(
      MIN_GENERAL_INPUT_CHARACTER_LENGTH,
      'Please enter a description of your assistant.',
    )
    .refine(
      (input: string) =>
        maxCharacterCheck({ input, limit: MAX_DESCRIPTION_CHARACTER_LENGTH }),
      (input: string) =>
        maxCharacterErrorMessage({
          input,
          limit: MAX_DESCRIPTION_CHARACTER_LENGTH,
        }),
    ),
  prompt: z
    .string()
    .trim()
    .min(
      MIN_GENERAL_INPUT_CHARACTER_LENGTH,
      'Please enter some instructions for your assistant.',
    )
    .refine(
      (input: string) =>
        input.trim() !== DEFAULT_ASSISTANT_PLACEHOLDER_PROMPT.trim(),
      'Please edit default text before submitting.',
    )
    .refine(
      (input: string) =>
        maxCharacterCheck({
          input,
          limit: MAX_INSTRUCTION_PROMPT_CHARACTER_LENGTH,
        }),
      (input: string) =>
        maxCharacterErrorMessage({
          input,
          limit: MAX_INSTRUCTION_PROMPT_CHARACTER_LENGTH,
        }),
    ),
  prompt_starters: z
    .array(
      z.object({
        value: z
          .string()
          .trim()
          .max(
            MAX_PROMPT_STARTER_CHARACTER_LENGTH,
            `Your conversation starter should not exceed ${MAX_PROMPT_STARTER_CHARACTER_LENGTH} characters.`,
          ),
      }),
    )
    .max(MAX_PROMPT_STARTERS_SIZE),
  view_access: z.enum(['private', 'agency', 'public', 'custom']),
  document_ids: z.array(z.string()),
  access_patterns: z
    .array(z.string())
    .max(
      20,
      `You can only grant access to a maximum of 20 domains. Please contact ${SUPPORT_EMAIL} if you want to grant access to more domains. `,
    ),

  // TODO: Migrate all enums to be shared properly across both FE and BE
  risk_category: z.enum(
    [
      'health_safety',
      'finance',
      'legal',
      'national_security',
      'cybersecurity_critical_infra',
      'none',
    ],
    {
      errorMap: () => ({
        message:
          'Please acknowledge the correct risk level for your assistant before proceeding.',
      }),
    },
  ),
})

export type ValidFormAssistantType = z.infer<typeof AssistantSchema>
export type ParsedValidFormAssistantType = Omit<
  ValidFormAssistantType,
  'prompt_starters'
> & {
  prompt_starters: string[]
}

export type AssistantSchemaKeys = keyof z.infer<typeof AssistantSchema>

// NOTE: We temporarily add a nullable property only as a type to ensure that schema validations still kicks in
// for new submissions/updates, but the type safety remain.
export type AssistantInputType = Omit<
  ValidFormAssistantType,
  'risk_category'
> & {
  risk_category: ValidFormAssistantType['risk_category'] | null
}

export type ParsedAssistantInputType = Omit<
  AssistantInputType,
  'prompt_starters'
> & {
  prompt_starters: string[]
}
