import { Card, CardBody, HStack, Text } from '@chakra-ui/react'

import { PromptTemplateType } from '~features/chatv2/types'

type PromptCardProps = {
  title: PromptTemplateType['title']
  onClick: () => void
}

export const PromptCard = ({
  title,
  onClick,
}: PromptCardProps): JSX.Element => {
  return (
    <Card
      onClick={onClick}
      boxShadow="none"
      borderRadius="4px"
      borderWidth="1px"
      background="utility.ui"
      borderColor="base.divider.strong"
      cursor="pointer"
      _hover={{ backgroundColor: 'interaction.muted.main.hover' }}
      height="100%"
    >
      <CardBody
        as={HStack}
        color="base.content.medium"
        paddingY="12px"
        paddingX="16px"
        justifyContent="space-between"
      >
        <Text textStyle="subhead-2" color="base.content.medium" noOfLines={2}>
          {title}
        </Text>
      </CardBody>
    </Card>
  )
}
