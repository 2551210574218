import { useState } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Center,
  HStack,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  Badge,
  Button,
  Link,
  Pagination,
  TouchableTooltip,
} from '@opengovsg/design-system-react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import camelcaseKeys from 'camelcase-keys'
import { CamelCasedPropertiesDeep } from 'type-fest'

import { routes } from '~constants/routes'
import { DAY_MONTH_YEAR_TIME_AM_PM, formatTime } from '~utils/date'
import { DataTable } from '~components/DataTable'

import { AssistantFeedbacksSearchParams } from '~features/assistants/hooks'

import { EmptyAssistantFeedbackSvgr } from '../Image'

import { AssistantFeedbackDrawer } from './AssistantFeedbackDrawer'
import { getTextAndStyleForFeedbackType } from './helper'
import { AssistantFeedbacks } from './types'

const FEEDBACK_TYPES_INFO = [
  {
    title: 'Overall Reviews (1-5 stars): ',
    description:
      "Ratings users provide when asked to review your overall assistant's performance",
  },
  {
    title: 'Message Reports: ',
    description: 'Specific responses flagged by users as problematic',
  },
]

const columnHelper = createColumnHelper<AssistantFeedbacks>()
const assistantFeedbacksColumns = [
  columnHelper.accessor('hashed_user_id', {
    header: () => (
      <Text textStyle="caption-1" color="base.content.strong">
        User ID
      </Text>
    ),
    cell: (info) => (
      <Text textStyle="caption-2" color="base.content.default">
        {info.getValue()}
      </Text>
    ),
  }),
  columnHelper.accessor('created_at', {
    header: () => (
      <Text textStyle="caption-1" color="base.content.strong">
        Date
      </Text>
    ),
    cell: (info) => (
      <Text textStyle="caption-2" color="base.content.default">
        {formatTime({
          timestamp: info.getValue(),
          dateFormat: DAY_MONTH_YEAR_TIME_AM_PM,
        })}
      </Text>
    ),
  }),
  columnHelper.accessor('feedback_type', {
    header: () => (
      <HStack color="base.content.strong" alignItems="center">
        <Text textStyle="caption-1">Feedback type</Text>
        <TouchableTooltip
          padding="8px 12px"
          borderRadius="4px"
          hasArrow
          label={
            <VStack spacing="3px" align="stretch" width="100%">
              <Text textStyle="body-2" color="white" fontWeight={700}>
                Feedback Types
              </Text>
              <UnorderedList width="100%" listStylePosition="inside">
                {FEEDBACK_TYPES_INFO.map(({ title, description }) => (
                  <ListItem key={title}>
                    {/* convert text as an inline element */}
                    <Text textStyle="body-2" as="span">
                      <Text as="span" fontWeight={700}>
                        {title}
                      </Text>
                      {description}
                    </Text>
                  </ListItem>
                ))}
              </UnorderedList>
            </VStack>
          }
          placement="top"
          wrapperStyles={{
            lineHeight: 0,
          }}
        >
          <Icon as={BiInfoCircle} fontSize="16px" />
        </TouchableTooltip>
      </HStack>
    ),
    cell: (info) => {
      const feedbackType = info.getValue()
      const isAssistantLevelFeedback = feedbackType === 'assistant_level'
      const isMessageLevelFeedback = feedbackType === 'message_level'
      const { text, colorScheme } = getTextAndStyleForFeedbackType({
        isAssistantLevelFeedback,
        isMessageLevelFeedback,
        feedbackNumericValue: info.row.original.numeric_rating,
      })

      return (
        <Badge
          variant="subtle"
          colorScheme={colorScheme}
          textStyle="caption-1"
          fontWeight={500}
        >
          {text}
        </Badge>
      )
    },
  }),
  columnHelper.accessor('content', {
    size: Number.MAX_VALUE,
    header: () => (
      <Text textStyle="caption-1" color="base.content.strong">
        Feedback
      </Text>
    ),
    cell: (info) => {
      const feedbackType = info.row.original.feedback_type
      const feedback = info.getValue()

      if (feedbackType === 'assistant_level') {
        return (
          <Text
            textStyle="caption-2"
            color="base.content.default"
            noOfLines={4}
          >
            {feedback || '-'}
          </Text>
        )
      }

      const responseMessage = info.row.original.message.response_data
      return (
        <VStack
          spacing="12px"
          align="start"
          textStyle="caption-2"
          color="base.content.default"
          lineHeight="1.125rem"
        >
          {feedback && <Text noOfLines={4}>{feedback}</Text>}
          <blockquote
            style={{
              padding: '0 1rem',
              borderLeft: '0.0625rem solid #BFC2C8',
            }}
          >
            <Text fontWeight={700} fontStyle="italic" marginBottom="3px">
              Reported message
            </Text>
            <Text fontStyle="italic" marginBottom="3px" noOfLines={3}>
              {responseMessage}
            </Text>
          </blockquote>
        </VStack>
      )
    },
  }),
  columnHelper.accessor('is_allow_creator_to_view', {
    size: 110,
    header: () => (
      <HStack color="base.content.strong" alignItems="center">
        <Text textStyle="caption-1" color="base.content.strong">
          Chat logs
        </Text>
        <TouchableTooltip
          padding="8px 12px"
          borderRadius="4px"
          hasArrow
          label={
            <Text textStyle="body-2" color="white" fontWeight={700}>
              Chat logs only viewable if user grants creator access
            </Text>
          }
          placement="top"
          wrapperStyles={{
            lineHeight: 0,
          }}
        >
          <Icon as={BiInfoCircle} fontSize="16px" />
        </TouchableTooltip>
      </HStack>
    ),
    cell: (info) => {
      const hasChatLogs = info.getValue()
      return hasChatLogs ? (
        <Link textStyle="caption-1">View</Link>
      ) : (
        <Text>-</Text>
      )
    },
  }),
]

type AssistantFeedbacksTableProps = {
  assistantFeedbacks: AssistantFeedbacks[]
  filteredCount: number
  pageSize: number
  currentPage: number
  handlePaginationClick: (params: AssistantFeedbacksSearchParams) => void
  isFetching: boolean
  assistantName: string
  assistantId: string
  totalCount: number
}

export const AssistantFeedbacksTable = ({
  assistantFeedbacks,
  filteredCount,
  pageSize,
  currentPage,
  handlePaginationClick,
  isFetching,
  assistantName,
  assistantId,
  totalCount,
}: AssistantFeedbacksTableProps) => {
  const {
    isOpen: isAssistantFeedbackDrawerOpen,
    onOpen: onOpenAssistantFeedbackDrawer,
    onClose: onCloseAssistantFeedbackDrawer,
  } = useDisclosure()
  const navigate = useNavigate()

  const [drawerContent, setDrawerContent] = useState<
    CamelCasedPropertiesDeep<AssistantFeedbacks> | undefined
  >(undefined)

  const table = useReactTable({
    columns: assistantFeedbacksColumns,
    data: assistantFeedbacks,
    // From docs (https://tanstack.com/table/v8/docs/api/features/pagination#pagecount), if row is not known `-1` can be set.
    pageCount: -1,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    enableSortingRemoval: false,
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    enableSorting: false,
    state: {
      sorting: [],
    },
  })

  return (
    <VStack width="100%" height="100%" align="start" spacing="38px">
      <Box width="100%">
        <DataTable
          isFetching={isFetching}
          emptyPlaceholder={
            <Center marginTop="60px">
              <VStack spacing="24px" align="stretch">
                {totalCount === 0 ? (
                  <>
                    <Text textStyle="h5" color="base.content.default">
                      You don&apos;t have any feedback yet
                    </Text>
                    <Button
                      variant="outline"
                      onClick={() =>
                        navigate(`${routes.chat}?assistant=${assistantId}`)
                      }
                    >{`Back to ${assistantName}`}</Button>
                  </>
                ) : (
                  <VStack
                    spacing="8px"
                    textAlign="center"
                    color="base.content.default"
                  >
                    <Text textStyle="h5">No feedback of this type yet.</Text>
                    <Text textStyle="body-1">
                      Try applying a different filter
                    </Text>
                  </VStack>
                )}
                <EmptyAssistantFeedbackSvgr />
              </VStack>
            </Center>
          }
          instance={table}
          onClickRow={(row) => {
            setDrawerContent(camelcaseKeys(row, { deep: true }))
            onOpenAssistantFeedbackDrawer()
          }}
          sx={{
            tableLayout: 'auto',
          }}
          tableCellProps={{
            verticalAlign: 'top',
          }}
          tableHeaderProps={{
            height: '56px',
            background: 'interaction.main-subtle.default',
          }}
        />
      </Box>

      {filteredCount > 0 && (
        <Pagination
          totalCount={filteredCount}
          pageSize={pageSize}
          onPageChange={(page) => {
            handlePaginationClick({ newPage: page })
          }}
          currentPage={currentPage}
        />
      )}

      {drawerContent && (
        <AssistantFeedbackDrawer
          isOpen={isAssistantFeedbackDrawerOpen}
          onClose={() => {
            onCloseAssistantFeedbackDrawer()
            setDrawerContent(undefined)
          }}
          {...drawerContent}
        />
      )}
    </VStack>
  )
}
