import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system'

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['list', 'item', 'divider'])

const $bg = cssVar('menu-bg')
const $shadow = cssVar('menu-shadow')

const baseStyleList = defineStyle({
  [$bg.variable]: '#fff',
  [$shadow.variable]: 'shadows.sm',
  _dark: {
    [$bg.variable]: 'colors.gray.700',
    [$shadow.variable]: 'shadows.dark-lg',
  },
  color: 'inherit',
  minW: '80px',
  maxW: '250px',
  py: '8px',
  px: '0px',
  zIndex: 1,
  borderRadius: 'md',
  borderWidth: '1px',
  bg: $bg.reference,
  boxShadow: $shadow.reference,
})

const baseStyleItem = defineStyle({
  py: '10px',
  px: '12px',
  transitionProperty: 'background',
  transitionDuration: 'ultra-fast',
  transitionTimingFunction: 'ease-in',
  _focus: {
    [$bg.variable]: 'colors.gray.100',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.100',
    },
  },
  _active: {
    [$bg.variable]: 'colors.gray.200',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.200',
    },
  },
  _expanded: {
    [$bg.variable]: 'colors.gray.100',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.100',
    },
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  bg: $bg.reference,
  textDecoration: 'none',
  color: 'inherit',
  userSelect: 'none',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  textAlign: 'start',
  flex: '0 0 auto',
  outline: 0,
})

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '2',
  opacity: 0.6,
})

const baseStyle = helpers.definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
  divider: baseStyleDivider,
})

export const inlineMenuTheme = helpers.defineMultiStyleConfig({
  baseStyle,
})
