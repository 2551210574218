import { useForm } from 'react-hook-form'
import { FormControl, Link, Stack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import _ from 'lodash'
import { z } from 'zod'

import {
  INVALID_LOGIN_EMAIL_DOMAIN_MESSAGE,
  isAllowedToAttemptLoginEmailSuffix,
} from '~shared/decorators/is-gov-sg-email'
import { AuthError } from '~shared/errors/types'

import { routes } from '~constants/routes'
import { useIsDesktop } from '~hooks/useIsDesktop'

import { UNKNOWN_LOGIN_ERROR_MESSAGE } from '../constants'
import { LoginFormInputs } from '../types'

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Please enter an email address.')
    .email({ message: 'Please enter a valid email address.' })
    .refine(isAllowedToAttemptLoginEmailSuffix, {
      message: INVALID_LOGIN_EMAIL_DOMAIN_MESSAGE,
    }),
})

interface LoginFormProps {
  onSubmit: (inputs: LoginFormInputs) => Promise<void>
  onOpenWaitlistModal: () => void
}

export const LoginForm = ({
  onSubmit,
  onOpenWaitlistModal,
}: LoginFormProps): JSX.Element => {
  const onSubmitForm = async (inputs: LoginFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      const message = _.get(
        e,
        'response.data.message',
        UNKNOWN_LOGIN_ERROR_MESSAGE,
      )
      const errorType = _.get(e, 'response.data.error')
      setError('email', { type: 'server', message })
      if (errorType === AuthError.WHITELIST) {
        onOpenWaitlistModal()
      }
    })
  }

  const { handleSubmit, register, formState, setError } =
    useForm<LoginFormInputs>({
      resolver: zodResolver(schema),
    })

  const isDesktop = useIsDesktop()

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void handleSubmit(onSubmitForm)(e)
  }

  return (
    <form noValidate onSubmit={handleFormSubmit}>
      <Stack direction="column" spacing="8px" align="stretch" mb="2.5rem">
        <FormControl
          isRequired
          isInvalid={!!formState.errors.email}
          isReadOnly={formState.isSubmitting}
        >
          <FormLabel htmlFor="email" textStyle="subhead-1" fontSize="16px">
            Log in with a whitelisted .gov.sg email address
          </FormLabel>
          <Input
            autoComplete="email"
            autoFocus
            placeholder="e.g. jane@data.gov.sg"
            {...register('email')}
          />
          <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
        </FormControl>
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          type="submit"
          color="white"
        >
          Get OTP
        </Button>
      </Stack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Link isExternal href={routes.faq}>
          Have a question?
        </Link>
      </Stack>
    </form>
  )
}
