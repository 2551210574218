import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { BiSolidErrorCircle, BiX } from 'react-icons/bi'
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'

import { useDeleteDocument, useGetDocuments } from '../hooks'

import { DocumentsUploadPanel } from './DocumentsUploadPanel'

type ModalPropsWithoutChildren = Omit<ModalProps, 'children'>
export interface DocumentsUploadModalProps extends ModalPropsWithoutChildren {
  isOpen: boolean
  onClose: () => void
  uploadedDocumentIds: string[]
  setUploadedDocumentIds: Dispatch<SetStateAction<string[]>>
  tokenLimit: number
  countLimit: number
  submitButtonLabel: string
  defaultSensitivityAcknowledged?: boolean
  isDeleteDocumentsOnClose?: boolean
}

export const DocumentsUploadModal = ({
  isOpen,
  onClose,
  uploadedDocumentIds,
  setUploadedDocumentIds,
  tokenLimit,
  countLimit,
  submitButtonLabel,
  defaultSensitivityAcknowledged = false,
  isDeleteDocumentsOnClose = false,
  ...props
}: DocumentsUploadModalProps) => {
  const { isBlockSubmission, blockSubmissionMessage, completedDocumentIds } =
    useGetDocuments({
      documentIds: uploadedDocumentIds,
      tokenLimit,
      countLimit,
    })

  // Store the original IDs when modal opens
  const originalDocumentIds = useRef<string[]>([])
  // Keep this in ref so useEffect below has clean dep array
  const latestUploadedIds = useRef(uploadedDocumentIds)
  // Keep the ref up to date
  latestUploadedIds.current = uploadedDocumentIds
  useEffect(() => {
    if (isOpen) {
      originalDocumentIds.current = [...latestUploadedIds.current]
    }
  }, [isOpen])

  const { mutate: deleteDocumentMutate } = useDeleteDocument()

  const closeAndRevertChanges = () => {
    // Delete any new documents that were uploaded
    const documentsToDelete = completedDocumentIds.filter(
      (id) => !originalDocumentIds.current.includes(id),
    )
    // Revert to original document IDs
    setUploadedDocumentIds(originalDocumentIds.current)
    // Delete new documents that were uploaded during this modal session
    documentsToDelete.forEach((documentId) => {
      deleteDocumentMutate({ documentId })
    })
    onClose()
  }

  const closeAndSaveChanges = () => {
    if (isDeleteDocumentsOnClose) {
      // Delete any new documents that were uploaded
      const documentsToDelete = originalDocumentIds.current.filter(
        (id) => !uploadedDocumentIds.includes(id),
      )
      // Delete new documents that were deleted during this modal session
      documentsToDelete.forEach((documentId) => {
        deleteDocumentMutate({ documentId })
      })
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={closeAndRevertChanges} {...props}>
      <ModalOverlay />
      <ModalContent>
        <HStack
          backgroundColor="utility.feedback.warning"
          color="base.content.strong"
          paddingY="8px"
          paddingX="12px"
        >
          <Icon as={BiSolidErrorCircle} fontSize="18px" />
          <Text textStyle="body-2">
            Please note: Pair <b>can&apos;t read images or URLs</b> in the
            documents yet, only text.
          </Text>
        </HStack>
        <ModalBody padding="0px">
          <VStack
            width="100%"
            alignItems="start"
            spacing="24px"
            paddingX="32px"
            paddingY="24px"
          >
            <HStack justifyContent="space-between" width="100%">
              <Text textStyle="h4">Upload files</Text>
              <IconButton
                icon={
                  <Icon as={BiX} fontSize="24px" color="base.content.strong" />
                }
                aria-label="close-documents-modal"
                variant="clear"
                onClick={closeAndRevertChanges}
              />
            </HStack>
            <DocumentsUploadPanel
              tokenLimit={tokenLimit}
              countLimit={countLimit}
              uploadedDocumentIds={uploadedDocumentIds}
              setUploadedDocumentIds={setUploadedDocumentIds}
              defaultSensitivityAcknowledged={defaultSensitivityAcknowledged}
              borderWidth="0px"
              padding="0xp"
            />
            <HStack width="100%" justifyContent="right" spacing="16px">
              <Button
                onClick={closeAndRevertChanges}
                variant="clear"
                color="base.content.strong"
              >
                Cancel
              </Button>

              <Tooltip
                label={blockSubmissionMessage}
                hasArrow
                closeDelay={200}
                placement={'top'}
                isDisabled={!isBlockSubmission}
              >
                <Button
                  onClick={closeAndSaveChanges}
                  color="white"
                  isDisabled={isBlockSubmission}
                >
                  {submitButtonLabel}
                </Button>
              </Tooltip>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
