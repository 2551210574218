import { BiSolidStar, BiStar } from 'react-icons/bi'
import { ButtonGroup, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import {
  Button,
  Checkbox,
  IconButton,
  Textarea,
} from '@opengovsg/design-system-react'
import _ from 'lodash'

type AssistantFeedbackFormControlProps = {
  feedbackOnClose: () => void
  onSubmit: () => void
  isDisabled: boolean
  isLoading: boolean
}

export function AssistantFeedbackFormControl({
  feedbackOnClose,
  onSubmit,
  isDisabled,
  isLoading,
}: AssistantFeedbackFormControlProps) {
  return (
    <ButtonGroup width="100%" justifyContent="flex-end" spacing="8px">
      <Button variant="clear" onClick={feedbackOnClose}>
        Cancel
      </Button>
      <Button
        color="white"
        onClick={onSubmit}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        Submit
      </Button>
    </ButtonGroup>
  )
}

type AssistantFeedbackFormBody = {
  isAssistantLevelFeedback: boolean
  headerText: string
  numericRating: number
  setNumericRating: React.Dispatch<React.SetStateAction<number>>
  placeholder?: string
  feedbackContent: string
  setFeedbackContent: React.Dispatch<React.SetStateAction<string>>
  isAllowCreatorToView: boolean
  setIsAllowCreatorToView: React.Dispatch<React.SetStateAction<boolean>>
}

export function AssistantFeedbackFormBody({
  isAssistantLevelFeedback,
  headerText,
  numericRating,
  setNumericRating,
  placeholder,
  feedbackContent,
  setFeedbackContent,
  isAllowCreatorToView,
  setIsAllowCreatorToView,
}: AssistantFeedbackFormBody) {
  return (
    <>
      <Flex
        justifyContent="space-between"
        flexDirection={isAssistantLevelFeedback ? 'column' : 'row'}
        gap={isAssistantLevelFeedback ? '2px' : 0}
      >
        <Text textStyle="subhead-2">{headerText}</Text>
        {isAssistantLevelFeedback && (
          <HStack spacing={0}>
            {_.range(5).map((index) => {
              const isSelected = index < numericRating

              return (
                <IconButton
                  key={index}
                  aria-label={`star-index-${index}`}
                  variant="clear"
                  icon={
                    <Icon
                      as={isSelected ? BiSolidStar : BiStar}
                      fontSize="18px"
                      color={
                        isSelected ? 'interaction.main.default' : 'slate.400'
                      }
                      _hover={{
                        color: isSelected
                          ? 'interaction.main.default'
                          : 'slate.700',
                      }}
                    />
                  }
                  // Index are 0 based, thus +1 to get the 1 based index
                  onClick={() => {
                    // If selecting self, means we are clearing the the star chosen
                    if (index === numericRating - 1) {
                      setNumericRating(0)
                    } else {
                      setNumericRating(index + 1)
                    }
                  }}
                />
              )
            })}
          </HStack>
        )}
      </Flex>
      <Textarea
        size="md"
        resize="none"
        placeholder={placeholder}
        minAutosizeRows={2}
        maxAutosizeRows={5}
        value={feedbackContent}
        onChange={(e) => {
          e.preventDefault()
          setFeedbackContent(e.target.value)
        }}
      />
      <Checkbox
        size="xs"
        colorScheme="dark"
        onChange={(e) => setIsAllowCreatorToView(e.target.checked)}
        checked={isAllowCreatorToView}
      >
        Share this chat and your email with the Assistant&apos;s creator for
        troubleshooting
      </Checkbox>
    </>
  )
}
