import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'

type InputFieldWithIconButtonProps = InputProps & {
  icon: IconButtonProps['icon']
  buttonAriaLabel: IconButtonProps['aria-label']
  onButtonClick?: () => void
}

export const InputFieldWithIconButton = forwardRef<
  InputFieldWithIconButtonProps,
  'input'
>(
  (
    { icon, buttonAriaLabel, onButtonClick, isDisabled, ...inputProps },
    ref,
  ) => (
    <InputGroup ref={ref} borderRadius="4px" size="sm">
      <Input {...inputProps} isDisabled={isDisabled} size="sm" />
      <InputRightElement>
        <IconButton
          size="sm"
          aria-label={buttonAriaLabel}
          icon={icon}
          onClick={onButtonClick}
          color="base.content.medium"
          // "!important" otherwise border disappears when button is disabled
          borderColor="base.divider.strong !important"
          borderRadius="0px"
          borderRightRadius="4px"
          fontSize="20px"
          variant="ghost"
          isDisabled={isDisabled}
        />
      </InputRightElement>
    </InputGroup>
  ),
)
