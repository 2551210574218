import { useParams, useSearchParams } from 'react-router-dom'
import { Center, Spinner, VStack } from '@chakra-ui/react'
import { HttpStatusCode, isAxiosError } from 'axios'

import { AssistantChatScreen } from '../components/AssistantChat/AssistantChatScreen'
import { AssistantNotFound } from '../components/AssistantChat/AssistantNotFound'
import { AssistantUnavailable } from '../components/AssistantChat/AssistantUnavailable'
import { NotFound } from '../components/Chat/NotFound'
import { VanillaChatScreen } from '../components/VanillaChat/VanillaChatScreen'
import { useBasicChatInputEditor } from '../hooks'
import { useChat } from '../hooks/useChat'

/**
 * The current setup for this page would be increasingly hard to maintain as we go about with assistants
 * and future features / UI changes.
 * TODOs:
 *
 * - Move over to another component page for "New Chat". This should include the destination of a shared assistant link
 * where the landing pages. This page should host the "Empty Screen" + toolbar. This page should only be
 * for the existing chats
 * - Clean up the hook. One possible approach is to have a separate handleFirstSubmit method that encapsulates
 * the handleSubmit. Adding the assistantId as part of the hook's argument as it currently is doesn't look right imo
 * since it leads to situations where all arguments can pretty much be partial and lead to funky states.
 * - Abstract out EmptyScreen further. This should be renamed to something cleaner, and should host the
 * prompt template preview highlights too. Create assistant chat window should be moved here too
 *
 * @date 21/11/2023 - 16:29:56
 *
 **/

export const ChatPage = (): JSX.Element => {
  const { chatId } = useParams()

  // Note: Assistant is provided as a query string for now
  const [searchParams] = useSearchParams()
  const paramAssistantId = searchParams.get('assistant') || undefined

  const {
    handleSubmit,
    messages,
    totalTokenCounts,
    chat,
    assistant: convoAssistant,
    assistantError,
    isChatLoading,
    isMutationLoading,
    isAssistantLoading,
    isStreaming,
    errorMessage,
  } = useChat({
    id: chatId,
    assistantId: paramAssistantId,
    placeholderIdPrefix: paramAssistantId,
  })

  const isSubmissionDisabled =
    isMutationLoading || isStreaming || !!errorMessage

  const editor = useBasicChatInputEditor(
    {
      onSubmit: handleSubmit,
    },
    [chatId, paramAssistantId],
  )

  const assistantId = paramAssistantId ?? convoAssistant?.id

  if (isChatLoading || isAssistantLoading) {
    return (
      <Center flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  if (chatId && messages.length === 0) {
    if (chat?.assistant_id) {
      return <AssistantNotFound />
    }
    return <NotFound />
  }

  if (assistantId && !convoAssistant) {
    if (
      isAxiosError(assistantError) &&
      assistantError.response?.status === HttpStatusCode.Forbidden
    ) {
      return <AssistantUnavailable agencyDenied={true} />
    }
    return <NotFound />
  }

  return (
    <VStack width="100%" justifyContent="space-between" spacing="0px">
      {assistantId ? (
        <AssistantChatScreen
          assistantId={assistantId}
          editor={editor}
          messages={messages}
          totalTokenCounts={totalTokenCounts}
          isMutationLoading={isMutationLoading}
          isStreaming={isStreaming}
          errorMessage={errorMessage}
          isSubmissionDisabled={isSubmissionDisabled}
          chatId={chatId}
        />
      ) : (
        <VanillaChatScreen
          editor={editor}
          messages={messages}
          totalTokenCounts={totalTokenCounts}
          isMutationLoading={isMutationLoading}
          isStreaming={isStreaming}
          errorMessage={errorMessage}
          isSubmissionDisabled={isSubmissionDisabled}
        />
      )}
    </VStack>
  )
}
