import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

import { ChatWithMessagesDto } from '~shared/types/chats.dto'

type AssistantPreviewStateType = {
  isUpdatingPreview: boolean
  previewChatHistory: ChatWithMessagesDto[]
  currentChatId?: string
}

type AssistantPreviewContextType = {
  state: AssistantPreviewStateType
  setIsUpdatingPreview: (isUpdating: boolean) => void
  setCurrentChatId: (currentChatId: string | undefined) => void
  setPreviewChatHistory: (chatHistory: ChatWithMessagesDto[]) => void
}

const AssistantPreviewContext = createContext<
  Map<string, AssistantPreviewStateType> | undefined
>(undefined)

const AssistantPreviewUpdaterContext = createContext<
  | ((assistantId: string, updates: Partial<AssistantPreviewStateType>) => void)
  | undefined
>(undefined)

export const AssistantPreviewStateProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [assistantPreviewStates, setAssistantPreviewStates] = useState(
    new Map<string, AssistantPreviewStateType>(),
  )

  const updateState = useCallback(
    (assistantId: string, updates: Partial<AssistantPreviewStateType>) => {
      setAssistantPreviewStates((prevState) => {
        const newState = new Map(prevState)
        const existingState = newState.get(assistantId) ?? {
          isUpdatingPreview: false,
          previewChatHistory: [],
        }
        newState.set(assistantId, { ...existingState, ...updates })
        return newState
      })
    },
    [],
  )

  return (
    <AssistantPreviewContext.Provider value={assistantPreviewStates}>
      <AssistantPreviewUpdaterContext.Provider value={updateState}>
        {children}
      </AssistantPreviewUpdaterContext.Provider>
    </AssistantPreviewContext.Provider>
  )
}

export const useAssistantPreviewContext = (
  assistantId?: string,
): AssistantPreviewContextType => {
  const states = useContext(AssistantPreviewContext)
  const updateState = useContext(AssistantPreviewUpdaterContext)

  const assistantOrPlaceholderId = assistantId ?? 'create-placeholder-id'
  if (states === undefined || updateState === undefined) {
    throw new Error(
      'useAssistantPreviewContext must be used within a AssistantPreviewStateProvider',
    )
  }

  const state = states.get(assistantOrPlaceholderId) ?? {
    isUpdatingPreview: false,
    previewChatHistory: [],
  }

  const setIsUpdatingPreview = useCallback(
    (isUpdating: boolean) => {
      updateState(assistantOrPlaceholderId, { isUpdatingPreview: isUpdating })
    },
    [assistantOrPlaceholderId, updateState],
  )

  const setCurrentChatId = useCallback(
    (currentChatId: string | undefined) => {
      updateState(assistantOrPlaceholderId, { currentChatId })
    },
    [assistantOrPlaceholderId, updateState],
  )

  const setPreviewChatHistory = useCallback(
    (chatHistory: ChatWithMessagesDto[]) => {
      updateState(assistantOrPlaceholderId, { previewChatHistory: chatHistory })
    },
    [assistantOrPlaceholderId, updateState],
  )

  return {
    state,
    setIsUpdatingPreview,
    setCurrentChatId,
    setPreviewChatHistory,
  }
}
