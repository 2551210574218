import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import moment from 'moment-timezone'

import { ListChatsResponseDto } from '~shared/types/chats.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'
import { generateTimestampGroup } from '~utils/date'

const listChats = async () => {
  return ApiService.get<ListChatsResponseDto>('/chats').then((response) => {
    return response.data
  })
}

export function useListChats() {
  return useQuery(queries.chat.list.queryKey, listChats, {
    select: (data) => {
      // Generates current timestamp at query update
      const currentMoment = moment()

      return _.map(data.chats, (chatData) => ({
        id: chatData.id,
        title: chatData.title || 'Placeholder Title',
        assistantId: chatData.assistant_id,
        assistant: chatData.assistant
          ? {
              id: chatData.assistant.id,
              name: chatData.assistant.name,
            }
          : undefined,
        createdAt: chatData.created_at,
        updatedAt: chatData.updated_at,
        timestampGroup: generateTimestampGroup(
          chatData.updated_at,
          currentMoment,
        ),
      }))
    },

    refetchOnWindowFocus: false,
  })
}
