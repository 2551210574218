import { BiPencil } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, ButtonGroup, HStack, Text } from '@chakra-ui/react'

import { SharedAssistantDto } from '~shared/types/assistants.dto'

import { useAuth } from '~lib/auth'
import { routes } from '~constants/routes'

import {
  AssistantAvatar,
  AssistantShareLinkPopover,
} from '~features/assistants'
import { AssistantChatHeaderMoreButton } from '~features/assistants/components/AssistantChatHeaderMoreButton'

import { ShareButton } from '../Chat/ShareButton'

import { ReviewAssistantButton } from './ReviewAssistantButton'

type ChatHeaderProps = {
  assistant: SharedAssistantDto
  latestMessageId: string | undefined
}

const AssistantShareButton = ({
  assistant,
}: {
  assistant?: SharedAssistantDto
}) => (
  <ShareButton
    isDisabled={assistant ? assistant.view_access === 'private' : true}
  />
)

export const AssistantChatHeader = ({
  assistant,
  latestMessageId,
}: ChatHeaderProps) => {
  const { user } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()

  const isAssistantOwner = assistant?.created_by === user?.email

  return (
    <HStack
      alignItems="center"
      paddingX="24px"
      minH="48px"
      width="100%"
      borderColor="base.divider.medium"
      borderBottomWidth="1px"
      justifyContent="space-between"
      bgColor="blue.50"
    >
      <HStack spacing="8px">
        <AssistantAvatar avatarSize="2xs" />
        <Text textStyle="subhead-1" noOfLines={1}>
          {assistant?.name}
        </Text>
      </HStack>
      <ButtonGroup spacing="8px" alignItems="center">
        <AssistantChatHeaderMoreButton
          assistant={assistant}
          isAssistantOwner={isAssistantOwner}
          messageId={latestMessageId}
        />

        {isAssistantOwner ? (
          <Button
            size="xs"
            variant="clear"
            colorScheme="sub"
            color="base.content.default"
            leftIcon={<BiPencil fontSize="20px" />}
            onClick={() =>
              navigate(`${routes.assistants}/${assistant.id}`, {
                state: { from: location },
              })
            }
            _active={{
              bg: 'interaction.sub-subtle.hover',
            }}
            _hover={{
              bg: 'interaction.sub-subtle.default',
            }}
          >
            Edit
          </Button>
        ) : (
          <ReviewAssistantButton
            assistantId={assistant.id}
            messageId={latestMessageId}
          />
        )}

        <AssistantShareLinkPopover assistant={assistant}>
          {/* Fix to forward ref by wrapping component with Box as suggested by: https://github.com/chakra-ui/chakra-ui/discussions/2774 */}
          <Box>
            <AssistantShareButton assistant={assistant} />
          </Box>
        </AssistantShareLinkPopover>
      </ButtonGroup>
    </HStack>
  )
}
