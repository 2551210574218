import { useQuery } from '@tanstack/react-query'

import { GetSharedAssistantResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const getSharedAssistant = async (assistantId: string) => {
  return ApiService.get<GetSharedAssistantResponseDto>(
    `/assistants/shared/${assistantId}`,
  ).then((response) => response.data)
}

export function useGetSharedAssistant({
  assistantId,
}: {
  assistantId?: string
}) {
  return useQuery(
    queries.assistants.sharedDetail(assistantId ?? '').queryKey,
    () => {
      if (assistantId) return getSharedAssistant(assistantId)
      return null
    },
  )
}
