import { BiMenu, BiPlus } from 'react-icons/bi'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { IconButton } from '@opengovsg/design-system-react'

import PairLogo from '~/assets/pair-logo-white.svg'
import { useFeatureAnnouncement } from '~/stores/user-preferences'

import { useTrackedFlag } from '~lib/feature-flag'
import { routes } from '~constants/routes'
import { FeatureFlagBanner } from '~components/FeatureFlagBanner'
import { Sidebar } from '~components/Sidebar'
import { SupportButton } from '~components/SupportButton'

import { DocumentsAnnouncementModal } from '~features/announcements/DocumentsAnnouncementModal'
import { useListChats } from '~features/chatv2'

export const LeftSidebarTemplatePage = (): JSX.Element => {
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure()
  const navigate = useNavigate()

  const { data, isLoading } = useListChats()
  const conversationsList = isLoading || !data ? [] : data

  const onClickCreate = () => navigate(routes.chat)
  const {
    hasSeen: hasSeenDocumentsAnnouncement,
    setHasSeen: setHasSeenDocumentsAnnouncement,
  } = useFeatureAnnouncement('documents')

  const { isEnabled: isDocumentsInChatEnabled } = useTrackedFlag(
    'documents_chat_enable',
  )
  return (
    <Flex height="100%" flexDir="column">
      <FeatureFlagBanner variant="private-banner" />
      <DocumentsAnnouncementModal
        isOpen={!hasSeenDocumentsAnnouncement && isDocumentsInChatEnabled}
        onClose={() => setHasSeenDocumentsAnnouncement()}
      />
      <SupportButton />

      {/* Mobile/tablet display navbar */}
      <HStack
        display={{ base: 'flex', lg: 'none' }}
        align="center"
        justify="space-between"
        background="blue.1000"
        paddingX="16px"
        paddingY="16px"
        borderBottomWidth="1px"
        borderStyle="solid"
        borderColor="brand.secondary.100"
      >
        <IconButton
          aria-label="Open menu"
          width="fit-content"
          onClick={onSidebarOpen}
          icon={<BiMenu />}
          colorScheme="base.content.default"
          _hover={{
            backgroundColor: 'interaction.tinted.inverse.hover',
          }}
        />
        {/* App logo */}
        <Image src={PairLogo} width="50px" alt="app logo" />
        <IconButton
          onClick={onClickCreate}
          icon={<BiPlus />}
          aria-label="New chat"
          colorScheme="base.content.default"
          _hover={{
            backgroundColor: 'interaction.tinted.inverse.hover',
          }}
        />
      </HStack>

      <VStack alignItems="left" spacing="0px" flex={1} minH={0}>
        <Flex alignItems="stretch" flex={1} minH={0} maxW="100%">
          <Drawer
            isOpen={isSidebarOpen}
            onClose={onSidebarClose}
            placement="left"
          >
            <DrawerOverlay />
            <DrawerContent backgroundColor="blue.1000">
              <Sidebar
                conversations={conversationsList}
                isDrawer={true}
                onClickCreate={onClickCreate}
                onClickCloseSidebar={onSidebarClose}
              />
            </DrawerContent>
          </Drawer>

          <VStack
            alignItems="stretch"
            width="266px"
            backgroundColor="blue.1000"
            display={{ base: 'none', lg: 'initial' }}
          >
            <Sidebar
              conversations={conversationsList}
              isDrawer={false}
              onClickCreate={onClickCreate}
            />
          </VStack>

          {/* Note: CSS calc needed here as we intend for sidebar to remain an absolute width */}
          <Flex
            maxW={{ base: '100%', lg: 'calc(100% - 266px)' }}
            flexGrow={1}
            overflowY="auto"
          >
            <Outlet />
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  )
}
