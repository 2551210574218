import {
  ASSISTANT_DOCUMENTS_CONTEXT_LIMIT,
  MESSAGE_DOCUMENTS_CONTEXT_LIMIT,
} from './documents'

// Taken from lowest common denominator (claude), which is capped at 1000
export const MODEL_CONVERSATION_SIZE_LIMIT = 900

// Total token usage we allow for all messages (not including documents)
export const BASE_CHAT_CONTENT_CONTEXT_LIMIT = 10000
// We allow twice the single chat documents limit
export const BASE_CHAT_DOCUMENTS_CONTEXT_LIMIT =
  MESSAGE_DOCUMENTS_CONTEXT_LIMIT * 2

// Note, these are deliberately large values to act as a general sanity check.
export const BASE_CHAT_SYSTEM_PROMPT_CONTEXT_LIMIT = 2000
export const ASSISTANT_SYSTEM_PROMPT_CONTEXT_LIMIT = 15000
export const ASSISTANT_WITH_DOCUMENTS_SYSTEM_PROMPT_CONTEXT_LIMIT =
  ASSISTANT_SYSTEM_PROMPT_CONTEXT_LIMIT + ASSISTANT_DOCUMENTS_CONTEXT_LIMIT
