import { FC, memo } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'

/**
 * React Markdown is stupidly expensive when used with other plugins
 * This components memoizes based on the children and className props of React Markdown
 * to only re-render when necessary
 */

export const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
)
