import { routes, shareRoutes } from '~constants/routes'

// For generation of sharable assistant links. Redirects to the assistant consumable URL
export function generateAssistantSharingLinkFromId(id: string) {
  return `${window.location.origin}${shareRoutes.assistant}/${id}`
}

// For generating a consumable link for users. To use for normal, non-sharing consumption
export function generateAssistantPathFromId(id: string) {
  return `${routes.chat}?assistant=${id}`
}
