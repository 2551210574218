import { IconType } from 'react-icons'
import {
  BiConversation,
  BiHelpCircle,
  BiInfoCircle,
  BiMailSend,
} from 'react-icons/bi'
import {
  Box,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  FEEDBACK_FORM_URL,
  PAIR_WISE_URL,
  SUPPORT_FORM_URL,
} from '~constants/config'

type SupportLinkMenuItemProps = {
  text: string
  icon: IconType
  href: string
  isExternal?: boolean
}

const SupportLinkMenuItem = ({
  text,
  icon,
  href,
  isExternal,
}: SupportLinkMenuItemProps) => {
  return (
    <MenuItem padding="0px" width="300px">
      <LinkBox width="100%">
        <HStack
          width="100%"
          paddingX="16px"
          paddingY="12px"
          alignItems="center"
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'interaction.muted.main.hover',
          }}
          _active={{
            cursor: 'pointer',
            backgroundColor: 'interaction.muted.main.active',
          }}
        >
          <Icon boxSize="20px" as={icon} />
          <LinkOverlay href={href} isExternal={isExternal}>
            <Text paddingLeft="8px" textStyle="body-1">
              {text}
            </Text>
          </LinkOverlay>
        </HStack>
      </LinkBox>
    </MenuItem>
  )
}

export const SupportButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box position="fixed" bottom="12px" right="12px" zIndex={2}>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          size="xs"
          aria-label="Support"
          variant="clear"
          color="base.content.strong"
          colorScheme="main"
          borderRadius="full"
          icon={<BiHelpCircle fontSize="24px" />}
          onClick={onOpen}
        />
        <MenuList>
          <SupportLinkMenuItem
            text="Give us feedback"
            href={FEEDBACK_FORM_URL}
            icon={BiConversation}
            isExternal
          />
          <SupportLinkMenuItem
            text="View our user guide"
            href={PAIR_WISE_URL}
            icon={BiInfoCircle}
            isExternal
          />
          <SupportLinkMenuItem
            text="Contact support"
            href={SUPPORT_FORM_URL}
            icon={BiMailSend}
            isExternal
          />
        </MenuList>
      </Menu>
    </Box>
  )
}
