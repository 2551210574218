import { BiLink } from 'react-icons/bi'
import { Button } from '@opengovsg/design-system-react'

export const ShareButton = ({
  isDisabled = false,
}: {
  isDisabled?: boolean
}) => (
  <Button
    size="xs"
    variant="clear"
    colorScheme="sub"
    color="base.content.default"
    leftIcon={<BiLink fontSize="20px" />}
    isDisabled={isDisabled}
    padding="8px"
    _active={{
      bg: 'interaction.sub-subtle.hover',
    }}
    _hover={{
      bg: isDisabled ? 'transparent' : 'interaction.sub-subtle.default',
    }}
  >
    Share
  </Button>
)
