import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

type CloseConfirmationModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const CloseConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: CloseConfirmationModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton size="sm" />
      <ModalHeader>Are you sure you want to cancel?</ModalHeader>
      <ModalBody>
        Cancelling now would cause you to lose all your progress. This is
        irreversible.
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button
            variant="clear"
            color="base.content.default"
            onClick={onClose}
          >
            Go back to edit
          </Button>
          <Button
            colorScheme="red"
            backgroundColor="interaction.critical.default"
            mr={3}
            onClick={onConfirm}
          >
            Cancel and lose all progress
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
