import { lazy, PropsWithChildren, Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { DisabledStaticPagesRoutes } from '~/pages'

import { APP_ENV } from '~constants/config'
import { routes } from '~constants/routes'
import { lazyImport } from '~utils/lazyImport'

import { AssistantsRoutes } from '~features/assistants'
import { ChatV2Routes } from '~features/chatv2'
import { ShareRoutes } from '~features/share/routes'

const { AuthRoutes } = lazyImport(() => import('~features/auth'), 'AuthRoutes')

const { HealthRoutes } = lazyImport(
  () => import('~features/health'),
  'HealthRoutes',
)

const LazyStaticPagesRoutes = () => {
  const reactAppEnv = APP_ENV
  const staticEnabled =
    reactAppEnv &&
    (reactAppEnv === 'production' || reactAppEnv === 'development')
  if (staticEnabled) {
    const LazyStaticPageRoutes = lazy(async () => {
      const module = await import('~pages/routes/index')
      return { default: module.StaticPagesRoutes }
    })
    return (
      <WithSuspense>
        <LazyStaticPageRoutes />
      </WithSuspense>
    )
  }
  return <DisabledStaticPagesRoutes />
}

const router = createBrowserRouter([
  {
    path: `${routes.login}/*`,
    element: <AuthRoutes />,
  },
  {
    path: `${routes.chat}/*`,
    element: <ChatV2Routes />,
  },
  {
    path: `${routes.assistants}/*`,
    element: <AssistantsRoutes />,
  },
  {
    path: `${routes.share}/*`,
    element: <ShareRoutes />,
  },
  {
    path: routes.health,
    element: <HealthRoutes />,
  },
  {
    path: routes.pages,
    element: <LazyStaticPagesRoutes />,
  },
  // TODO: Confirm development of a dashboard page
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <Box height="100vh" minH="$100vh" maxH="$100vh">
        <RouterProvider router={router} />
      </Box>
    </WithSuspense>
  )
}
