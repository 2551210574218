import { ReactNode, useEffect, useRef } from 'react'
import { StackProps, VStack } from '@chakra-ui/react'

import { ChatMessageDto } from '~shared/types/chats.dto'

import { useAutoScrollToBottom } from '~features/chatv2/hooks'

import { ChatMessage } from './ChatMessage'
import { ChatScrollAnchor } from './ChatScrollAnchor'
import { ErrorMessage } from './ErrorMessage'

interface ChatMessageWindowProps extends StackProps {
  isAiResponseLoading: boolean
  isAiStreaming: boolean
  messages: ChatMessageDto[]
  errorMessage: string | null
  defaultAiAvatar?: ReactNode
  assistantId?: string
}

export const ChatMessageWindow = ({
  messages,
  isAiResponseLoading,
  isAiStreaming,
  errorMessage,
  defaultAiAvatar,
  assistantId,
  ...props
}: ChatMessageWindowProps) => {
  // Ref to track the scroll state of window
  const ref = useRef<HTMLDivElement>(null)

  // Autoscrolls when streaming is happening
  useAutoScrollToBottom({ scrollElement: ref.current }, [messages])

  // Auto-scroll to bottom when new message error message
  useEffect(() => {
    ref.current?.scrollTo(0, ref.current.scrollHeight)
  }, [isAiResponseLoading, errorMessage])

  return (
    <VStack
      ref={ref}
      width="100%"
      overflowY="auto"
      spacing="0px"
      alignItems="stretch"
      flexGrow={1}
      {...props}
    >
      {messages.map((message, idx) => {
        return (
          <ChatMessage
            id={message.id}
            key={idx}
            content={message.content}
            author={message.author}
            documents={message.documents}
            tool={message.tool}
            sources={message.sources}
            defaultAiAvatar={defaultAiAvatar}
            assistantId={assistantId}
          />
        )
      })}

      {/* Loading state, which presents itself as a chat message */}
      {isAiResponseLoading && (
        <ChatMessage
          id=""
          content=""
          author="assistant"
          tool="llm"
          isLoading={true}
          defaultAiAvatar={defaultAiAvatar}
        />
      )}
      {errorMessage && (
        <ErrorMessage
          defaultAiAvatar={defaultAiAvatar}
          content={
            errorMessage
              ? errorMessage
              : 'The chat has encountered an unidentified error. Please refresh the chat and try again.'
          }
        />
      )}

      {/* Automatically scroll down to the bottom on reply, provided
       * that user is within scroll intersection area
       */}
      <ChatScrollAnchor trackVisibility={isAiStreaming} scrollRef={ref} />
    </VStack>
  )
}
