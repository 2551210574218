import { useRef } from 'react'
import { BiSolidInfoCircle } from 'react-icons/bi'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react'
import { Tag } from '@opengovsg/design-system-react'

import { AssistantSchemaKeys } from '~features/assistants/schema/assistant-schema'

import { FieldNameToLabelDict } from './constants/fields'
interface AssistantSubmitConfirmationDialogProps {
  isOpen: boolean
  onClose: () => void
  updatedFields: AssistantSchemaKeys[]
  onSubmit: () => void
}

export const AssistantSubmitConfirmationDialog = ({
  isOpen,
  onClose,
  updatedFields,
  onSubmit,
}: AssistantSubmitConfirmationDialogProps): JSX.Element => {
  const cancelRef = useRef<HTMLButtonElement | null>(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Publish these changes to everyone using your assistant?
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing="24px" width="100%" alignItems="start">
              <VStack spacing="8px" width="100%">
                <HStack
                  padding="16px"
                  backgroundColor="utility.feedback.info-subtle"
                  width="100%"
                >
                  <Icon
                    as={BiSolidInfoCircle}
                    color="utility.feedback.info"
                    fontSize="20px"
                  />
                  <Text textStyle="body-1"> You have made changes to:</Text>
                </HStack>
                <Wrap spacingX="8px" width="100%">
                  {updatedFields.map((updatedField) => (
                    <Tag
                      size="md"
                      color="interaction.sub.default"
                      backgroundColor="interaction.sub-subtle.default"
                      colorScheme="interaction.sub"
                      textStyle="subhead-2"
                      key={updatedField}
                    >
                      {FieldNameToLabelDict[updatedField]}
                    </Tag>
                  ))}
                </Wrap>
              </VStack>
              <Text>
                Publishing these changes would apply the changes to anyone using
                your assistant. Are you sure you want to publish these changes?
              </Text>
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant="clear" size="sm">
              <Text textStyle="subhead-1" color="base.content.strong">
                Cancel
              </Text>
            </Button>
            <Button
              backgroundColor="interaction.main.default"
              onClick={onSubmit}
              ml={3}
              size="sm"
            >
              <Text textStyle="subhead-1" color="white">
                Confirm
              </Text>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
