/**
 * Email domains that are allowed to sign up for a user account.
 * Refer to backend/src/auth/auth.guard.ts > IsWhitelistedGuard > isEmailAllowed for BE auth logic.
 * Only whitelisted domains are exposed to the FE (see frontend/src/features/auth/components/LoginForm.tsx).
 *
 * If SIGNUP_IS_OPEN is specified to true, a user is determined to be "allowed" if they meet one of the criterias:
 *   - User is already on the whitelisted domains (eg; open.gov.sg). Whitelist takes precedence over blacklist for now, but should be fixed
 *   - User is already in the db. Legacy: For scenarios where we explicitly onboard banned gov emails (eg; tom@mfa.gov.sg)
 *   - User is not on the list of banned domains
 */

import { ShareableDomainDto } from '../types/assistants.dto'

export const HEALTHCARE_DOMAINS: ShareableDomainDto[] = [
  // MOHH domains
  { label: 'Ministry of Health Holdings (MOHH)', value: 'mohh.com.sg' },
  { label: 'Synapxe (MOHH)', value: 'synapxe.sg' },
  { label: '1 Finance Shared Service (MOHH)', value: '1fss.com.sg' },
  // SingHealth domains
  { label: 'Singapore General Hospital (SingHealth)', value: 'sgh.com.sg' },
  {
    label: 'National Cancer Centre Singapore (SingHealth)',
    value: 'nccs.com.sg',
  },
  {
    label: 'National Dental Centre Singapore (SingHealth)',
    value: 'ndcs.com.sg',
  },
  {
    label: 'National Heart Centre Singapore (SingHealth)',
    value: 'nhcs.com.sg',
  },
  {
    label: 'National Neuroscience Institute (SingHealth)',
    value: 'nni.com.sg',
  },
  { label: 'Sengkang General Hospital (SingHealth)', value: 'skh.com.sg' },
  { label: 'Changi General Hospital (SingHealth)', value: 'cgh.com.sg' },
  {
    label: "KK Women's and Children's Hospital (SingHealth)",
    value: 'kkh.com.sg',
  },
  { label: 'Singapore National Eye Centre (SingHealth)', value: 'snec.com.sg' },
  { label: 'Singapore Cord Blood Bank (SingHealth)', value: 'scbb.com.sg' },
  { label: 'SingHealth Group (SingHealth)', value: 'singhealth.com.sg' },
  {
    label: 'National Organ Transplant Unit (SingHealth)',
    value: 'notu.com.sg',
  },
  {
    label: 'Singapore Eye Research Institute (SingHealth)',
    value: 'seri.com.sg',
  },
  {
    label: 'Unit for Pre-hospital Emergency Care (SingHealth)',
    value: 'upec.sg',
  },
  {
    label: 'SingHealth Academy (SingHealth)',
    value: 'singhealthacademy.edu.sg',
  },
  {
    label: 'SingHealth Community Hospitals (SingHealth)',
    value: 'singhealthch.com.sg',
  },
  {
    label: 'Singapore Sport and Exercise Medicine Centre (SingHealth)',
    value: 'ssmc.sg',
  },
  {
    label: 'National Dental Centre Singapore (SingHealth)',
    value: 'ndc.com.sg',
  },
  // CRIS domains
  {
    label: 'Consortium for Clinical Research and Innovation, Singapore (CRIS)',
    value: 'cris.sg',
  },
  {
    label: 'Advanced Cell Therapy and Research Institute, Singapore (CRIS)',
    value: 'actris.cris.sg',
  },
  {
    label: 'National Health Innovation Centre Singapore (CRIS)',
    value: 'nhic.cris.sg',
  },
  {
    label: 'Precision Health Research, Singapore (CRIS)',
    value: 'precise.cris.sg',
  },
  {
    label: 'Singapore Clinical Research Institute (CRIS)',
    value: 'scri.cris.sg',
  },
  {
    label: 'Cardiovascular Disease National Collaborative Enterprise (CRIS)',
    value: 'cadence.cris.sg',
  },
  {
    label: 'Singapore Translational Cancer Consortium (CRIS)',
    value: 'stcc.cris.sg',
  },
  // NHG domains
  { label: 'National Healthcare Group (NHG)', value: 'nhg.com.sg' },
  { label: 'Woodlands Health (NHG)', value: 'wh.com.sg' },
  { label: 'Khoo Teck Puat Hospital (NHG)', value: 'ktph.com.sg' },
  { label: 'Tan Tock Seng Hospital (NHG)', value: 'ttsh.com.sg' },
  { label: 'National Centre for Infectious Diseases (NHG)', value: 'ncid.sg' },
  { label: 'National Skin Centre (NHG)', value: 'nsc.com.sg' },
  {
    label: 'National Healthcare Group Polyclinics (NHG)',
    value: 'nhgp.com.sg',
  },
  {
    label: 'National Healthcare Group Pharmacy (NHG)',
    value: 'pharmacy.nhg.com.sg',
  },
  { label: 'Yishun Community Hospital (NHG)', value: 'yishunhospital.com.sg' },
  {
    label: 'National Healthcare Group Diagnostics (NHG)',
    value: 'diagnostics.nhg.com.sg',
  },
  { label: 'Institute of Mental Health (NHG)', value: 'imh.com.sg' },
  { label: 'Primary Care Academy (NHG)', value: 'pca.sg' },
  {
    label: 'Geriatric Education & Research Institute (NHG)',
    value: 'geri.com.sg',
  },
]

export const HEALTHCARE_EMAIL_SUFFIXES = HEALTHCARE_DOMAINS.map(
  (domain) => `@${domain.value}`,
)

export const WHITELISTED_EMAIL_SUFFIXES = [
  '.gov.sg',
  '@science.edu.sg',
  '@sp.edu.sg',
  '@ite.edu.sg',
  '@nyp.edu.sg',
  '@np.edu.sg',
  '@rp.edu.sg',
  '@aci.edu.sg',
  '@sirs.edu.sg',
]

export const BANNED_GOVERNMENT_DOMAINS = [
  'pair.gov.sg',
  'dsta.gov.sg',
  'mci.gov.sg',
]

// TODO(PAIR-2012): rm after VAPT
export const VAPT_EMAIL_SUFFIXES = [
  '@cure53.de',
  '@volt.cure53.de',
  '@cüre53.de',
]
