import { ReactNode } from 'react'
import { HStack, StackProps } from '@chakra-ui/react'

type SidebarItemContainerProps = StackProps & {
  children: ReactNode
  onClick?: () => void
  isSelected?: boolean
}

export const SidebarItemContainer = ({
  children,
  onClick,
  isSelected = false,
  ...stackProps
}: SidebarItemContainerProps) => {
  return (
    <HStack
      paddingX="8px"
      paddingY="4px"
      borderRadius="4px"
      onClick={onClick}
      aria-selected={isSelected}
      justifyContent="space-between"
      alignItems={'center'}
      _hover={{
        cursor: 'pointer',
        backgroundColor: 'interaction.tinted.inverse.hover',
      }}
      _selected={{
        backgroundColor: 'interaction.tinted.inverse.active',
      }}
      userSelect="none"
      {...stackProps}
    >
      {children}
    </HStack>
  )
}
