import { DocumentMimeTypeType } from '../types/documents.dto'

export const UPLOAD_DOCUMENT_MAX_SIZE_IN_MEGABYTES = 30
export const UPLOAD_DOCUMENT_MAX_SIZE_IN_BYTES =
  UPLOAD_DOCUMENT_MAX_SIZE_IN_MEGABYTES * 1024 * 1024

export const ASSISTANT_DOCUMENTS_CONTEXT_LIMIT = 90_000
export const MESSAGE_DOCUMENTS_CONTEXT_LIMIT = 30_000

export const ASSISTANT_DOCUMENTS_COUNT_LIMIT = 100
export const MESSAGE_DOCUMENTS_COUNT_LIMIT = 20

export type MimeTypeOption = {
  mimeType: DocumentMimeTypeType
  extension: string
  label: string
}
export const SUPPORTED_MIME_TYPES: MimeTypeOption[] = [
  {
    mimeType: 'application/pdf',
    extension: '.pdf',
    label: 'PDF',
  },
  {
    mimeType: 'text/plain',
    extension: '.txt',
    label: 'TXT',
  },
  {
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: '.docx',
    label: 'DOCX',
  },
]
