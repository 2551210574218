import { Navigate, Route, Routes } from 'react-router-dom'

import { PrivateElement } from '~/app/PrivateElement'

import { routes } from '~constants/routes'
import { LeftSidebarTemplatePage } from '~templates/LeftSidebarTemplatePage'

import { ChatPage } from './ChatPage'

const RouteTemplate = () => {
  return <PrivateElement element={<LeftSidebarTemplatePage />} />
}

export const ChatV2Routes = () => {
  return (
    <Routes>
      {/*
        Errors thrown will redirect to base `/chat` page.
        TODO: Add better fallback error component. This should be a static element
      */}
      <Route
        element={<RouteTemplate />}
        errorElement={<Navigate to={routes.chat} />}
      >
        <Route index element={<ChatPage />} />
        <Route path=":chatId" element={<ChatPage />} />
        <Route path="*" element={<Navigate to="." />} />
      </Route>
    </Routes>
  )
}

// Re-exports as index
export { ChatPage } from './ChatPage'
