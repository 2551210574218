import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import {
  Box,
  Flex,
  Grid,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Link, TouchableTooltip } from '@opengovsg/design-system-react'

import { TruncatedAssistantDto } from '~shared/types/assistants.dto'

import { generateAssistantPathFromId } from '~features/assistants/utils'

import { AssistantAccessTag } from '../AssistantAccessTag'

import { AssistantDiscoveryMoreButton } from './AssistantDiscoveryMoreButton'

type AssistantCardProps = {
  assistant: TruncatedAssistantDto
  isCurrentUserCreated?: boolean
  conversationCount?: number
  feedbackCount?: number
}

export const AssistantCard = ({
  assistant,
  isCurrentUserCreated = false,
  conversationCount = 0,
  feedbackCount = 0,
}: AssistantCardProps) => {
  const location = useLocation()

  return (
    <LinkBox
      as={Grid}
      gridTemplateColumns={{
        base: 'minmax(200px, 1fr) 268px auto',
        md: 'minmax(200px, 1fr) 268px auto',
      }}
      gap="16px"
      width="100%"
      alignItems="start"
      paddingY="12px"
      paddingX="8px"
      _hover={{ backgroundColor: 'interaction.muted.neutral.hover' }}
      borderBottom="1px"
      borderColor="base.divider.medium"
    >
      {/* Main content */}
      <VStack
        alignItems="flex-start"
        spacing="0px"
        minWidth="0"
        paddingRight={{ base: '16px', lg: '32px' }}
      >
        <LinkOverlay
          as={ReactRouterLink}
          to={generateAssistantPathFromId(assistant.id)}
        >
          <Text textStyle="subhead-2" color="base.content.strong" noOfLines={1}>
            {assistant.name}
          </Text>
        </LinkOverlay>
        <Text textStyle="caption-2" color="base.content.medium" noOfLines={2}>
          {assistant.description}
        </Text>
      </VStack>

      {/* Middle section */}
      {isCurrentUserCreated ? (
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          gap="16px"
          height="100%"
        >
          <Box display="flex" flexGrow={1}>
            <TouchableTooltip
              padding="8px 12px"
              borderRadius="4px"
              hasArrow
              label={
                <Text textStyle="body-2" color="white">
                  View feedback and metrics
                </Text>
              }
              placement="top"
              wrapperStyles={{
                lineHeight: 0,
              }}
            >
              <Link
                as={ReactRouterLink}
                textStyle="caption-1"
                textAlign="left"
                display="inline-block"
                variant="standalone"
                color="base.content.medium"
                _hover={{
                  color: 'base.content.medium',
                  textDecor: 'underline',
                }}
                to={`/assistants/${assistant.id}/feedbacks`}
                state={{ from: `${location.pathname}${location.search}` }}
              >
                <Flex gap="4px" direction="column">
                  <Text>
                    {conversationCount} chat{conversationCount !== 1 && 's'}
                  </Text>
                  {feedbackCount > 0 && <Text>{feedbackCount} feedback</Text>}
                </Flex>
              </Link>
            </TouchableTooltip>
          </Box>
          <Flex width="140px">
            <AssistantAccessTag access={assistant.view_access} />
          </Flex>
        </Flex>
      ) : (
        <Box />
      )}

      {/* More button */}
      <Box justifySelf="end">
        <AssistantDiscoveryMoreButton
          assistant={assistant}
          isCurrentUserCreated={isCurrentUserCreated}
        />
      </Box>
    </LinkBox>
  )
}
