import { BiX } from 'react-icons/bi'
import {
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import { AssistantFeedbackType } from '~shared/types/assistant-feedback.dto'
import { FeedbackRatingType } from '~shared/types/feedbacks.dto'

import { useAssistantFeedback } from '~features/chatv2/hooks/useAssistantFeedback'

import {
  AssistantFeedbackFormBody,
  AssistantFeedbackFormControl,
} from './AssistantFeedbackForm'

type AssistantFeedbackModalProps = {
  onClose: () => void
  isOpen: boolean
  assistantId: string
  feedbackRating?: FeedbackRatingType
  feedbackType: AssistantFeedbackType
  messageId: string
}

export const AssistantFeedbackModal = ({
  onClose,
  isOpen,
  feedbackType,
  feedbackRating,
  messageId,
  assistantId,
}: AssistantFeedbackModalProps) => {
  const isAssistantLevelFeedback = feedbackType === 'assistant_level'

  const {
    feedbackOnClose,
    onSubmit,
    isCreateAssistantFeedbackLoading,
    numericRating,
    setNumericRating,
    isAllowCreatorToView,
    setIsAllowCreatorToView,
    feedbackContent,
    setFeedbackContent,
  } = useAssistantFeedback({
    assistantId,
    onClose,
    feedbackRating,
    messageId,
    feedbackType,
  })

  return (
    <Modal isOpen={isOpen} onClose={feedbackOnClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding="40px 32px 24px">
          <HStack justify="space-between" alignItems="center">
            <Text textStyle="h4">Review Assistant</Text>
            <IconButton
              variant="clear"
              icon={<Icon as={BiX} fontSize="24px" />}
              onClick={feedbackOnClose}
              aria-label="assistant-feedback-modal-close-button"
              colorScheme="neutral"
            />
          </HStack>
        </ModalHeader>
        <VStack spacing="12px" align="stretch">
          <ModalBody as={VStack} align="stretch">
            <AssistantFeedbackFormBody
              isAssistantLevelFeedback={isAssistantLevelFeedback}
              headerText="How would you rate this Assistant?"
              numericRating={numericRating}
              setNumericRating={setNumericRating}
              feedbackContent={feedbackContent}
              setFeedbackContent={setFeedbackContent}
              isAllowCreatorToView={isAllowCreatorToView}
              setIsAllowCreatorToView={setIsAllowCreatorToView}
              placeholder="Please elaborate on your response (optional)"
            />
          </ModalBody>
          <ModalFooter>
            <AssistantFeedbackFormControl
              feedbackOnClose={feedbackOnClose}
              onSubmit={onSubmit}
              isDisabled={isAssistantLevelFeedback && numericRating <= 0}
              isLoading={isCreateAssistantFeedbackLoading}
            />
          </ModalFooter>
        </VStack>
      </ModalContent>
    </Modal>
  )
}
