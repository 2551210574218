import { useNavigate } from 'react-router-dom'
import {
  Box,
  Center,
  Icon,
  Link,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Tab, Tabs } from '@opengovsg/design-system-react'

import { routes } from '~constants/routes'

import { AssistantBanner } from '../components/AssistantsDiscovery/AssistantBanner'
import { AssistantCard } from '../components/AssistantsDiscovery/AssistantCard'
import { FeaturedPublicAssistantCards } from '../components/AssistantsDiscovery/FeaturedPublicAssistantCards'
import { CreatedByMeHeroIcon } from '../components/AssistantsDiscovery/icons/CreatedByMeHeroIcon'
import { RecentlyUsedHeroIcon } from '../components/AssistantsDiscovery/icons/RecentlyUsedHeroIcon'
import { useAssistantPreviewContext } from '../hooks/useAssistantPreviewContext'
import { useListGroupedAssistants } from '../hooks/useListGroupedAssistants'

export const AssistantsDiscoveryPage = () => {
  const { data: assistantsData, isLoading } = useListGroupedAssistants()
  const navigate = useNavigate()

  const { setPreviewChatHistory, setCurrentChatId } =
    useAssistantPreviewContext()

  const onCreateAssistant = () => {
    // Reset state
    setPreviewChatHistory([])
    setCurrentChatId(undefined)
    navigate(`${routes.assistants}/create`)
    return
  }

  // TODO: Add error element
  if (isLoading || !assistantsData) {
    return (
      <Center height="100%" flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <VStack
      flex={1}
      alignItems="flex-start"
      backgroundColor="base.canvas.default"
      width="100%"
    >
      <AssistantBanner onCreateAssistant={onCreateAssistant} />
      <VStack
        width="100%"
        paddingTop="32px"
        paddingBottom="64px"
        paddingX={{ base: '32px', lg: '64px' }}
        gap="40px"
      >
        <FeaturedPublicAssistantCards
          featuredAssistants={assistantsData.trending_public}
        />
        <VStack alignItems="flex-start" width="100%">
          <Text textStyle="h6">Your Assistants</Text>
          {/* TODO: move these repetitive styling props over to theming */}
          <Tabs variant="line" width="full" size="sm">
            <TabList css={{ gap: '20px' }}>
              <Tab
                mx="0px"
                px="0px"
                fontWeight={600}
                fontSize="12px !important"
                letterSpacing="wide"
              >
                Recently used
              </Tab>
              <Tab
                mx="0px"
                px="0px"
                fontWeight={600}
                fontSize="12px !important"
                letterSpacing="wide"
              >
                Created by me
              </Tab>
              {assistantsData.public.length > 0 && (
                <Tab
                  mx="0px"
                  px="0px"
                  fontWeight={600}
                  fontSize="12px !important"
                  letterSpacing="wide"
                >
                  Public Assistants
                </Tab>
              )}
            </TabList>
            <TabPanels paddingTop="8px">
              {/* Recently used */}
              <TabPanel p="0px">
                {assistantsData.recent.length === 0 && (
                  <Box
                    justifyContent="center"
                    width="100%"
                    display="flex"
                    paddingY="30px"
                    paddingX="16px"
                  >
                    <VStack maxWidth="373px" textAlign="center" spacing="12px">
                      <Icon as={RecentlyUsedHeroIcon} fontSize="44px" />
                      <VStack width="100%" spacing="4px">
                        <Text textStyle="subhead-2" color="base.content.medium">
                          Get started with an Assistant
                        </Text>
                        <Text
                          textStyle="body-2"
                          color="interaction.support.unselected"
                        >
                          Assistants you use will appear here for quick access.
                          Start by exploring the public Assistants above.
                        </Text>
                      </VStack>
                    </VStack>
                  </Box>
                )}

                {assistantsData.recent.length > 0 &&
                  assistantsData.recent.map((assistant) => {
                    // use feedback_count to check for assistant truncation type
                    if ('feedback_count' in assistant)
                      return (
                        <AssistantCard
                          key={assistant.id}
                          assistant={assistant}
                          isCurrentUserCreated={true}
                          conversationCount={assistant.conversation_count}
                          feedbackCount={assistant.feedback_count}
                        />
                      )
                    return (
                      <AssistantCard key={assistant.id} assistant={assistant} />
                    )
                  })}
              </TabPanel>

              {/* Created by me */}
              <TabPanel>
                {assistantsData.self.length === 0 && (
                  <Box
                    justifyContent="center"
                    width="100%"
                    display="flex"
                    paddingY="30px"
                    paddingX="16px"
                  >
                    <VStack maxWidth="373px" textAlign="center" spacing="12px">
                      <Icon as={CreatedByMeHeroIcon} fontSize="44px" />
                      <VStack width="100%" spacing="4px">
                        <Text textStyle="subhead-2" color="base.content.medium">
                          Create an Assistant for your repetitive tasks
                        </Text>
                        <Text
                          textStyle="body-2"
                          color="interaction.support.unselected"
                        >
                          Build custom AI assistants to automate your workflows
                          and boost productivity for you and your team.
                        </Text>
                      </VStack>
                      <Link
                        onClick={onCreateAssistant}
                        colorScheme="neutral"
                        textStyle="body-2"
                      >
                        Create your first Assistant
                      </Link>
                    </VStack>
                  </Box>
                )}

                {assistantsData.self.length > 0 &&
                  /* no need for check here as only TruncatedAssistantWithConversationCountDto type for self */
                  assistantsData.self.map((assistant) => (
                    <AssistantCard
                      key={assistant.id}
                      assistant={assistant}
                      isCurrentUserCreated={true}
                      conversationCount={assistant.conversation_count}
                      feedbackCount={assistant.feedback_count}
                    />
                  ))}
              </TabPanel>
              {/* Public Assistants */}
              {assistantsData.public.length > 0 && (
                <TabPanel width="100%">
                  <Box
                    width="100%"
                    maxWidth="100%"
                    overflowX="auto"
                    overflowY="hidden"
                  >
                    {assistantsData.public.map((assistant) => {
                      // use feedback_count to check for assistant truncation type
                      if ('feedback_count' in assistant)
                        return (
                          <AssistantCard
                            key={assistant.id}
                            assistant={assistant}
                            isCurrentUserCreated={true}
                            conversationCount={assistant.conversation_count}
                            feedbackCount={assistant.feedback_count}
                          />
                        )
                      return (
                        <AssistantCard
                          key={assistant.id}
                          assistant={assistant}
                        />
                      )
                    })}
                  </Box>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </VStack>
      </VStack>
    </VStack>
  )
}
