import { BiSolidDislike, BiSolidStar } from 'react-icons/bi'
import { Center, HStack, Icon, Text } from '@chakra-ui/react'
import _ from 'lodash'

import { getTextAndStyleForFeedbackType } from './helper'

type AssistantDrawerHeaderProps = {
  isAssistantLevelFeedback: boolean
  isMessageLevelFeedback: boolean
  numericRating: number | null | undefined
}

export function AssistantDrawerHeader({
  isAssistantLevelFeedback,
  isMessageLevelFeedback,
  numericRating,
}: AssistantDrawerHeaderProps) {
  const { text } = getTextAndStyleForFeedbackType({
    isMessageLevelFeedback,
    isAssistantLevelFeedback,
    feedbackNumericValue: numericRating,
  })

  return (
    <HStack spacing="8px">
      {isAssistantLevelFeedback && numericRating ? (
        <HStack
          bg={
            numericRating <= 3
              ? 'interaction.critical-subtle.active'
              : 'interaction.success-subtle.active'
          }
          padding="4px"
          borderRadius="4px"
          width="fit-content"
        >
          {_.range(numericRating).map((index) => (
            <Icon as={BiSolidStar} fontSize="16px" key={index} color="white" />
          ))}
        </HStack>
      ) : (
        <Center borderRadius="4px" bg="#8394A8" height="24px" width="24px">
          <Icon as={BiSolidDislike} fontSize="16px" color="white" />
        </Center>
      )}
      <Text
        textTransform="uppercase"
        textStyle="subhead-3"
        color="base.content.strong"
      >
        {text}
      </Text>
    </HStack>
  )
}
