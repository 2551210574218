import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const Code = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="44"
    height="48"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.2759 9.3858C13.2335 8.57784 13.8542 7.88853 14.6622 7.84618L31.3617 6.97099L38.8478 13.5636L40.0329 36.1779C40.0753 36.9859 39.4546 37.6752 38.6467 37.7175L16.2891 38.8892C15.4811 38.9316 14.7918 38.3109 14.7494 37.503L13.2759 9.3858Z"
      fill="#FA6C42"
    />
    <rect
      width="8.58537"
      height="1.07317"
      rx="0.536585"
      transform="matrix(-0.99863 0.052336 0.052336 0.99863 36.4375 28.583)"
      fill="#FFBFAD"
    />
    <rect
      width="8.58537"
      height="1.07317"
      rx="0.536585"
      transform="matrix(-0.99863 0.052336 0.052336 0.99863 36.0977 22.1523)"
      fill="#FFBFAD"
    />
    <rect
      width="8.58537"
      height="1.07317"
      rx="0.536585"
      transform="matrix(-0.99863 0.052336 0.052336 0.99863 36.2656 25.3379)"
      fill="#FFBFAD"
    />
    <rect
      width="8.58537"
      height="1.07317"
      rx="0.536585"
      transform="matrix(-0.99863 0.052336 0.052336 0.99863 35.9258 18.9092)"
      fill="#FFBFAD"
    />
    <rect
      width="17.2207"
      height="0.94439"
      rx="0.472195"
      transform="matrix(0.99863 -0.052336 -0.052336 -0.99863 18.7852 17.5479)"
      fill="#FFBFAD"
    />
    <rect
      width="17.0027"
      height="0.94439"
      rx="0.472195"
      transform="matrix(-0.0397218 -0.999211 -0.999211 0.0397218 26.0742 33.9404)"
      fill="#FFBFAD"
    />
    <path
      d="M34.1139 13.4004L38.957 13.5133C36.763 11.4349 33.7552 8.73393 31.3945 6.8291L32.1681 11.7011C32.2201 12.6928 33.0917 13.454 34.1139 13.4004Z"
      fill="#181951"
    />
    <circle cx="13.6859" cy="31.5121" r="9.65854" fill="#181951" />
    <path
      d="M11.8128 37.7723H12.4388V36.5202H11.8196C11.6268 36.5165 9.9347 36.4119 9.9347 34.0162C9.9347 32.7685 9.5184 31.9841 8.99443 31.5121C9.5184 31.0401 9.9347 30.2557 9.9347 29.0081C9.9347 26.6123 11.6268 26.5077 11.8128 26.504H12.4388V25.252H11.8115C10.7291 25.2545 8.68267 26.0432 8.68267 29.0081C8.68267 30.7609 7.63222 30.8798 7.42188 30.8861L7.43064 32.1381C7.48197 32.1381 8.68267 32.1594 8.68267 34.0162C8.68267 36.981 10.7291 37.7698 11.8128 37.7723ZM19.951 30.8861C19.8996 30.8861 18.6989 30.8648 18.6989 29.0081C18.6989 26.0432 16.6525 25.2545 15.5689 25.252H14.9428V26.504H15.562C15.7548 26.5077 17.4469 26.6123 17.4469 29.0081C17.4469 30.2557 17.8632 31.0401 18.3872 31.5121C17.8632 31.9841 17.4469 32.7685 17.4469 34.0162C17.4469 36.4119 15.7548 36.5165 15.5689 36.5202H14.9428V37.7723H15.5701C16.6525 37.7698 18.6989 36.981 18.6989 34.0162C18.6989 32.2633 19.7494 32.1444 19.9597 32.1381L19.951 30.8861Z"
      fill="#FFBFAD"
    />
  </svg>
))
