import { isEmail, registerDecorator, ValidationOptions } from 'class-validator'

export const createEmailValidator = (domain: string) => {
  return (value: unknown) => {
    return (
      typeof value === 'string' &&
      isEmail(value) &&
      value.toLowerCase().endsWith(domain)
    )
  }
}

export const createBulkEmailValidator = (domains: string[]) => {
  return (value: unknown) => {
    return (
      typeof value === 'string' &&
      isEmail(value) &&
      domains.some((domain) => value.toLowerCase().endsWith(domain))
    )
  }
}

export const IsValidEmail = (
  emailValidator: (domain: string) => boolean,
  options?: ValidationOptions,
) => {
  return (object: object, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options,
      validator: {
        validate: emailValidator,
      },
    })
  }
}
