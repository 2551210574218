import { BiBoltCircle } from 'react-icons/bi'
import { Circle, Icon, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import { PAIR_WISE_URL } from '~constants/config'

export const DefaultHero = () => {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      spacing="8px"
      flexGrow={1}
    >
      <Circle background="base.canvas.backdrop" padding="8px">
        <Icon as={BiBoltCircle} boxSize="24px" color="base.content.medium" />
      </Circle>
      <Text color="base.content.strong" textStyle="h5">
        How can I help you today?
      </Text>
      <Link
        textStyle="caption-1"
        color="interaction.links.default"
        textDecoration="none"
        href={PAIR_WISE_URL}
        isExternal
      >
        Learn how to use Pair
      </Link>
    </VStack>
  )
}
