import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CreateOrUpdateAssistantRequestDto,
  UpdateAssistantResponseDto,
} from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const updateAssistant = async ({
  assistantId,
  data,
}: {
  assistantId: string
  data: CreateOrUpdateAssistantRequestDto
}) => {
  return ApiService.patch<UpdateAssistantResponseDto>(
    `/assistants/${assistantId}`,
    data,
  ).then((response) => response.data)
}

export function useUpdateAssistant() {
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation(updateAssistant, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queries.assistants.list.queryKey,
      }),
  })

  return {
    isLoading,
    mutate,
  }
}
