import { Link as RouterLink } from 'react-router-dom'
import { Image, Text, VStack } from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'

import NotFoundImage from '~/assets/search-not-found.svg'

import { routes } from '~constants/routes'

export const AssistantUnavailable = ({
  agencyDenied,
}: {
  agencyDenied?: boolean
}) => {
  return (
    <VStack
      flexGrow={1}
      width="100%"
      paddingX="0px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
    >
      <VStack
        alignItems="center"
        justifyContent="center"
        spacing="30px"
        flexGrow={1}
      >
        <Image
          htmlWidth="300px"
          src={NotFoundImage}
          alt="404 Not Found Image"
          loading="lazy"
          display={{ base: 'none', md: 'initial' }}
        />
        <VStack alignItems="center" justifyContent="center" spacing="10px">
          <Text color="base.content.strong" textStyle="h5">
            {agencyDenied
              ? 'No access to this assistant'
              : 'Assistant unavailable'}
          </Text>
          <Text textAlign="center">
            {agencyDenied ? (
              <>
                This assistant is not available for your agency/email domain.{' '}
                <br />
                You do not have permission to view it.
              </>
            ) : (
              'Access permissions has been changed by its creator.'
            )}
          </Text>
          <Link as={RouterLink} to={routes.assistants}>
            View other assistants instead
          </Link>
        </VStack>
      </VStack>
    </VStack>
  )
}
