import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CreateAssistantResponseDto,
  CreateOrUpdateAssistantRequestDto,
} from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const createAssistant = async (
  formData: CreateOrUpdateAssistantRequestDto,
) => {
  return ApiService.post<CreateAssistantResponseDto>(
    '/assistants',
    formData,
  ).then((response) => response.data)
}

export function useCreateAssistant() {
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation(createAssistant, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queries.assistants.list.queryKey,
      }),
  })

  return {
    isLoading,
    mutate,
  }
}
