import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'

import { INPUT_TOOLBAR_ICON_SIZE_IN_PX } from '../constants'

interface InputToolbarIconButtonProps extends IconButtonProps {
  tooltipLabel: string
  icon: React.ReactElement
  onClick: () => void
  isDisabled?: boolean
  isTooltipDisabled?: boolean
}

export const InputToolbarIconButton = ({
  tooltipLabel,
  icon,
  onClick,
  isDisabled = false,
  isTooltipDisabled = false,
  ...props
}: InputToolbarIconButtonProps) => (
  <Tooltip
    label={tooltipLabel}
    hasArrow
    closeDelay={200}
    placement="top"
    isDisabled={isTooltipDisabled}
  >
    <IconButton
      minHeight={`${INPUT_TOOLBAR_ICON_SIZE_IN_PX}px`}
      minWidth={`${INPUT_TOOLBAR_ICON_SIZE_IN_PX}px`}
      display="inline-flex"
      variant="unstyled"
      height="fit-content"
      _disabled={{
        color: 'interaction.support.disabled-content',
        cursor: 'not-allowed',
        _hover: {
          color: 'interaction.support.disabled-content',
        },
      }}
      border="none"
      background="transparent"
      color="grey.400"
      _hover={{
        color: 'interaction.support.selected',
      }}
      fontSize="20px"
      icon={icon}
      zIndex={1}
      onClick={onClick}
      isDisabled={isDisabled}
      {...props}
    />
  </Tooltip>
)
