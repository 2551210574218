import { useMemo } from 'react'
import { BiGlobe } from 'react-icons/bi'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { Icon, Text, VStack } from '@chakra-ui/react'
import _ from 'lodash'

import { useAuth } from '~lib/auth'
import { routes } from '~constants/routes'

import {
  AssistantAvatar,
  generateAssistantPathFromId,
  useRecentlyUsedAssistants,
} from '~features/assistants'
import { IListChatType } from '~features/chatv2/types'

import { SidebarConversationsEntry } from './SidebarConversations'
import { SidebarHeader } from './SidebarHeader'
import { SidebarItemContainer } from './SidebarItemContainer'
import { SidebarNavigationMenu } from './SidebarNavigationMenu'

type SidebarProps = {
  onClickCreate: () => void
  conversations?: IListChatType
  isDrawer?: boolean
  onClickCloseSidebar?: () => void
}

export const Sidebar = ({
  conversations,
  isDrawer = false,
  onClickCreate,
  onClickCloseSidebar,
}: SidebarProps) => {
  const { logout } = useAuth()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paramAssistantId = searchParams.get('assistant') || undefined

  const { data: recentAssistantsData } = useRecentlyUsedAssistants()

  const orderedConversations = useMemo(
    () =>
      _.orderBy(
        conversations,
        (conversation) => conversation.updatedAt,
        'desc',
      ),
    [conversations],
  )

  const { chatId } = useParams()
  const location = useLocation()

  return (
    <VStack
      padding="8px"
      alignItems="stretch"
      color="white"
      height="full"
      justifyContent="space-between"
    >
      <VStack alignItems="stretch" spacing="24px" minH={0}>
        <SidebarHeader
          isDrawer={isDrawer}
          onClickCreate={onClickCreate}
          onClickCloseSidebar={onClickCloseSidebar}
        />
        <VStack
          alignItems="stretch"
          spacing="18px"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#BABECB',
              borderRadius: '2px',
            },
          }}
          paddingRight="2px"
        >
          {/* Section for assistants */}
          <VStack alignItems="stretch" spacing="0px">
            <Text
              fontSize="10px"
              fontWeight="600"
              lineHeight="16px"
              paddingLeft="8px"
              paddingBottom="4px"
              letterSpacing={0.8}
              color="interaction.support.unselected"
            >
              ASSISTANTS
            </Text>
            <SidebarItemContainer
              justifyContent="flex-start"
              padding="8px"
              spacing="8px"
              onClick={() => navigate(routes.assistants)}
              isSelected={location.pathname === routes.assistants}
            >
              <Icon
                as={BiGlobe}
                aria-label="Explore Assistants"
                boxSize="20px"
              />
              <Text textStyle="body-2">Explore Assistants</Text>
            </SidebarItemContainer>
            {recentAssistantsData?.map((assistant) => {
              return (
                <SidebarItemContainer
                  key={assistant.id}
                  justifyContent="flex-start"
                  padding="8px"
                  spacing="8px"
                  isSelected={paramAssistantId === assistant.id}
                  onClick={() =>
                    navigate(generateAssistantPathFromId(assistant.id))
                  }
                >
                  <AssistantAvatar
                    avatarSize="2xs"
                    bgColor="interaction.tinted.inverse.active"
                  />
                  <Text textStyle="body-2">{assistant.name}</Text>
                </SidebarItemContainer>
              )
            })}
          </VStack>

          <VStack alignItems="stretch" spacing="0px">
            <Text
              fontSize="10px"
              fontWeight="600"
              lineHeight="16px"
              paddingLeft="8px"
              paddingBottom="4px"
              letterSpacing={0.8}
              color="interaction.support.unselected"
            >
              RECENT CHATS
            </Text>
            {/* Section for conversations */}
            {orderedConversations?.map((conversation) => (
              <SidebarConversationsEntry
                key={conversation.id}
                id={conversation.id}
                title={conversation.title}
                onClick={() => navigate(`${routes.chat}/${conversation.id}`)}
                createdAt={conversation.createdAt}
                isSelected={chatId === conversation.id}
                assistantName={conversation.assistant?.name}
                isAssistantDeleted={
                  !!conversation.assistantId && !conversation.assistant
                }
              />
            ))}
          </VStack>
        </VStack>
      </VStack>

      <SidebarNavigationMenu onLogout={logout} />
    </VStack>
  )
}
