import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

type UncheckSensitivityModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const UncheckSensitivityModal = ({
  isOpen,
  onClose,
  onConfirm,
}: UncheckSensitivityModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton size="sm" />
      <ModalHeader>Caution: Delete uploaded files?</ModalHeader>
      <ModalBody>
        <VStack width="100%" alignItems="start" spacing="2px">
          <Text>Are you sure you want to uncheck the acknowledgement?</Text>
          <Text>
            Unchecking this box will permanently delete all uploaded files
          </Text>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button
            variant="clear"
            color="base.content.default"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            backgroundColor="interaction.critical.default"
            mr={3}
            onClick={onConfirm}
          >
            Uncheck and delete files
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
