import { useNavigate } from 'react-router-dom'
import { Box, Flex, Icon, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Tile } from '@opengovsg/design-system-react'

import { TrendingPublicAssistantType } from '~shared/types/assistants.dto'

import { generateAssistantPathFromId } from '~features/assistants/utils'

import { Code as CodeIllustration } from './icons/Code'
import { Draft as DraftIllustration } from './icons/Draft'
import { DraftCleanup as DraftCleanupIllustration } from './icons/DraftCleanup'
import { Informational as InformationalIllustration } from './icons/Informational'

const iconMap = {
  code: CodeIllustration,
  draft: DraftIllustration,
  'draft-cleanup': DraftCleanupIllustration,
  informational: InformationalIllustration,
} as const

export const FeaturedPublicAssistantCards = ({
  featuredAssistants,
}: {
  featuredAssistants: TrendingPublicAssistantType[]
}) => {
  const navigate = useNavigate()

  if (featuredAssistants.length === 0) {
    return null
  }

  return (
    <VStack alignItems="flex-start" gap="16px">
      <Text textStyle="h6">Trending public Assistants</Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing="16px"
        width="full"
        mt="0px"
      >
        {featuredAssistants.map((assistantWithIconType) => {
          return (
            <Tile
              key={assistantWithIconType.id}
              onClick={() =>
                navigate(generateAssistantPathFromId(assistantWithIconType.id))
              }
              css={{
                padding: '16px',
                borderRadius: '8px',
                // TODO: Figure out a better way to style Tiles. This colour should be base.divider.default.
                borderColor: '#E0E0E0',
              }}
            >
              <Flex gap="8px">
                <Icon
                  as={iconMap[assistantWithIconType.icon_type]}
                  fontSize="44px"
                />
                <Box>
                  <Text textStyle="subhead-2">
                    {assistantWithIconType.name}
                  </Text>
                  <Text
                    textStyle="caption-2"
                    noOfLines={2}
                    color="base.content.medium"
                  >
                    {assistantWithIconType.description}
                  </Text>
                </Box>
              </Flex>
            </Tile>
          )
        })}
      </SimpleGrid>
    </VStack>
  )
}
