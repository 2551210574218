import {
  Alert,
  AlertIcon,
  CloseButton,
  HStack,
  Spacer,
  Text,
  useToast as useChakraToast,
} from '@chakra-ui/react'

type CustomToastType = 'info' | 'success' | 'warning' | 'error'

interface CustomToastComponentProps {
  type: CustomToastType
  content: string
  onClose: () => void
}

// TODO: Switch to chakra theming
const getColorForType = (type: CustomToastType) => {
  switch (type) {
    case 'info':
      return 'utility.feedback.info'
    case 'success':
      return 'utility.feedback.success'

    case 'warning':
      return 'utility.feedback.warning'

    case 'error':
      return 'utility.feedback.critical'

    default:
      return 'utility.feedback.info'
  }
}

const CustomToastComponent = ({
  type,
  content,
  onClose,
}: CustomToastComponentProps) => {
  const alertColor = getColorForType(type)

  return (
    <Alert
      as={HStack}
      alignItems="center"
      spacing="8px"
      status={type}
      backgroundColor={`${alertColor}-subtle`}
      borderColor={alertColor}
      borderWidth="1px"
      borderRadius="4px"
      paddingY="4px"
      paddingRight="8px"
      paddingLeft="16px"
    >
      <AlertIcon boxSize="18px" color={alertColor} marginRight="8px" />
      <Text color="base.content.default" textStyle="body-1">
        {content}
      </Text>
      <Spacer />
      <CloseButton
        onClick={onClose}
        alignSelf="flex-end"
        size="md"
        fontSize="10px"
      />
    </Alert>
  )
}

type BaseToastProps = {
  content: string
  duration?: number | null
}

type ToastPropsWithType = BaseToastProps & {
  type: CustomToastType
}

export const useCustomToast = () => {
  const toast = useChakraToast()

  const createToast = ({
    content,
    type,
    duration = 3_000,
  }: ToastPropsWithType) => {
    return toast({
      position: 'top',
      duration,
      isClosable: true,

      // onClose does not rely on this.
      // eslint-disable-next-line @typescript-eslint/unbound-method
      render: ({ onClose }) => {
        return (
          <CustomToastComponent
            type={type}
            content={content}
            onClose={onClose}
          />
        )
      },
    })
  }

  const createSuccessToast = (props: BaseToastProps) =>
    createToast({ ...props, type: 'success' })

  const createErrorToast = (props: BaseToastProps) =>
    createToast({ ...props, type: 'error' })

  return { createToast, createSuccessToast, createErrorToast }
}
