import { ReactNode, useRef } from 'react'
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  Text,
  useDisclosure,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'

import { TruncatedAssistantDto } from '~shared/types/assistants.dto'

import { AssistantAccessTag } from './AssistantAccessTag'
import { AssistantsShareLinkContent } from './AssistantShareLinkContent'

type AssistantsCopyPopoverProps = PopoverProps & {
  children: ReactNode
  assistant: TruncatedAssistantDto
}

export const AssistantShareLinkPopover = ({
  children,
  assistant,
  ...popoverProps
}: AssistantsCopyPopoverProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const popoverRef = useRef(null)
  // Note (20241104):
  // This is needed due to this issue:
  // https://github.com/chakra-ui/chakra-ui/issues/7359
  // If/when we upgrade to chakra 3, it should be resolved.
  useOutsideClick({
    ref: popoverRef,
    handler: onClose,
  })

  return (
    <Box ref={popoverRef}>
      <Popover isLazy {...popoverProps} isOpen={isOpen} onOpen={onOpen}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent p="8px">
          <PopoverHeader border="0" textStyle="h6">
            <VStack alignItems="start">
              <Text>Share Assistant link</Text>
              <AssistantAccessTag access={assistant.view_access} />
            </VStack>
          </PopoverHeader>
          <PopoverBody>
            <AssistantsShareLinkContent assistant={assistant} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
