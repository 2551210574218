import { ReactNode, useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import {
  FlagProvider as UnleashFlagProvider,
  UnleashClient,
  useFlag,
  useFlagsStatus,
  useUnleashClient,
} from '@unleash/proxy-client-react'

import { APP_ENV } from '~constants/config'
import { getBrowserInfo } from '~utils/user-agent'

import { useAuth } from './auth'

const UNLEASH_CLIENT_URL =
  import.meta.env.VITE_UNLEASH_CLIENT_URL ||
  'https://flags.pair.gov.sg/api/frontend'
const UNLEASH_CLIENT_KEY = import.meta.env.VITE_UNLEASH_CLIENT_TOKEN || 'no-key'
const browserInfo = getBrowserInfo()

// NOTE (28/11/2023 - 17:44:13): Datadog does not allow `.` as as character within the flag name
export type IFeatureFlags =
  | 'documents_enable'
  | 'documents_chat_enable'
  | 'documents_assistant_chat_enable'
  | 'enable_creator_assistants_feedback'
  | 'promo_assistants_banner'

// Custom hook for tracking evaluations + type safety
export const useTrackedFlag = (key: IFeatureFlags) => {
  const value = useFlag(key)
  const { flagsReady, flagsError } = useFlagsStatus()
  const client = useUnleashClient()

  const isCached = !!client.getAllToggles().find((flag) => flag.name === key)

  // Adds evaluation for tracking on datadog
  if (flagsReady) {
    datadogRum.addFeatureFlagEvaluation(key, value)
  }

  return {
    isEnabled: value,
    isReady: flagsReady || isCached,
    isError: !!flagsError,
  }
}

const unleashClient = new UnleashClient({
  url: UNLEASH_CLIENT_URL, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: UNLEASH_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 45, // How often (in seconds) the client should poll the proxy for updates
  appName: 'pair-chat', // The name of your application. It's only used for identifying your application,
  // Note: working with current limit of 2 environments. Defaults all to staging
  environment: APP_ENV === 'production' ? 'production' : 'development',
  context: {
    properties: browserInfo,
  },
})
export const FlagProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const auth = useAuth()

  // Only kickstart the client once the auth check has been done
  useEffect(() => {
    if (!auth.isLoading) {
      void unleashClient.start()
    }

    return () => unleashClient.stop()
  }, [auth.isLoading])

  // Sets the user ID of the user only after auth check
  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.id) {
      unleashClient.setContextField('userId', auth.user?.id)
      unleashClient.setContextField('email', auth.user?.email)
    }
  }, [auth.user, auth.isAuthenticated])

  return (
    <UnleashFlagProvider unleashClient={unleashClient} startClient={false}>
      {children}
    </UnleashFlagProvider>
  )
}
