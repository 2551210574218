import moment from 'moment-timezone'

import { ITimeStampGroup } from '~types/date'
moment.tz.setDefault('Asia/Singapore')

export const DAY_MONTH_YEAR_TIME_AM_PM = 'DD MMM YY, h:mma'

export function generateTimestampGroup(
  timestamp: string,
  // Creates a default moment based on current timestamp
  currentMoment: moment.Moment = moment(),
): ITimeStampGroup {
  if (moment(timestamp).isSame(currentMoment, 'day')) {
    return 'Today'
  }

  if (moment(timestamp).isSame(currentMoment, 'week')) {
    return 'Previous 7 days'
  }

  if (moment(timestamp).isSame(currentMoment, 'month')) {
    return 'Previous 30 days'
  }

  if (moment(timestamp).isSame(currentMoment, 'year')) {
    return moment(timestamp).format('MMMM') as ITimeStampGroup
  }

  return 'Older'
}

export function formatTime({
  timestamp,
  dateFormat,
}: {
  timestamp?: moment.MomentInput
  dateFormat: string
}) {
  if (!timestamp) {
    return '-'
  }
  const momentified = moment(timestamp)
  return momentified.isValid() ? momentified.format(dateFormat) : '-'
}
