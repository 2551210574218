import { BiBoltCircle } from 'react-icons/bi'
import { HStack, Icon, Text } from '@chakra-ui/react'

export const VanillaChatHeader = () => {
  return (
    <HStack
      alignItems="center"
      paddingX="24px"
      minH="48px"
      width="100%"
      borderColor="base.divider.medium"
      borderBottomWidth="1px"
      justifyContent="space-between"
    >
      <HStack spacing="8px" color="base.content.default">
        <Icon as={BiBoltCircle} boxSize="24px" />
        <Text textStyle="subhead-1">Chat</Text>
      </HStack>
    </HStack>
  )
}
