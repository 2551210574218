import {
  forwardRef,
  IconButton,
  IconButtonProps,
  PlacementWithLogical,
  Tooltip,
} from '@chakra-ui/react'

// Component should be used in cases where ref needs to be shared between parent and a tooltip-encapsulated iconbutton
// eg; to allow parent "PopoverTrigger" to pass ref to underlying iconbutton

interface IconButtonWithTooltipProps extends IconButtonProps {
  tooltipText?: string
  tooltipCloseDelay?: number
  tooltipPlacement?: PlacementWithLogical
}

export const IconButtonWithTooltip = forwardRef<
  IconButtonWithTooltipProps,
  'button'
>((props, ref) => {
  const { tooltipText, tooltipCloseDelay, tooltipPlacement, ...buttonProps } =
    props
  return (
    <Tooltip
      label={props.tooltipText || props['aria-label']}
      closeDelay={props.tooltipCloseDelay ?? 200}
      hasArrow
      placement={props.tooltipPlacement ?? 'top'}
    >
      <IconButton ref={ref} {...buttonProps} />
    </Tooltip>
  )
})
