import { BiArrowBack } from 'react-icons/bi'
import { Link as RouterLink } from 'react-router-dom'
import { Image, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import NotFoundImage from '~/assets/search-not-found.svg'

import { routes } from '~constants/routes'

export const NotFound = () => {
  return (
    <VStack
      flexGrow={1}
      width="100%"
      paddingX="0px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
    >
      <VStack
        alignItems="center"
        justifyContent="center"
        spacing="30px"
        flexGrow={1}
      >
        <Image
          htmlWidth="300px"
          src={NotFoundImage}
          alt="404 Not Found Image"
          loading="lazy"
          display={{ base: 'none', md: 'initial' }}
        />
        <VStack alignItems="center" justifyContent="center" spacing="20px">
          <Text color="base.content.strong" textStyle="h5" paddingEnd={0}>
            Sorry, we could not find the page you’re looking for
          </Text>
          <Button
            as={RouterLink}
            to={routes.chat}
            leftIcon={<BiArrowBack />}
            variant="outline"
          >
            Back to home
          </Button>
        </VStack>
      </VStack>
    </VStack>
  )
}
