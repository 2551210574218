import { Box, HStack, Text } from '@chakra-ui/react'
import { SingleSelect } from '@opengovsg/design-system-react'

import {
  FeedbackTypesFilterEnumType,
  GetAssistantStatisticsResponseDto,
} from '~shared/types/assistant-feedback.dto'

import { AssistantFeedbacksSearchParams } from '~features/assistants/hooks'

import { FEEDBACK_TYPES_FILTER, FeedbackStatisticsFields } from './constants'
import { isFeedbackTypeFilter } from './helper'

type TitleAndStatisticsProps = {
  title: string
  statistics: string | number // leaving it general
  fallback?: '-'
}

const TitleAndStatistics = ({
  title,
  statistics,
  fallback,
}: TitleAndStatisticsProps) => {
  return (
    <Box>
      <Text textStyle="caption-1" color="base.content.medium">
        {title}
      </Text>
      <Text textStyle="h5" color="base.content.brand">
        {statistics === 0 && fallback ? fallback : statistics}
      </Text>
    </Box>
  )
}

type AssistantFeedbacksStatisticsProps = {
  feedbackStatistics: GetAssistantStatisticsResponseDto
  feedbackTypeFilter: string
  setFeedbackTypeFilter: React.Dispatch<
    React.SetStateAction<FeedbackTypesFilterEnumType>
  >
  handlePaginationClick: (params: AssistantFeedbacksSearchParams) => void
  totalCount: number
}

export const AssistantFeedbacksStatistics = ({
  feedbackStatistics,
  feedbackTypeFilter,
  setFeedbackTypeFilter,
  handlePaginationClick,
  totalCount,
}: AssistantFeedbacksStatisticsProps) => {
  const onChange = (value: string) => {
    if (isFeedbackTypeFilter(value)) {
      setFeedbackTypeFilter(value)
      // Clicking on a new filter should result page to always go back to first page
      handlePaginationClick({ feedbackTypeFilter: value, newPage: 1 })
    }
  }

  return (
    <HStack justifyContent="space-between" marginBottom="32px">
      <HStack spacing="48px">
        {FeedbackStatisticsFields.map(({ title, field, fallback }) => {
          return (
            <TitleAndStatistics
              title={title}
              statistics={feedbackStatistics[field]}
              key={title}
              fallback={fallback}
            />
          )
        })}
      </HStack>

      {totalCount > 0 && (
        <Box width="322px">
          <SingleSelect
            isClearable={false}
            placeholder="Show all feedback types"
            isSearchable={false}
            onChange={onChange}
            name="feedback types filter"
            value={feedbackTypeFilter}
            items={FEEDBACK_TYPES_FILTER}
          />
        </Box>
      )}
    </HStack>
  )
}
