import { Navigate, NavigateProps, useLocation } from 'react-router-dom'
import { Center, Spinner } from '@chakra-ui/react'

import { useAuth } from '~lib/auth'
import { routes } from '~constants/routes'

interface PrivateElementProps {
  /**
   * Route to redirect to when user is not authenticated OR
   * role does not match user's role.
   */
  redirectTo?: NavigateProps['to']
  element: React.ReactElement
}

export const PrivateElement = ({
  element,
  redirectTo,
}: PrivateElementProps): React.ReactElement => {
  const location = useLocation()

  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Center height="100%" flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  if (isAuthenticated) {
    return element
  }

  // defaults to /login route when not logged in
  return (
    <Navigate
      replace
      to={redirectTo ?? routes.login}
      state={{ from: location }}
    />
  )
}
