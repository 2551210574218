import { Components } from 'react-markdown'
import {
  Code,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react'

import { HighlightedCodeBlock } from '~components/HighlightedCodeBlock'
import { PopoutLink } from '~components/PopoutLink'

import { textStyles } from '../theme/foundations/textStyles'

//  This file stores the styling overrides for react markdown.
//  TODO: Swap over to chakra prose

function extractLanguageFromClassName(className: string | undefined) {
  if (!className) return null

  // Matcher works by parsing className, which react-markdown returns in the the following format eg; `language-python`
  // Taken from: https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
  const matcher = /language-(\w+)/.exec(className || '')

  if (!matcher) {
    return null
  }

  // Checks if both language classname match exists, as well as the corresponding language
  if (matcher[0] && matcher[1]) {
    // Second element of array refers to the actual language
    return matcher[1]
  }

  return null
}

export const markdownTheme: Components = {
  p: ({ children }) => {
    return (
      <Text whiteSpace="pre-wrap" textStyle="body-2" lineHeight="1.4rem">
        {children}
      </Text>
    )
  },
  table: ({ children }) => {
    return (
      <Table borderRadius={'8px'} overflow="hidden">
        {children}
      </Table>
    )
  },
  thead: ({ children }) => {
    return <Thead>{children}</Thead>
  },

  tr: ({ children }) => {
    return <Tr>{children}</Tr>
  },
  td: ({ children }) => {
    return (
      <Td paddingY="14px" textAlign="left">
        {children}
      </Td>
    )
  },

  th: ({ children }) => {
    return (
      <Th
        {...textStyles['caption-1']}
        backgroundColor="brand.secondary.100"
        color="brand.secondary.500"
        textTransform="capitalize"
      >
        {children}
      </Th>
    )
  },
  tbody: ({ children }) => {
    return (
      <Tbody backgroundColor="white" {...textStyles['caption-2']}>
        {children}
      </Tbody>
    )
  },
  // TODO: Figure out a cleaner implementation. Textstyle prop not supported for headers
  h1: ({ children }) => {
    return <Text textStyle="h3">{children}</Text>
  },

  h2: ({ children }) => {
    return <Text textStyle="h4">{children}</Text>
  },

  h3: ({ children }) => {
    return <Text textStyle="h5">{children}</Text>
  },
  h4: ({ children }) => {
    return <Text textStyle="h6">{children}</Text>
  },
  h5: ({ children }) => {
    return <Text textStyle="subhead-1">{children}</Text>
  },
  h6: ({ children }) => {
    return <Text textStyle="body-1">{children}</Text>
  },

  ol: ({ children, depth, start }) => {
    return (
      <OrderedList
        paddingLeft={depth > 0 ? 4 : 8}
        // Note: If this is not specified, it will default to using newline to infer the OL's index.
        start={start}
      >
        {children}
      </OrderedList>
    )
  },
  ul: ({ children, depth }) => {
    return (
      <UnorderedList paddingLeft={depth > 0 ? 4 : 8}>{children}</UnorderedList>
    )
  },

  li: ({ children }) => {
    return (
      <ListItem textStyle="body-2" paddingY="4px">
        {children}
      </ListItem>
    )
  },
  code: ({ children, className, inline }) => {
    if (inline) {
      return <Code>{children}</Code>
    }

    const language = extractLanguageFromClassName(className) || 'text'
    return (
      <HighlightedCodeBlock language={language} content={children.toString()} />
    )
  },

  blockquote: ({ children }) => {
    return (
      <Text
        fontStyle="italic"
        textStyle="subhead-1"
        borderStartWidth="2px"
        borderStartColor="base.divider.strong"
        color="base.content.default"
        paddingLeft="8px"
        marginY="12px"
      >
        {children}
      </Text>
    )
  },
  a: (props) => {
    return <PopoutLink {...props} />
  },
}

// Mostly the same as default markdown theme, with a few customisation. Currently, will use key to override what is in the default markdown theme and keep mostly the same till @Rachel found out more styling to fix
export const assistantFeedbackMarkdownTheme: Components = {
  ...markdownTheme,
  p: ({ children }) => {
    return (
      <Text
        whiteSpace="pre-wrap"
        textStyle="body-1"
        fontSize="0.875rem"
        letterSpacing="-0.011em"
      >
        {children}
      </Text>
    )
  },
}
