import { RefObject, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Box } from '@chakra-ui/react'

import { useAtBottom } from '~hooks/useAtBottom'

interface ChatScrollAnchorProps {
  trackVisibility?: boolean
  scrollRef?: RefObject<HTMLElement> | null
}

export function ChatScrollAnchor({
  trackVisibility,
  scrollRef = null,
}: ChatScrollAnchorProps) {
  const isAtBottom = useAtBottom(0, scrollRef)
  const { ref, entry, inView } = useInView({
    trackVisibility,
    delay: 100,
  })

  useEffect(() => {
    if (isAtBottom && trackVisibility && !inView) {
      entry?.target.scrollIntoView({
        block: 'start',
      })
    }
  }, [inView, entry, isAtBottom, trackVisibility])

  return <Box height="1px" ref={ref} w="full" />
}
