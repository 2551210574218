import axios, { isAxiosError } from 'axios'

import { BASE_API_URL } from '~constants/config'

export const ApiService = axios.create({
  baseURL: BASE_API_URL,
  timeout: 100_000, // 100 secs
  withCredentials: true,
})

export function isAuthenticationError(err: unknown) {
  return isAxiosError(err) && err.response?.status === 401
}

export function isClientError(err: unknown) {
  return (
    isAxiosError(err) &&
    err.response?.status &&
    err.response?.status >= 400 &&
    err.response?.status < 500
  )
}
