import { ReactNode } from 'react'
import { BiSolidDislike } from 'react-icons/bi'
import {
  Avatar,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'

import { assistantFeedbackMarkdownTheme } from '~lib/markdown'
import { PairAvatarLogo } from '~components/Icons/brand/PairAvatarLogo'

import { MarkdownChatContent } from '~features/chatv2/components/Chat'
import { DocumentTag } from '~features/chatv2/components/DocumentTag'

type AssistantFeedbackMessageReportProps = {
  isAi: boolean
  defaultAiAvatar?: ReactNode
  email: string | undefined
  content: string
  feedback: string | null | undefined
  isMessageLevelFeedback: boolean
  isOffendingMessage: boolean
  documentNames?: string[]
}

// Reference Chat Message. (TODO: see what does the other conditional statement represents)
export const AssistantFeedbackMessageReport = ({
  isAi,
  defaultAiAvatar,
  email,
  content,
  feedback,
  isMessageLevelFeedback,
  isOffendingMessage,
  documentNames,
}: AssistantFeedbackMessageReportProps) => {
  const isMessageLevelFeedbackAndIsOffendingMessage =
    isMessageLevelFeedback && isOffendingMessage
  const backgroundColor = isAi
    ? isMessageLevelFeedbackAndIsOffendingMessage
      ? 'utility.feedback.critical-subtle'
      : 'base.canvas.alt'
    : 'white'

  return (
    <HStack
      padding="12px 16px"
      align="stretch"
      w="100%"
      background={backgroundColor}
      spacing="16px"
    >
      {isAi ? (
        defaultAiAvatar || (
          <Avatar
            backgroundColor="transparent"
            icon={<PairAvatarLogo width="20px" />}
            size="2xs"
          />
        )
      ) : (
        <Avatar
          name={email || 'User'}
          backgroundColor={'interaction.main.default'}
          size="2xs"
        />
      )}

      {/* 16px accounts for the padding. 20px accounts for the avatar size */}
      <VStack spacing="16px" align="start" width="calc(100% - 16px - 20px)">
        <VStack spacing="12px" width="100%" align="start">
          {isAi ? (
            <MarkdownChatContent
              content={content}
              customMarkdownTheme={assistantFeedbackMarkdownTheme}
            />
          ) : (
            <Text
              whiteSpace="pre-wrap"
              fontSize="0.875rem"
              lineHeight="1.5rem"
              letterSpacing="-0.011em"
            >
              {content}
            </Text>
          )}

          {documentNames && documentNames.length > 0 && (
            <Wrap width="100%">
              {documentNames.map((documentName, index) => (
                <WrapItem key={index}>
                  <DocumentTag documentName={documentName} />
                </WrapItem>
              ))}
            </Wrap>
          )}
        </VStack>
        {isMessageLevelFeedbackAndIsOffendingMessage && feedback && (
          <HStack
            spacing="8px"
            background="interaction.critical-subtle.default"
            padding="12px 16px"
            borderRadius="4px"
            width="full"
          >
            <Icon
              as={BiSolidDislike}
              fontSize="20px"
              color="utility.feedback.critical"
            />
            <Text
              textStyle="body-2"
              lineHeight="1.5rem"
              letterSpacing="-0.011em"
              color="utility.feedback.critical"
            >
              {feedback}
            </Text>
          </HStack>
        )}
      </VStack>
    </HStack>
  )
}
