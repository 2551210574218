import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const Informational = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.94713 9.83626C8.88061 8.68091 9.7592 7.68852 10.9141 7.61454L26.5712 6.61161L33.9602 13.1399L35.1958 34.5988C35.2626 35.7595 34.3759 36.7545 33.2152 36.8214L12.6775 38.0039C11.5169 38.0708 10.5218 37.1841 10.455 36.0234L8.94713 9.83626Z"
      fill="#FF7046"
    />
    <path
      d="M29.285 12.7978L34.026 12.8838C31.8679 10.8606 29.039 8.24999 26.7188 6.39746L27.3719 11.1443C27.4277 12.1147 28.2847 12.8553 29.285 12.7978Z"
      fill="#480A29"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.166 31.6375L35.2674 18.6954C34.2626 18.1952 33.1325 17.9172 31.9414 17.9249C27.866 17.9514 24.6199 21.3128 24.6911 25.4329C24.7622 29.553 28.1237 32.8716 32.1991 32.8452C33.668 32.8356 35.0291 32.3929 36.166 31.6375Z"
      fill="#FFC0AE"
    />
    <rect
      width="7.44592"
      height="1.10319"
      rx="0.551595"
      transform="matrix(-0.99864 0.0563648 0.0498763 0.998319 32.2188 26.3076)"
      fill="#FF7046"
    />
    <rect
      width="7.44592"
      height="1.10319"
      rx="0.551595"
      transform="matrix(-0.99864 0.0563648 0.0498763 0.998319 32.0664 23.0645)"
      fill="#FF7046"
    />
    <path
      d="M36.4251 30.3024C36.4251 30.3024 40.4586 35.1356 42.4221 37.3805C43.4769 38.5865 43.3395 38.354 43.5047 38.5981C43.752 38.9636 44.4049 39.9738 43.621 40.7244C42.8372 41.475 41.9791 40.3997 41.5305 39.8114C41.0342 39.1605 41.5804 39.8774 40.4313 38.2694C39.9256 37.5617 39.0911 36.4389 38.6253 35.7795C37.6289 34.3691 35.375 31.1066 35.375 31.1066L35.7057 30.7827L36.4251 30.3024Z"
      fill="#480A29"
    />
    <path
      d="M33.099 17.9225C36.8509 18.3662 40.233 22.615 39.5626 26.8138C38.8921 31.0125 34.9125 33.8373 30.6716 33.1246C26.4307 32.4119 23.5379 28.4301 24.208 24.2334C24.4504 22.7156 25.1257 21.377 26.0896 20.3202C27.3832 18.6587 30.3337 17.5956 33.099 17.9225ZM30.8875 31.7724C34.3717 32.3579 37.6456 30.0359 38.1968 26.5842C38.7479 23.1326 36.3694 19.8607 32.8831 19.2748C29.3968 18.6889 26.125 21.0113 25.5739 24.4629C25.0227 27.9146 27.4013 31.1865 30.8875 31.7724Z"
      fill="#480A29"
    />
    <path
      d="M11.2728 12.5439C6.86195 12.5439 3.27344 15.2714 3.27344 18.6235C3.27344 20.8334 4.79172 22.8145 7.27312 23.8929V27.7427L11.5448 24.6992C15.83 24.5905 19.2722 21.9064 19.2722 18.6235C19.2722 15.2714 15.6837 12.5439 11.2728 12.5439Z"
      fill="#480A29"
    />
    <path
      d="M11.6308 17.9475L11.6308 17.9475C11.4953 18.0683 11.3399 18.2068 11.1933 18.3757L11.1933 18.3757C10.7274 18.9135 10.762 19.4112 10.7682 19.4664L10.7952 19.8414C10.8122 20.0767 11.0166 20.2536 11.2519 20.2367C11.4872 20.2198 11.6642 20.0153 11.6472 19.78L11.6199 19.4005C11.6199 19.4006 11.6199 19.4005 11.6199 19.4004C11.6198 19.3936 11.6152 19.1929 11.839 18.9348L11.839 18.9348C11.9471 18.8099 12.0717 18.6987 12.1948 18.5888L12.1988 18.5853L12.1988 18.5853L12.2016 18.5828C12.3884 18.4163 12.5935 18.2335 12.7439 17.992C12.8961 17.7474 12.9914 17.4444 12.9622 17.0388C12.8855 15.9743 11.9572 15.1707 10.8927 15.2473C9.95937 15.3145 9.22676 16.0363 9.11183 16.9305C9.08035 17.1754 9.29894 17.3527 9.53075 17.336C9.76841 17.3189 9.93016 17.1101 9.98705 16.8983C10.1032 16.4658 10.4831 16.1332 10.954 16.0993C11.5486 16.0565 12.0674 16.5056 12.1102 17.1002C12.124 17.2928 12.0927 17.4226 12.0173 17.5431C11.9398 17.6671 11.8145 17.784 11.6308 17.9475ZM11.2844 20.8261C11.0491 20.843 10.8721 21.0475 10.889 21.2828C10.906 21.518 11.1104 21.695 11.3457 21.6781C11.581 21.6611 11.758 21.4567 11.741 21.2214C11.7241 20.9861 11.5196 20.8092 11.2844 20.8261Z"
      fill="#FFC0AE"
      stroke="#FFC0AE"
      strokeWidth="0.1"
    />
  </svg>
))
