import {
  BiConversation,
  BiDotsHorizontalRounded,
  BiLink,
  BiPencil,
} from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Icon,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Menu, ModalCloseButton } from '@opengovsg/design-system-react'

import { TruncatedAssistantDto } from '~shared/types/assistants.dto'

import { useTrackedFlag } from '~lib/feature-flag'
import { routes } from '~constants/routes'

import { AssistantAccessTag } from '~features/assistants'

import { AssistantsShareLinkContent } from '../AssistantShareLinkContent'

type AssistantDiscoveryMoreButtonProps = {
  assistant: TruncatedAssistantDto
  isCurrentUserCreated: boolean
}

import { DeleteAssistantButton } from '../DeleteAssistantButton'

export const AssistantDiscoveryMoreButton = ({
  assistant,
  isCurrentUserCreated,
}: AssistantDiscoveryMoreButtonProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isEnabled: isAssistantsFeedbackViewable } = useTrackedFlag(
    'enable_creator_assistants_feedback',
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="more"
          icon={<BiDotsHorizontalRounded />}
          variant="clear"
          color="base.content.default"
        />
        <MenuList minW="175px">
          {isCurrentUserCreated && (
            <MenuItem
              textStyle="body-2"
              color="base.content.strong"
              icon={
                <Icon as={BiPencil} fontSize="16px" verticalAlign="middle" />
              }
              onClick={() => navigate(`${routes.assistants}/${assistant.id}`)}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            textStyle="body-2"
            color="base.content.strong"
            icon={<Icon as={BiLink} fontSize="16px" verticalAlign="middle" />}
            isDisabled={assistant.view_access === 'private'}
            onClick={onOpen}
          >
            Share
          </MenuItem>
          {isCurrentUserCreated && isAssistantsFeedbackViewable && (
            <MenuItem
              textStyle="body-2"
              color="base.content.strong"
              icon={
                <Icon
                  as={BiConversation}
                  fontSize="16px"
                  verticalAlign="middle"
                />
              }
              // TODO: add state for navigation to prevent unwanted accessing of feedback
              onClick={() =>
                navigate(`/assistants/${assistant.id}/feedbacks`, {
                  state: { from: `${location.pathname}${location.search}` },
                })
              }
            >
              View all feedback
            </MenuItem>
          )}
          {isCurrentUserCreated && (
            <DeleteAssistantButton assistantId={assistant.id} />
          )}
        </MenuList>
      </Menu>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader paddingBottom="8px">
            <VStack alignItems="start">
              <Text>Share Assistant link</Text>
              <AssistantAccessTag access={assistant.view_access} />
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb="16px">
            <AssistantsShareLinkContent assistant={assistant} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
