import { Center, Spinner } from '@chakra-ui/react'
import { HttpStatusCode, isAxiosError } from 'axios'
import _ from 'lodash'

import { ChatMessageDto } from '~shared/types/chats.dto'

import { useTrackedFlag } from '~lib/feature-flag'

import {
  AssistantAvatar,
  generateAssistantPathFromId,
} from '~features/assistants'
import { useGetSharedAssistant } from '~features/assistants/hooks/useGetSharedAssistant'
import { HIGH_RISK_ASSISTANT_WARNING_MESSAGE } from '~features/chatv2/constants'
import { ChatScreenProps } from '~features/chatv2/types'

import { ChatMessageWindow } from '../Chat/ChatMessageWindow'
import { InputToolbar } from '../InputToolbar'

import { AssistantChatHeader } from './AssistantChatHeader'
import { AssistantLiveNewChat } from './AssistantLiveNewChat'
import { AssistantUnavailable } from './AssistantUnavailable'

// TODO: moving these props into a provider in the future
type CreateAssistantChatWindowProps = ChatScreenProps & {
  assistantId: string
  chatId?: string
}

// TODO: Consider wrapping in an assistant context for child components to reduce prop drilling
export const AssistantChatScreen = ({
  assistantId,
  messages,
  isMutationLoading,
  isStreaming,
  errorMessage,
  editor,
  isSubmissionDisabled,
  chatId,
}: CreateAssistantChatWindowProps) => {
  const {
    data: sharedAssistantData,
    isLoading: sharedAssistantIsLoading,
    error: assistantError,
  } = useGetSharedAssistant({ assistantId })

  const { isEnabled: isDocumentsInAssistantChatEnabled } = useTrackedFlag(
    'documents_assistant_chat_enable',
  )

  if (sharedAssistantIsLoading) {
    return (
      <Center flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  if (!isMutationLoading && messages.length === 0 && !sharedAssistantData) {
    // TODO: Improve error handling
    if (
      !(
        isAxiosError(assistantError) &&
        assistantError.response?.status === HttpStatusCode.Forbidden
      )
    ) {
      throw new Error('Assistant cannot be loaded')
    }
  }

  if (!sharedAssistantData) {
    return <AssistantUnavailable agencyDenied={!chatId} />
  }

  // For assistant messages, we want to obfuscate the tool used, so just set
  // it to the default 'llm'
  const modifiedForAssistantMessages = _.map(
    messages,
    (message): ChatMessageDto => {
      return { ...message, tool: 'llm' }
    },
  )

  const latestMessageId = _.last(messages)?.id

  return (
    <>
      <AssistantChatHeader
        assistant={sharedAssistantData.assistant}
        latestMessageId={latestMessageId}
      />
      {modifiedForAssistantMessages.length ? (
        <ChatMessageWindow
          isAiResponseLoading={isMutationLoading}
          isAiStreaming={isStreaming}
          messages={modifiedForAssistantMessages}
          errorMessage={errorMessage}
          defaultAiAvatar={<AssistantAvatar avatarSize="2xs" />}
          assistantId={sharedAssistantData.assistant.id}
        />
      ) : (
        <AssistantLiveNewChat
          editor={editor}
          assistant={sharedAssistantData.assistant}
        />
      )}
      <InputToolbar
        editor={editor}
        isSubmissionDisabled={isSubmissionDisabled || !editor.isEditable}
        placeholder={`Ask ${sharedAssistantData.assistant.name}`}
        newChatUrl={generateAssistantPathFromId(assistantId)}
        isAllowDocumentUploads={isDocumentsInAssistantChatEnabled}
        // TODO 20240926
        // We temporarily disable the isLongChat infobox from the
        // AssistantChatScreen so it doesn't stack with the high risk
        // infobox
        isShowChatTooLongAlert={false}
        {...(sharedAssistantData.assistant.is_high_risk && {
          warningMessage: HIGH_RISK_ASSISTANT_WARNING_MESSAGE,
        })}
      />
    </>
  )
}
