import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

const parts = checkboxAnatomy.extend(
  'othersInput',
  'othersContainer',
  'othersCheckbox',
)

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const getColorProps = (props: StyleFunctionProps) => {
  const { colorScheme: c } = props

  switch (c) {
    case 'dark':
      return {
        bg: mode('utility.ui', 'transparent')(props),
        checkedBg: 'interaction.main.default',
        borderColor: mode('base.content.strong', 'utility.ui')(props),
        border: '1.5px solid',
      }
    default: {
      return {}
    }
  }
}

const baseStyle = definePartsStyle((props) => {
  const { borderColor, checkedBg, border } = getColorProps(props)

  return {
    // Control is the box containing the check icon
    control: {
      borderColor,
      _checked: {
        bg: checkedBg,
        borderColor: checkedBg,
      },
      border,
    },
  }
})

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'main',
    size: 'md',
  },
})
