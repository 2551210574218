import { useMemo } from 'react'

import { SUPPORT_EMAIL } from '~constants/config'
import { OthersMultiSelect, OthersMultiSelectProps } from '~components/ui/Radio'

import { useListSharingOptions } from '~features/assistants/hooks/useListSharingOptions'

import { APPROVAL_CONSENT_MESSAGE } from './constants/warning-messages'

type AssistantDomainSharingSelectorProps = Pick<
  OthersMultiSelectProps,
  'name' | 'values' | 'onChange' | 'errorMessage'
>

export function AssistantDomainSharingSelector({
  name,
  values,
  errorMessage,
  onChange,
}: AssistantDomainSharingSelectorProps) {
  const { data } = useListSharingOptions()

  const items = useMemo(() => {
    return (
      data?.domains.map((domainData) => ({
        label: domainData.value,
        description: domainData.label,
        value: domainData.value,
      })) || []
    )
  }, [data])

  return (
    <OthersMultiSelect
      descriptionMessage={APPROVAL_CONSENT_MESSAGE}
      nothingFoundLabel={`No matching domains found. Contact ${SUPPORT_EMAIL} to add your domain.`}
      isSearchable={true}
      name={name}
      size="xs"
      values={values}
      onChange={onChange}
      // Disable interactivity if user does not have any access to the domains specified or if still loading
      isDisabled={!data || items.length === 0}
      items={items || []}
      fixedItemHeight={64}
      errorMessage={errorMessage}
    />
  )
}
