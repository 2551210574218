export const routes = {
  login: '/login',
  health: '/health',
  chat: '/chat',
  assistants: '/assistants',
  index: '/',
  pages: '/*',
  faq: '/faq',
  privacy: '/privacy',
  termsOfUse: '/terms-of-use',
  share: '/share',
}

export const shareRoutes = {
  assistant: `${routes.share}/asst`,
}
