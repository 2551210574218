import _ from 'lodash'

import {
  BASE_CHAT_CONTENT_CONTEXT_LIMIT,
  BASE_CHAT_DOCUMENTS_CONTEXT_LIMIT,
  MODEL_CONVERSATION_SIZE_LIMIT,
} from '~shared/constants/context-limits'

import { useTrackedFlag } from '~lib/feature-flag'

import { VanillaChatScreenProps } from '~features/chatv2/types'

import { ChatMessageWindow } from '../Chat/ChatMessageWindow'
import { InputToolbar } from '../InputToolbar'

import { EmptyScreen } from './EmptyScreen'
import { VanillaChatHeader } from './VanillaChatHeader'

export const VanillaChatScreen = ({
  editor,
  messages,
  totalTokenCounts,
  isMutationLoading,
  isStreaming,
  errorMessage,
  isSubmissionDisabled,
}: VanillaChatScreenProps) => {
  const { isEnabled: isDocumentsInChatEnabled } = useTrackedFlag(
    'documents_chat_enable',
  )

  const hasExistingDocuments = _.some(
    messages,
    (message) => !!message.documents?.length,
  )

  const LONG_CHAT_LIMIT_RATIO = 0.85
  const { messageTokenCount, documentTokenCount } = totalTokenCounts
  const isTokenLimitCloseToExceeding =
    messageTokenCount >
      BASE_CHAT_CONTENT_CONTEXT_LIMIT * LONG_CHAT_LIMIT_RATIO ||
    documentTokenCount >
      BASE_CHAT_DOCUMENTS_CONTEXT_LIMIT * LONG_CHAT_LIMIT_RATIO
  const isLongChat =
    isTokenLimitCloseToExceeding ||
    messages.length > MODEL_CONVERSATION_SIZE_LIMIT

  return (
    <>
      {messages.length ? (
        <>
          <VanillaChatHeader />
          <ChatMessageWindow
            isAiResponseLoading={isMutationLoading}
            isAiStreaming={isStreaming}
            messages={messages}
            errorMessage={errorMessage}
          />
        </>
      ) : (
        <EmptyScreen editor={editor} />
      )}

      <InputToolbar
        editor={editor}
        isSubmissionDisabled={isSubmissionDisabled}
        isAllowDocumentUploads={isDocumentsInChatEnabled}
        defaultSensitivityAcknowledged={hasExistingDocuments}
        isShowChatTooLongAlert={isLongChat}
      />
    </>
  )
}
