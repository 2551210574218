import { useQuery } from '@tanstack/react-query'

import { GetAssistantStatisticsResponseDto } from '~shared/types/assistant-feedback.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const getAssistantFeedbackStatistics = async ({
  assistantId,
}: {
  assistantId: string
}) => {
  return ApiService.get<GetAssistantStatisticsResponseDto>(
    `/assistants/${assistantId}/feedbacks/statistics`,
  ).then((response) => response.data)
}

export function useGetAssistantFeedbackStatistics({
  assistantId,
  enabled,
}: {
  assistantId: string
  enabled: boolean
}) {
  return useQuery(
    queries.assistants.feedbacks(assistantId)._ctx.statistics.queryKey,
    async () => {
      const response = await getAssistantFeedbackStatistics({ assistantId })
      return response
    },
    { enabled },
  )
}
