import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import {
  DocumentMimeTypeType,
  PrepareDocumentUploadRequestDto,
  PrepareDocumentUploadResponseDto,
} from '~shared/types/documents.dto'

import { ApiService } from '~lib/api'
import { APP_ENV } from '~constants/config'
import { queries } from '~constants/queries'

const prepareDocumentUpload = async (
  requestData: PrepareDocumentUploadRequestDto,
): Promise<PrepareDocumentUploadResponseDto> => {
  const response = await ApiService.post<PrepareDocumentUploadResponseDto>(
    '/documents/upload',
    requestData,
  )
  return response.data
}

const mockPrepareDocumentUpload = async (
  requestData: PrepareDocumentUploadRequestDto,
): Promise<PrepareDocumentUploadResponseDto> => {
  const response = await ApiService.post<PrepareDocumentUploadResponseDto>(
    '/documents/mock-upload',
    requestData,
  )
  return response.data
}

const uploadToPresignedUrl = async (url: string, file: File): Promise<void> => {
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  })
}

const uploadDocument = async ({
  document,
}: {
  document: File
}): Promise<{ documentId: string }> => {
  // TODO: Handle local development better
  if (APP_ENV === 'development') {
    const { document_id } = await mockPrepareDocumentUpload({
      name: document.name,
      // Validation will be done via class validator, so just cast first
      mime_type: document.type as DocumentMimeTypeType,
    })

    // Simulate uploading on develop
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return { documentId: document_id }
  }

  // 1) Get presigned URL
  const { presigned_upload_url: presignedUploadUrl, document_id } =
    await prepareDocumentUpload({
      name: document.name,
      // Validation will be done via class validator, so just cast first
      mime_type: document.type as DocumentMimeTypeType,
    })
  // 2) Upload to presigned URL
  await uploadToPresignedUrl(presignedUploadUrl, document)
  // Return the document ID
  return { documentId: document_id }
}

export function useUploadDocument({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: { documentId: string }) => void
  onError?: (error: { status: number }) => void
}) {
  const { isLoading, mutate } = useMutation(
    queries.documents.upload.queryKey,
    uploadDocument,
    {
      onSuccess,
      onError,
    },
  )

  return {
    isLoading,
    mutate,
  }
}
