import { SVGProps } from 'react'

export const PairAvatarLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#3C4764"
      fillRule="evenodd"
      d="M7.275 0C3.257 0 0 3.195 0 7.137v16.005c0 .853 1.132 1.182 1.605.466l4-3.529a7.433 7.433 0 0 0 1.67.189h8.656c4.018 0 7.275-3.195 7.275-7.137V7.137C23.206 3.195 19.95 0 15.931 0H7.275Z"
      clipRule="evenodd"
    />
    <path
      fill="#F7F9FB"
      d="M14.273 8.656c0-1.202-1.031-2.05-3.01-2.05H7.97l.035.471.375.048c.387.047.445.106.445.212v5.888c0 .13-.105.188-.398.223l-.422.048-.035.47h3.432l-.035-.47-.562-.048c-.352-.035-.434-.094-.434-.2v-2.225c.246.023.54.047.797.047 1.64 0 3.104-.86 3.104-2.414Zm-1.675.2c0 1.024-.61 1.707-1.488 1.707-.258 0-.503-.035-.738-.082V7.1h.656c.996 0 1.57.695 1.57 1.755Z"
    />
    <path
      fill="#E9EAEE"
      fillRule="evenodd"
      d="M15.053 16.814V3.234h.177v13.58h-.177Z"
      clipRule="evenodd"
    />
  </svg>
)
