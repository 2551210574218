import { useMutation } from '@tanstack/react-query'

import { DeleteDocumentResponseDto } from '~shared/types/documents.dto'

import { ApiService } from '~lib/api'

export const deleteDocument = async ({
  documentId,
}: {
  documentId: string
}) => {
  return ApiService.delete<DeleteDocumentResponseDto>(
    `/documents/${documentId}`,
  ).then((response) => response.data)
}

export function useDeleteDocument() {
  const { isLoading, mutate } = useMutation(deleteDocument)

  return {
    isLoading,
    mutate,
  }
}
