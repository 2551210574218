import { FaRegTrashAlt } from 'react-icons/fa'
import { HStack, Spinner, Text, VStack } from '@chakra-ui/react'

import { TruncatedDocumentDto } from '~shared/types/documents.dto'

import { IconButtonWithTooltip } from '~components/IconButtonWithTooltip'

type DocumentItemProps = {
  document: TruncatedDocumentDto
  tokenLimit: number
  onDelete: ({ documentId }: { documentId: string }) => void
  isDisabled?: boolean
}

export const DocumentItem = ({
  document,
  tokenLimit,
  onDelete,
  isDisabled,
}: DocumentItemProps) => {
  const isCompleted = document.status === 'completed'

  const tokenLimitPercentage = document.token_count
    ? Math.round((document.token_count / tokenLimit) * 100)
    : 0

  return (
    <HStack
      justifyContent="space-between"
      spacing="32px"
      backgroundColor={
        isCompleted
          ? 'interaction.main-subtle.default'
          : 'interaction.neutral-subtle.default'
      }
      borderColor="base.divider.medium"
      borderRadius="4px"
      borderWidth="1px"
      padding="20px"
      width="100%"
    >
      <VStack alignItems="start" spacing="4px" flex={1} minWidth={0}>
        <Text textStyle="caption-1" noOfLines={1} width="100%">
          {document.name}
        </Text>
        {document.status === 'completed' && (
          <Text textStyle="caption-2" color="base.content.medium">
            {tokenLimitPercentage === 0 ? '~' : ''}
            {tokenLimitPercentage}% of context limit
          </Text>
        )}
      </VStack>
      {(document.status === 'processing' || document.status === 'queued') && (
        <HStack color="base.content.medium" flexShrink={0}>
          <Spinner size="sm" />
          <Text textStyle="caption-1">
            {document.status === 'processing' ? 'Processing...' : 'Scanning...'}
          </Text>
        </HStack>
      )}
      {document.status === 'completed' && (
        <IconButtonWithTooltip
          tooltipText={'Delete document'}
          aria-label="delete document"
          onClick={() => {
            onDelete({ documentId: document.id })
          }}
          variant="clear"
          icon={<FaRegTrashAlt />}
          color="interaction.critical.default"
          size="xs"
          fontSize="16px"
          flexShrink={0}
          isDisabled={isDisabled}
        />
      )}
    </HStack>
  )
}
