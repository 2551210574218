import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Fonts } from '~/theme/foundations/fonts'

import { AuthProvider } from '~lib/auth'
import { FlagProvider } from '~lib/feature-flag'
import { queryClient } from '~lib/react-query'

import { ConversationStateProvider } from '~features/chatv2/hooks/useConversationContext'

import { AppRouter } from './AppRouter'
import { AppThemeProvider } from './AppThemeProvider'

const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    {/* OGP Design system sets portalZIndex to 40 to fix conflicting zIndexes between components, but this messes up some of the display order of Chakra react portals  */}
    {/* Use specific theme provider to ensure toasts appear above modals. */}
    {/* https://github.com/chakra-ui/chakra-ui/issues/6276#issuecomment-1429862773 */}
    {/* https://github.com/opengovsg/design-system/blob/main/react/src/theme/ThemeProvider.tsx */}
    <AppThemeProvider>
      <Fonts />
      <AuthProvider>
        <FlagProvider>
          <ConversationStateProvider>
            <AppRouter />
          </ConversationStateProvider>
        </FlagProvider>
      </AuthProvider>
    </AppThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} position={'bottom-left'} />
  </QueryClientProvider>
)

export default App
