import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

type FeatureName = 'documents'

type FeatureAnnouncement = {
  hasSeen: boolean
}

type IUserPreferencesStore = {
  featureAnnouncements: Record<string, FeatureAnnouncement>
  setHasSeen: (featureName: FeatureName) => void
}

export const useUserPreferencesStore = create<IUserPreferencesStore>()(
  devtools(
    persist(
      (set) => ({
        featureMode: 'default',
        featureAnnouncements: {},
        setHasSeen: (featureName: FeatureName) => {
          set((state) => ({
            featureAnnouncements: {
              ...state.featureAnnouncements,
              [featureName]: { hasSeen: true },
            },
          }))
        },
      }),
      {
        name: 'user-preferences-store',
        storage: createJSONStorage(() => localStorage),
        version: 1,
      },
    ),
  ),
)

export function useFeatureAnnouncement(featureName: FeatureName) {
  const hasSeen = useUserPreferencesStore(
    (state) => state.featureAnnouncements[featureName]?.hasSeen ?? false,
  )
  const setHasSeen = useUserPreferencesStore(
    (state) => () => state.setHasSeen(featureName),
  )
  return {
    hasSeen,
    setHasSeen,
  }
}
