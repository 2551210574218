import { ReactNode, useRef } from 'react'
import {
  PopoverAnchor,
  PopoverContent,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { AssistantFeedbackType } from '~shared/types/assistant-feedback.dto'
import { FeedbackRatingType } from '~shared/types/feedbacks.dto'

import { UnanimatedPopover } from '~components/UnanimatedComponents'

import { useAssistantFeedback } from '~features/chatv2/hooks/useAssistantFeedback'

import {
  AssistantFeedbackFormBody,
  AssistantFeedbackFormControl,
} from './AssistantFeedbackForm'

type AssistantFeedbackPopoverProps = {
  assistantId: string
  headerText: string
  feedbackRating?: FeedbackRatingType
  feedbackType: AssistantFeedbackType
  children: (props: { onOpen: () => void }) => ReactNode
  messageId: string
  placeholder?: string
}

// Not using RHF since we dont really need any validation or complex validation.
export const AssistantFeedbackPopover = ({
  children,
  assistantId,
  headerText,
  feedbackRating,
  feedbackType,
  messageId,
  placeholder,
}: AssistantFeedbackPopoverProps) => {
  const isAssistantLevelFeedback = feedbackType === 'assistant_level'

  // For referencing text area for initial focus on popover
  const textAreaFieldRef = useRef<HTMLTextAreaElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    feedbackOnClose,
    onSubmit,
    isCreateAssistantFeedbackLoading,
    numericRating,
    setNumericRating,
    isAllowCreatorToView,
    setIsAllowCreatorToView,
    feedbackContent,
    setFeedbackContent,
  } = useAssistantFeedback({
    assistantId,
    onClose,
    feedbackRating,
    messageId,
    feedbackType,
  })

  return (
    <UnanimatedPopover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={feedbackOnClose}
      initialFocusRef={textAreaFieldRef}
      isLazy
    >
      <PopoverAnchor>{children({ onOpen })}</PopoverAnchor>
      <PopoverContent
        p="16px 16px"
        width="374px"
        marginRight="24px" // Prevents undesirable overflow
        // See https://github.com/chakra-ui/chakra-ui/issues/4109
        rootProps={{ style: { transform: 'scale(0)' } }}
      >
        <VStack spacing="8px" align="stretch">
          <AssistantFeedbackFormBody
            isAssistantLevelFeedback={isAssistantLevelFeedback}
            headerText={headerText}
            numericRating={numericRating}
            setNumericRating={setNumericRating}
            feedbackContent={feedbackContent}
            setFeedbackContent={setFeedbackContent}
            isAllowCreatorToView={isAllowCreatorToView}
            setIsAllowCreatorToView={setIsAllowCreatorToView}
            placeholder={placeholder}
          />
          <AssistantFeedbackFormControl
            feedbackOnClose={feedbackOnClose}
            onSubmit={onSubmit}
            isDisabled={isAssistantLevelFeedback && numericRating <= 0}
            isLoading={isCreateAssistantFeedbackLoading}
          />
        </VStack>
      </PopoverContent>
    </UnanimatedPopover>
  )
}
