import { useState } from 'react'
import { BiSolidError } from 'react-icons/bi'
import {
  Button,
  FormLabel,
  HStack,
  Icon,
  Mark,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useCustomToast } from '~hooks/useCustomToast'
import { UnanimatedModal } from '~components/UnanimatedComponents'

import { useDeleteAssistant } from '../hooks/useDeleteAssistant'

type DeleteAssistantButtonProps = {
  assistantId: string
  isOpenDeleteAssistantModal: boolean
  onCloseDeleteAssistantModal: () => void
  onAssistantDeleted?: () => void
}

export const DeleteAssistantModal = ({
  assistantId,
  isOpenDeleteAssistantModal,
  onCloseDeleteAssistantModal,
  onAssistantDeleted,
}: DeleteAssistantButtonProps) => {
  const [uploadedConfi, setUploadedConfi] = useState('')
  const { isLoading, mutate } = useDeleteAssistant()
  const { createErrorToast, createSuccessToast } = useCustomToast()

  const onClickDeleteAssistant = () => {
    mutate(
      {
        assistantId,
        uploadedConfi: uploadedConfi == 'yes',
        deleteRelatedMessages: true,
      },
      {
        onError: () => {
          createErrorToast({
            content:
              'An unexpected error has occurred while deleting your assistant. Please try again.',
          })
        },
        onSuccess: () => {
          createSuccessToast({
            content: 'Your Assistant has been successfully deleted.',
            duration: 5_000,
          })
          onCloseDeleteAssistantModal()
          if (onAssistantDeleted) {
            onAssistantDeleted()
          }
        },
      },
    )
  }

  return (
    <UnanimatedModal
      onClose={onCloseDeleteAssistantModal}
      isOpen={isOpenDeleteAssistantModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="sm" />
        <ModalHeader paddingBottom="24px">
          Delete this assistant for you and all other users
        </ModalHeader>
        <ModalBody as={VStack} align="stretch" spacing="24px">
          <HStack alignItems="left">
            <Icon fontSize="24px" color="interaction.critical.default">
              <BiSolidError />
            </Icon>
            <Text textStyle="body-2">
              <Mark fontWeight="700" color="interaction.critical.default">
                Warning:
              </Mark>{' '}
              Once deleted, you and all other current users will no longer be
              able to access the assistant, past conversations and all
              associated data. This action is irreversible.
            </Text>
          </HStack>
          <RadioGroup
            size="md"
            onChange={(e) => {
              setUploadedConfi(e)
            }}
          >
            <FormLabel>
              Have you accidentally uploaded confidential information?
            </FormLabel>
            <Radio value="no" size="sm">
              No, I did not upload any confidential information
            </Radio>
            <Radio value="yes" size="sm">
              Yes, I uploaded confidential information
            </Radio>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant="clear"
              color="neutral"
              onClick={onCloseDeleteAssistantModal}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              backgroundColor="interaction.critical.default"
              mr={3}
              onClick={onClickDeleteAssistant}
              isDisabled={uploadedConfi === ''}
              isLoading={isLoading}
            >
              Delete assistant
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </UnanimatedModal>
  )
}
