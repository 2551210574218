import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'

import { FeedbackTypesFilterEnumType } from '~shared/types/assistant-feedback.dto'

const chatKeys = createQueryKeys('chat', {
  list: {
    queryKey: null,
  },
  detail: (conversationId: string) => ({
    queryKey: [conversationId],
  }),
  create: (conversationId: string) => ({
    queryKey: ['create', conversationId],
  }),
})

const assistantKeys = createQueryKeys('assistants', {
  list: {
    queryKey: null,
    contextQueries: {
      recent: { queryKey: ['recent'] },
    },
  },
  detail: (assistantId: string) => ({
    queryKey: [assistantId],
  }),
  sharedList: null,
  sharedDetail: (assistantId: string) => ({
    queryKey: [assistantId],
  }),
  sharingOptions: null,
  feedbacks: (assistantId: string) => ({
    queryKey: [assistantId],
    contextQueries: {
      statistics: null,
      filter: ({
        page,
        feedbackTypesFilter,
      }: {
        page: number
        feedbackTypesFilter: FeedbackTypesFilterEnumType
      }) => ({
        queryKey: [feedbackTypesFilter, page],
      }),
      messageLogs: ({ feedbackId }: { feedbackId: string }) => ({
        queryKey: [feedbackId],
      }),
    },
  }),
  preview: (assistantId: string) => ({
    queryKey: [assistantId],
  }),
  listPromotional: null,
})

const userKeys = createQueryKeys('user', {
  userType: (id: string) => ({
    queryKey: [id],
  }),
})

const documentKeys = createQueryKeys('documents', {
  listByIds: (ids: string[]) => ({
    queryKey: [ids],
  }),
  upload: null,
})

export const queries = mergeQueryKeys(
  chatKeys,
  assistantKeys,
  userKeys,
  documentKeys,
)
