import { ReactNode, useEffect, useRef } from 'react'
import {
  AbsoluteCenter,
  Box,
  Divider,
  HStack,
  Spinner,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react'

import { ChatMessageDto, ChatWithMessagesDto } from '~shared/types/chats.dto'

import { ChatMessage } from '~features/chatv2/components/Chat/ChatMessage'
import { ChatScrollAnchor } from '~features/chatv2/components/Chat/ChatScrollAnchor'
import { ErrorMessage } from '~features/chatv2/components/Chat/ErrorMessage'
import { useAutoScrollToBottom } from '~features/chatv2/hooks'

interface PreviewChatMessageWindowProps extends StackProps {
  isAiResponseLoading: boolean
  isAiStreaming: boolean
  errorMessage: string | null
  defaultAiAvatar?: ReactNode
  previousChats: ChatWithMessagesDto[]
  currentChatMessages: ChatMessageDto[]
  isUpdatingPreview: boolean
}

export const PreviewChatMessageWindow = ({
  isAiResponseLoading,
  isAiStreaming,
  errorMessage,
  defaultAiAvatar,
  previousChats,
  currentChatMessages: currentChat,
  isUpdatingPreview,
  ...props
}: PreviewChatMessageWindowProps) => {
  // Ref to track the scroll state of window
  const ref = useRef<HTMLDivElement>(null)

  // Autoscrolls when streaming is happening
  useAutoScrollToBottom({ scrollElement: ref.current }, [
    currentChat,
    isAiResponseLoading,
  ])

  // Auto-scroll to bottom when new message error message
  useEffect(() => {
    ref.current?.scrollTo(0, ref.current.scrollHeight)
  }, [currentChat, previousChats, isAiResponseLoading, errorMessage])

  return (
    <VStack
      ref={ref}
      width="100%"
      overflowY="auto"
      spacing="0px"
      alignItems="stretch"
      flexGrow={1}
      px="44px"
      paddingBottom="16px"
      {...props}
    >
      {previousChats.map((chat, chatIdx) => {
        return (
          <Box key={`msgwdivider_${chatIdx}`}>
            {chat.messages.map((message, message_idx) => {
              return (
                <Box key={`${chatIdx}_${message_idx}`}>
                  <ChatMessage
                    id={message.id}
                    content={message.content}
                    author={message.author}
                    documents={message.documents}
                    tool={message.tool}
                    sources={message.sources}
                    defaultAiAvatar={defaultAiAvatar}
                    isPreview={true}
                    isFadedText={true}
                  />
                </Box>
              )
            })}

            <Box position="relative" width="full" py="8px">
              {isUpdatingPreview && chatIdx === previousChats.length - 1 ? (
                <>
                  <Divider borderColor="base.content.brand" variant="dashed" />
                  <AbsoluteCenter
                    backgroundColor="base.canvas.alt-light"
                    px="3px"
                  >
                    <HStack>
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="base.content.brand"
                        size="xs"
                      />
                      <Text color="base.content.brand" textStyle="caption-3">
                        UPDATING PREVIEW
                      </Text>
                    </HStack>
                  </AbsoluteCenter>
                </>
              ) : (
                <>
                  <Divider borderColor="base.content.medium" />
                  <AbsoluteCenter
                    backgroundColor="base.canvas.alt-light"
                    px="3px"
                  >
                    <Text color="base.content.medium" textStyle="caption-3">
                      PREVIEW UPDATED
                    </Text>
                  </AbsoluteCenter>
                </>
              )}
            </Box>
          </Box>
        )
      })}

      {currentChat.map((message, message_idx) => {
        return (
          <ChatMessage
            id={message.id}
            key={`cur_${message_idx}`}
            content={message.content}
            author={message.author}
            documents={message.documents}
            tool={message.tool}
            sources={message.sources}
            defaultAiAvatar={defaultAiAvatar}
            isPreview={true}
          />
        )
      })}

      {/* Loading state, which presents itself as a chat message */}
      {isAiResponseLoading && (
        <ChatMessage
          id=""
          content=""
          author="assistant"
          tool="llm"
          isLoading={true}
          defaultAiAvatar={defaultAiAvatar}
          isPreview={true}
        />
      )}
      {errorMessage && (
        <ErrorMessage
          defaultAiAvatar={defaultAiAvatar}
          content={
            errorMessage
              ? errorMessage
              : 'The chat has encountered an unidentified error. Please refresh the chat and try again.'
          }
          isPreview={true}
        />
      )}

      {/* Automatically scroll down to the bottom on reply, provided
       * that user is within scroll intersection area
       */}
      <ChatScrollAnchor trackVisibility={isAiStreaming} scrollRef={ref} />
    </VStack>
  )
}
