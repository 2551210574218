import { useQuery } from '@tanstack/react-query'

import { GetAssistantFeedbackMessageLogsResponseDto } from '~shared/types/assistant-feedback.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const getAssistantFeedbackMessageLogs = async ({
  assistantId,
  feedbackId,
}: {
  assistantId: string
  feedbackId: string
}) => {
  return ApiService.get<GetAssistantFeedbackMessageLogsResponseDto>(
    `/assistants/${assistantId}/feedbacks/${feedbackId}/message-logs`,
  ).then((response) => response.data)
}

export function useGetAssistantFeedbackMessageLogs({
  assistantId,
  feedbackId,
  enabled,
}: {
  assistantId: string
  feedbackId: string
  enabled: boolean
}) {
  const { data, isInitialLoading } = useQuery(
    queries.assistants.feedbacks(assistantId)._ctx.messageLogs({ feedbackId })
      .queryKey,
    async () => {
      const response = await getAssistantFeedbackMessageLogs({
        assistantId,
        feedbackId,
      })
      return response
    },
    { enabled },
  )
  // See more here for enabled problems with isLoading: https://github.com/TanStack/query/issues/3584
  return {
    assistantFeedbackMessageLogs: data,
    isLoadingAssistantFeedbackMessageLogs: isInitialLoading,
  }
}
