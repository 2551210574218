import { HStack, Text, Wrap } from '@chakra-ui/react'
import { Tag } from '@opengovsg/design-system-react'

type ChatMessageSourcesProps = {
  sources: { id: string; name: string }[]
}

export const ChatMessageSources = ({ sources }: ChatMessageSourcesProps) => {
  return (
    <HStack alignItems="flex-start" spacing="8px">
      <Text textStyle="body-2" fontWeight={700} lineHeight="1.5rem">
        Context
      </Text>
      <Wrap spacingX="8px">
        {sources.map((source) => (
          <Tag
            size="md"
            color="interaction.sub.default"
            backgroundColor="interaction.sub-subtle.default"
            colorScheme="interaction.sub"
            textStyle="caption-1"
            key={source.id}
          >
            {source.name}
          </Tag>
        ))}
      </Wrap>
    </HStack>
  )
}
