import { SimpleGrid } from '@chakra-ui/react'

import { PROMPT_TEMPLATES } from '~features/chatv2/constants'
import { ChatInputType } from '~features/chatv2/types'

import { PromptCard } from '../Prompts/PromptCard'

type PromptHighlightsProps = {
  onSelect: (content: ChatInputType['text']) => void
}

export const VanillaPromptHighlights = ({
  onSelect,
}: PromptHighlightsProps) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing="12px"
      justifyContent="center"
      padding="16px"
      width="80%"
    >
      {PROMPT_TEMPLATES.map(({ title, content }, idx) => (
        <PromptCard key={idx} title={title} onClick={() => onSelect(content)} />
      ))}
    </SimpleGrid>
  )
}
