import { UAParser } from 'ua-parser-js'

type ITrackedUserAgent = {
  tracked_browser_name?: string
  tracked_os_name?: string
  tracked_os_version?: string
}

const userAgent = UAParser()

export function getDevicePixelRatio() {
  return window.devicePixelRatio
}

export function getBrowserInfo(): ITrackedUserAgent {
  return {
    tracked_browser_name: userAgent.browser.name,
    tracked_os_name: userAgent.os.name,
    tracked_os_version: userAgent.os.version,
  }
}
