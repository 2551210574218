import { Button, HStack, Text, VStack } from '@chakra-ui/react'

export type ErrorDocumentType = {
  id: string
  name: string
  type: string
  message?: string
}

export const ErrorDocumentItem = ({
  errorDocument,
  onDelete,
}: {
  errorDocument: ErrorDocumentType
  onDelete: (documentId: string) => void
}) => {
  let errorMessage = 'There was an error processing this document.'
  switch (errorDocument.type) {
    case 'application/pdf':
      errorMessage =
        'There was an error processing this PDF. Please ensure the file is not encrypted and try again, or paste the contents of the file in a .txt file.'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      errorMessage =
        'There was an error processing this document. Try (i) opening and re-saving the file in Microsoft Word, (ii) exporting it as a PDF, or (iii) pasting the contents of the document into a .txt file '
      break
    default: {
      errorMessage =
        'There was an error processing this document. Please copy the contents of the file into a new .txt file and try again.'
    }
  }

  return (
    <HStack
      justifyContent="space-between"
      spacing="32px"
      backgroundColor="interaction.critical-subtle.default"
      borderColor="base.divider.medium"
      borderRadius="4px"
      borderWidth="1px"
      padding="20px"
      width="100%"
    >
      <VStack
        alignItems="start"
        spacing="4px"
        color="utility.feedback.critical"
      >
        <Text textStyle="caption-1" noOfLines={1}>
          {errorDocument.name}
        </Text>
        <Text textStyle="caption-2">
          {errorDocument.message ?? errorMessage}
        </Text>
      </VStack>
      <Button
        size="xs"
        variant="clear"
        onClick={() => {
          onDelete(errorDocument.id)
        }}
      >
        <Text textStyle="caption-1">Dismiss</Text>
      </Button>
    </HStack>
  )
}
