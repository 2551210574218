import { useState } from 'react'
import { BiCheck, BiPencil, BiTrash, BiX } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  useEditableControls,
  VStack,
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queries } from '~constants/queries'
import { UnanimatedMenuList } from '~components/UnanimatedComponents'

import { deleteChat, updateChatTitle } from '~features/chatv2/api'

import { SidebarItemContainer } from '../SidebarItemContainer'

type SidebarConversationsEntryProps = {
  id: string
  title: string
  createdAt: string
  onClick?: () => void
  isSelected?: boolean
  assistantName?: string
  isAssistantDeleted?: boolean
}

interface EditableControlsProps {
  onDelete: () => void
  isAssistantDeleted?: boolean
}

// Editable Controls manages the state of the editable title
// Editable Controls need to be within an Editable context
export const EditableControls = ({
  onDelete,
  isAssistantDeleted,
}: EditableControlsProps) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup
      justifyContent="flex-end"
      size="sm"
      spacing="0px"
      alignItems="center"
    >
      <IconButton
        variant="clear"
        colorScheme="inverse"
        aria-label="confirm"
        icon={<BiCheck />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        variant="clear"
        colorScheme="inverse"
        aria-label="cancel"
        icon={<BiX />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Search database"
        variant="clear"
        size="2xs"
        colorScheme="inverse"
        icon={<BsThreeDots color="white" fontSize="16px" />}
      />
      <UnanimatedMenuList>
        {isAssistantDeleted !== true && (
          <MenuItem icon={<BiPencil />} {...getEditButtonProps()}>
            Edit Title
          </MenuItem>
        )}
        <MenuItem
          icon={<BiTrash />}
          color="interaction.critical.default"
          onClick={onDelete}
        >
          Delete Conversation
        </MenuItem>
      </UnanimatedMenuList>
    </Menu>
  )
}

export const SidebarConversationsEntry = ({
  id,
  title,
  onClick,
  isSelected,
  assistantName,
  isAssistantDeleted,
}: SidebarConversationsEntryProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // TODO: refactor this into useChat?
  const updateChatTitleMutation = useMutation(updateChatTitle)
  const deleteChatMutation = useMutation(deleteChat, {
    onSuccess: async () => {
      // Invalidate list of chats
      await queryClient.invalidateQueries({
        queryKey: queries.chat.list.queryKey,
      })

      // Force a redirect to chat main page to avoid un-retrievable chat
      navigate('/chat')
    },
  })

  const [isHovered, setIsHovered] = useState(false)

  return (
    <Editable
      defaultValue={title}
      placeholder="Enter a new chat title"
      textStyle="body-2"
      isPreviewFocusable={false}
      onSubmit={(newTitle: string) => {
        updateChatTitleMutation.mutate({
          chatId: id,
          data: { title: newTitle },
        })
      }}
    >
      <SidebarItemContainer
        onClick={onClick}
        isSelected={isSelected}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <VStack
          spacing="0px"
          alignItems="flex-start"
          color="base.content.inverse"
          paddingBottom="2px"
        >
          <EditablePreview noOfLines={1} cursor="pointer" maxWidth="200px" />
          {/* Removes ugly chakra blue outline due to WAI-ARIA compliance */}
          <EditableInput
            _focus={{ boxShadow: 'none' }}
            _placeholder={{ color: 'interaction.support.placeholder' }}
            name="chat-title-input"
          />
          {assistantName && (
            <Text
              color="interaction.support.unselected"
              textStyle="caption-2"
              noOfLines={1}
              maxWidth="200px"
            >
              {assistantName}
            </Text>
          )}
          {isAssistantDeleted && (
            <Text color="interaction.support.unselected" textStyle="caption-2">
              Deleted assistant
            </Text>
          )}
        </VStack>
        {isHovered && (
          <EditableControls
            onDelete={() => deleteChatMutation.mutate(id)}
            isAssistantDeleted={isAssistantDeleted}
          />
        )}
      </SidebarItemContainer>
    </Editable>
  )
}
