import { useQuery } from '@tanstack/react-query'

import { GetAssistantResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const getAssistant = async (assistantId: string) => {
  return ApiService.get<GetAssistantResponseDto>(
    `/assistants/${assistantId}`,
  ).then((response) => response.data)
}

export function useGetAssistant({
  assistantId,
  onError,
}: {
  assistantId: string
  onError?: (err: unknown) => void
}) {
  return useQuery(
    queries.assistants.detail(assistantId).queryKey,
    () => getAssistant(assistantId),
    { onError },
  )
}
