// TODO: Swap over to default "env.mode"
export const APP_ENV = import.meta.env.VITE_REACT_APP_ENV

// Base url resolves to server serving the static if not specified
const BASE_URL = import.meta.env.VITE_API_PREVIEW_URL || ''

export const BASE_API_URL = BASE_URL + '/api/v1'

export const SUPPORT_EMAIL = 'support@pair.gov.sg'
export const SUPPORT_FORM_URL = 'https://go.gov.sg/pair-support'
export const PAIR_WISE_URL = 'https://go.gov.sg/pair-wise'
export const PAIR_WISE_ASSISTANTS_URL = 'https://go.gov.sg/pair-wise-assistants'
export const PAIR_PROMPT_GUIDES_URL =
  'https://go.gov.sg/pair-assistants-sample-instructions'
export const PAIR_CHAT_OR_ASSISTANTS_URL =
  'https://go.gov.sg/pair-chat-or-assistants'
export const FEEDBACK_FORM_URL = 'https://go.gov.sg/pair-feedback'
export const PAIR_WISE_WHATS_NEW_INFO_URL = 'https://go.gov.sg/pair-whats-new'

export const PROMPT_ASSISTANT_URL =
  BASE_URL + '/chat?assistant=assistant_4cc104b9-5b63-49eb-a921-3a2fd85609d4'

// Footer
export const REPORT_VULNERABILITY_URL =
  'https://www.tech.gov.sg/report_vulnerability'

export const VERSION_NOTES = ['Limited Access']

// Monitoring related configs
export const DATADOG_APPLICATION_ID = import.meta.env
  .VITE_DATADOG_APPLICATION_ID
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
