// For all the error messages of the application

export const DEFAULT_STREAM_TERMINATED_ERROR_MESSAGE =
  'Something went wrong and your connection got interrupted.'

// TODO: delete v1 error messages
export const STREAM_BAD_REQUEST_ERROR_MESSAGE =
  'The request cannot be completed. Message provided cannot exceed defined limit.'

// TODO: Update other error messages to include "Please refresh your ..."
// since for V2 ErrorMessage we don't add that by default
export const EXCEEDS_TOKEN_LIMITS_ERROR_MESSAGE =
  'The message you have submitted was too long. Please reload the conversation and submit a shorter message.'

export const INTERNAL_SERVER_ERROR_MESSAGE =
  'Something went wrong. Our team has been notified and is currently working to fix the issue. Please refresh your chat and try again.'

export const RATE_LIMIT_ERROR_MESSAGE =
  'We are currently experiencing a high volume of traffic and were unable to process your message. Please try again later.'

export const ASSISTANT_NO_LONGER_AVAILABLE_MESSAGE =
  'This assistant has been deleted, or is no longer shared with you.'

export const LLM_STREAM_TIMEOUT =
  'Sorry for the interruption. Please refresh to view the full response.'

export const DEFAULT_UNCAUGHT_ERROR_MESSAGE =
  'An unexpected error has occurred. Please contact the Pair team if the problem persists'
