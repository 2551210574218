import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import { BiInfoCircle, BiSolidErrorCircle } from 'react-icons/bi'
import {
  Box,
  Checkbox,
  HStack,
  Icon,
  Spinner,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Attachment } from '@opengovsg/design-system-react'
import _ from 'lodash'

import {
  SUPPORTED_MIME_TYPES,
  UPLOAD_DOCUMENT_MAX_SIZE_IN_BYTES,
  UPLOAD_DOCUMENT_MAX_SIZE_IN_MEGABYTES,
} from '~shared/constants/documents'

import { TOKENS_TO_WORDS_RATIO } from '~constants/tokens'
import { useCustomToast } from '~hooks/useCustomToast'

import { UncheckSensitivityModal } from '~features/assistants/components/AssistantsForm/UncheckSensitivityModal'
import { MAX_DOCUMENTS_PER_UPLOAD_LIMIT } from '~features/documents/constants'

import {
  useDeleteDocument,
  useGetDocuments,
  useUploadDocument,
} from '../../hooks'

import { DocumentItem } from './DocumentItem'
import { ErrorDocumentItem, ErrorDocumentType } from './ErrorDocumentItem'

interface DocumentsUploadPanelProps extends StackProps {
  countLimit: number
  tokenLimit: number
  uploadedDocumentIds: string[]
  setUploadedDocumentIds: Dispatch<SetStateAction<string[]>>
  isDisabled?: boolean
  defaultSensitivityAcknowledged?: boolean
}

export const DocumentsUploadPanel = ({
  countLimit,
  tokenLimit,
  uploadedDocumentIds,
  setUploadedDocumentIds,
  isDisabled,
  defaultSensitivityAcknowledged = false,
  ...props
}: DocumentsUploadPanelProps) => {
  // If user has already uploaded some documents for this assistant, leave
  // box as checked
  const [isSensitivityConfirmed, setIsSensitivityConfirmed] = useState(
    defaultSensitivityAcknowledged || uploadedDocumentIds.length > 0,
  )

  const [
    isFailedUploadDueToDocumentLimit,
    setIsFailedUploadDueToDocumentLimit,
  ] = useState(false)

  const initialDocumentIdsRef = useRef<string[]>(uploadedDocumentIds)
  const [rejectedDocuments, setRejectedDocuments] = useState<
    ErrorDocumentType[]
  >([])

  const { createErrorToast } = useCustomToast()

  // Actions
  const { mutate: uploadDocumentMutate, isLoading: isDocumentUploading } =
    useUploadDocument({
      onSuccess: (data) => {
        setUploadedDocumentIds((prev) => [...prev, data.documentId])
      },
      onError: (error) => {
        const status = _.get(error, 'status')
        let errorMessage =
          'An unexpected error has occurred while uploading the document. Please try again.'
        if (status === 429) {
          errorMessage =
            "You've reached the maximum number of document uploads for this time period. Please try again in a few minutes."
        }
        createErrorToast({
          content: errorMessage,
        })
      },
    })

  const {
    uploadedDocuments,
    completedDocuments,
    processingOrQueuedDocuments,
    totalTokenCount,
  } = useGetDocuments({
    documentIds: uploadedDocumentIds,
    countLimit,
    tokenLimit,
  })

  const numExistingDocuments =
    completedDocuments.length + processingOrQueuedDocuments.length

  const handleFileUpload = useCallback(
    (files: File[]) => {
      if (files.length > MAX_DOCUMENTS_PER_UPLOAD_LIMIT) {
        createErrorToast({
          content: `You can upload up to ${MAX_DOCUMENTS_PER_UPLOAD_LIMIT} files at a time. Please try again with fewer files.`,
        })
        return
      }

      if (files.length + numExistingDocuments > countLimit) {
        setIsFailedUploadDueToDocumentLimit(true)
        return
      }
      setIsFailedUploadDueToDocumentLimit(false)

      const newRejectedDocuments: ErrorDocumentType[] = []

      for (const file of files) {
        if (file && isSensitivityConfirmed) {
          if (file.size === 0) {
            // Doesn't need to be robust, only exists temporarily in the
            // frontend
            const randomId =
              Date.now().toString(36) + Math.random().toString(36).slice(2, 7)
            newRejectedDocuments.push({
              id: randomId,
              name: file.name,
              type: file.type,
              message: 'Cannot upload empty file.',
            })
          } else {
            uploadDocumentMutate({
              document: file,
            })
          }
        }
      }

      if (newRejectedDocuments.length > 0) {
        setRejectedDocuments((prev) => [...prev, ...newRejectedDocuments])
      }
    },
    [
      createErrorToast,
      uploadDocumentMutate,
      isSensitivityConfirmed,
      numExistingDocuments,
      countLimit,
      setRejectedDocuments,
    ],
  )

  const totalTokenLimitPercentage = (totalTokenCount / tokenLimit) * 100

  // Check if the calculated percentage is greater than 0 but less than 1
  const roundedTokenLimitPercentage =
    totalTokenLimitPercentage > 0 && totalTokenLimitPercentage < 1
      ? 1
      : Math.round(totalTokenLimitPercentage)

  const remainingTokenCount = tokenLimit - totalTokenCount
  const remainingWordCount = Math.round(
    remainingTokenCount * TOKENS_TO_WORDS_RATIO,
  )

  const isTokenLimitExceeded = totalTokenCount > tokenLimit
  // Note, we should never hit this point since we block uploading documents
  // above the limit, so this is just for DID
  const isDocumentCountLimitExceeded = numExistingDocuments > countLimit
  const isDocumentCountLimitHit = numExistingDocuments === countLimit

  const { mutate: deleteDocumentMutate } = useDeleteDocument()
  const onDeleteDocument = ({ documentId }: { documentId: string }) => {
    // Only permanently delete the document immediately if it's not within
    // the original document ids - for those we handle that in the backend
    // only upon submitting the form
    if (!_.includes(initialDocumentIdsRef.current, documentId)) {
      deleteDocumentMutate({ documentId })
    }
  }

  // Defer unchecking of the sensitivity confirmation to a modal if there
  // are already files uploaded.
  const {
    isOpen: isUncheckSensitivityModalOpen,
    onOpen: onUncheckSensitivityModalOpen,
    onClose: onUncheckSensitivityModalClose,
  } = useDisclosure()

  const onConfirmUncheck = () => {
    for (const documentId of uploadedDocumentIds) {
      onDeleteDocument({ documentId })
    }
    setUploadedDocumentIds([])
    onUncheckSensitivityModalClose()
    setIsSensitivityConfirmed(() => false)
  }

  let attachmentTooltipLabel = ''
  switch (true) {
    case isDocumentCountLimitHit:
      attachmentTooltipLabel =
        'You have uploaded the maximum number of documents.'
      break
    case !isSensitivityConfirmed:
      attachmentTooltipLabel =
        'To start uploading files, first check the box above.'
      break
  }

  return (
    <>
      <UncheckSensitivityModal
        isOpen={isUncheckSensitivityModalOpen}
        onClose={onUncheckSensitivityModalClose}
        onConfirm={onConfirmUncheck}
      />
      <VStack
        width="100%"
        padding="20px"
        backgroundColor="white"
        borderColor="base.divider.medium"
        borderRadius="8px"
        borderWidth="1px"
        spacing="8px"
        alignItems="start"
        {...props}
      >
        <Checkbox
          size="xs"
          onChange={() => {
            if (isSensitivityConfirmed && uploadedDocumentIds.length > 0) {
              onUncheckSensitivityModalOpen()
              return
            }
            setIsSensitivityConfirmed((currVal) => !currVal)
          }}
          isChecked={isSensitivityConfirmed}
        >
          <VStack alignItems="start" spacing="4px">
            <Text textStyle="body-2">
              I confirm that I will only upload documents up to{' '}
              <strong>Restricted / Sensitive Normal</strong> classification.
            </Text>
            <Text textStyle="caption-2" color="base.content.medium">
              This must be checked before you can upload documents below.
            </Text>
          </VStack>
        </Checkbox>
        <VStack
          width="100%"
          spacing="12px"
          align="stretch"
          boxSizing="content-box"
          textStyle="body-1"
        >
          <VStack spacing="8px" width="100%" alignItems="start">
            <Tooltip
              label={attachmentTooltipLabel}
              hasArrow
              placement={'top'}
              isDisabled={attachmentTooltipLabel.length === 0}
            >
              <Box position="relative" width="100%">
                <Attachment
                  isDisabled={
                    !isSensitivityConfirmed ||
                    isDocumentUploading ||
                    isDocumentCountLimitHit ||
                    isDocumentCountLimitExceeded ||
                    isDisabled
                  }
                  name="document-input"
                  multiple
                  maxSize={UPLOAD_DOCUMENT_MAX_SIZE_IN_BYTES}
                  value={[]}
                  accept={_.map(
                    SUPPORTED_MIME_TYPES,
                    (mimeTypeOption) => mimeTypeOption.extension,
                  )}
                  onChange={(files: File[]) => handleFileUpload(files)}
                  onRejection={(rejectedFiles) => {
                    const parsedRejectedFiles = _.map(
                      rejectedFiles,
                      (rejectedFile) => {
                        // Note, this does not have to be very robust, just serves
                        // as an identifier. Will not live anywhere permanently
                        // since it's just for rejected files.
                        const randomId = `rejected_${crypto.randomUUID()}`

                        const firstError = rejectedFile.errors[0]
                        const updatedMessage =
                          firstError.code === 'file-too-large'
                            ? `Failed to upload. This file exceeds the size limit. Please upload a file that is under ${UPLOAD_DOCUMENT_MAX_SIZE_IN_MEGABYTES} MB`
                            : firstError.message

                        return {
                          id: randomId,
                          name: rejectedFile.file.name,
                          type: rejectedFile.file.type,
                          message: updatedMessage,
                        }
                      },
                    )
                    setRejectedDocuments([
                      ...rejectedDocuments,
                      ...parsedRejectedFiles,
                    ])
                  }}
                />
                {/* Because we can't customize the Attachment component, this is.
                a somewhat hacky way of adding a loading state to the
                disabled Attachment - basically just cover with an overlay */}
                {isDocumentUploading && (
                  <VStack
                    position="absolute"
                    top="5px"
                    left="5px"
                    right="5px"
                    bottom="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="grey.100"
                  >
                    <Spinner size="lg" />
                    <Text textStyle="body-2">Uploading...</Text>
                  </VStack>
                )}
              </Box>
            </Tooltip>
            {isFailedUploadDueToDocumentLimit && (
              <Text textStyle="caption-2" color="interaction.critical.default">
                Upload exceeds document limits: {numExistingDocuments}/
                {countLimit} uploaded. You can add{' '}
                {countLimit - numExistingDocuments} more.
              </Text>
            )}
            {!isFailedUploadDueToDocumentLimit && (
              <Text textStyle="caption-2" color="base.content.medium">
                Upload up to {countLimit} files (maximum{' '}
                {MAX_DOCUMENTS_PER_UPLOAD_LIMIT} per upload). Supported formats:{' '}
                {SUPPORTED_MIME_TYPES.map(
                  (mimeTypeOption) => mimeTypeOption.label,
                ).join(', ')}
                .
              </Text>
            )}
          </VStack>
          {(uploadedDocumentIds.length > 0 || rejectedDocuments.length > 0) && (
            <VStack
              borderRadius="8px"
              borderWidth="1px"
              borderColor="base.divider.medium"
              padding="20px"
              alignItems="start"
              spacing="16px"
            >
              <VStack alignItems="start" spacing="0px" width="100%">
                <HStack>
                  <Box
                    color={
                      isTokenLimitExceeded
                        ? 'utility.feedback.critical'
                        : 'base.content.default'
                    }
                    paddingBottom="3px"
                  >
                    <Text textStyle="h5" as="span" letterSpacing="-0.08em">
                      {roundedTokenLimitPercentage}
                      {'% '}
                    </Text>
                    <Text as="span" textStyle="subhead-2" paddingLeft="3px">
                      of context limit used
                    </Text>
                  </Box>
                  <Tooltip
                    label={
                      <VStack spacing="16px">
                        <Text>
                          Context limit is the amount of text Pair can receive
                          as input when generating a response.
                        </Text>
                        <Text>
                          The total word count of the text extracted from your
                          documents must not exceed this context limit.
                        </Text>
                      </VStack>
                    }
                    hasArrow
                    placement={'right'}
                    maxWidth="220px"
                  >
                    <Box position="relative" paddingTop="8px">
                      <Icon
                        as={BiInfoCircle}
                        fontSize="18px"
                        color="base.content.medium"
                      />
                    </Box>
                  </Tooltip>
                </HStack>
                {isDocumentCountLimitExceeded && (
                  <HStack color="utility.feedback.critical">
                    <Icon as={BiSolidErrorCircle} />
                    <Text textStyle="body-2">
                      You have hit the document limit. To continue, please
                      reduce your documents to {countLimit} or fewer.
                    </Text>
                  </HStack>
                )}
                {!isDocumentCountLimitExceeded && isTokenLimitExceeded && (
                  <HStack color="utility.feedback.critical">
                    <Icon as={BiSolidErrorCircle} />
                    <Text textStyle="body-2">
                      You have exceeded the total word limit. Please reupload
                      files with less text.
                    </Text>
                  </HStack>
                )}
                {!isDocumentCountLimitExceeded && !isTokenLimitExceeded && (
                  <Text textStyle="caption-2" color="base.content.medium">
                    ~{remainingWordCount} words available
                  </Text>
                )}
              </VStack>

              <VStack width="100%" spacing="12px">
                {uploadedDocuments.map((document, index) => {
                  if (
                    document.status === 'failed' ||
                    document.status === 'cancelled'
                  ) {
                    return (
                      <ErrorDocumentItem
                        key={index}
                        errorDocument={{
                          id: document.id,
                          name: document.name,
                          type: document.mime_type,
                        }}
                        onDelete={(id: string) => {
                          setUploadedDocumentIds(
                            _.filter(
                              uploadedDocumentIds,
                              (uploadedDocumentId) => uploadedDocumentId !== id,
                            ),
                          )
                        }}
                      />
                    )
                  }
                  return (
                    <DocumentItem
                      key={index}
                      document={document}
                      tokenLimit={tokenLimit}
                      isDisabled={isDisabled}
                      onDelete={({ documentId }: { documentId: string }) => {
                        onDeleteDocument({ documentId })
                        setUploadedDocumentIds(
                          _.without(uploadedDocumentIds, documentId),
                        )
                      }}
                    />
                  )
                })}
              </VStack>

              {rejectedDocuments.length > 0 && (
                <VStack width="100%" spacing="12px" alignItems="start">
                  {rejectedDocuments.map((rejectedDocument, index) => (
                    // We handle the rejected documents separately since they never
                    // go to the front end.
                    <ErrorDocumentItem
                      key={index}
                      errorDocument={rejectedDocument}
                      onDelete={(id: string) => {
                        setRejectedDocuments(
                          _.filter(
                            rejectedDocuments,
                            (rejectedDocument) => rejectedDocument.id !== id,
                          ),
                        )
                      }}
                    />
                  ))}
                  rejectedDocuments
                </VStack>
              )}
            </VStack>
          )}
        </VStack>
      </VStack>
    </>
  )
}
