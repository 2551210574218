import { useQuery } from '@tanstack/react-query'

import { ListAssistantsResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

const listMostRecentlyUsedAssistants = async () => {
  return ApiService.get<ListAssistantsResponseDto>(
    '/assistants/recently-used',
  ).then((response) => {
    return response.data
  })
}

export const useRecentlyUsedAssistants = () => {
  return useQuery(
    queries.assistants.list._ctx.recent.queryKey,
    listMostRecentlyUsedAssistants,
    {
      select: (data) => data.assistants,
      refetchOnWindowFocus: false,
    },
  )
}
