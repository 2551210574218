import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const DraftCleanup = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.2159 10.5395C31.2761 9.61428 30.5782 8.81399 29.6534 8.7478L14.5767 7.6687C14.1293 7.63668 13.6876 7.78422 13.3492 8.07869L7.09123 13.5249C6.75197 13.8201 6.5447 14.2387 6.51548 14.6875L5.1627 35.4701C5.1022 36.3995 5.80659 37.202 6.73601 37.2625L27.6976 38.6269C28.627 38.6874 29.4295 37.983 29.49 37.0536L31.2159 10.5395Z"
      fill="#386EFE"
    />
    <path
      d="M11.293 13.5732L6.63903 13.8562C8.77681 11.8818 11.5789 9.42344 13.875 7.61816L13.1871 11.9608C13.125 12.9149 12.2765 13.6372 11.293 13.5732Z"
      fill="#1A2644"
    />
    <path
      d="M24.668 19.5C24.168 18.8333 23.168 17.4999 20.5586 18.9996C18.5571 20.1499 16.508 18.9561 15.3908 17.8551C15.1612 17.6288 14.9218 17.4053 14.622 17.2867C13.6618 16.9068 12.0816 16.7949 11.0586 18.4999"
      stroke="#B6D6F1"
      strokeLinecap="round"
    />
    <path
      d="M24.668 24.5C23.4265 24.5 19.5693 24.4243 18.2765 24.3982C17.9913 24.3924 17.7087 24.3516 17.4462 24.2399C16.6348 23.8944 15.9048 23.3203 15.3894 22.8037C15.1617 22.5755 14.9218 22.3526 14.622 22.234C13.6618 21.8541 12.0816 21.7422 11.0586 23.4472"
      stroke="#B6D6F1"
      strokeLinecap="round"
    />
    <path
      d="M10.6797 28.3662L24.2855 29.2298"
      stroke="#B6D6F1"
      strokeLinecap="round"
    />
    <path
      d="M27.806 29.6061C31.82 30.3028 33.3936 27.734 34.0776 23.7929C33.3936 27.734 34.2718 30.7283 37.6587 31.3161C34.2718 30.7283 32.2992 34.0397 31.6836 37.5867C32.2992 34.0397 31.82 30.3028 27.806 29.6061Z"
      fill="#B6D6F1"
    />
    <path
      d="M33.9821 36.0276C36.3906 36.4456 37.2526 35.3773 37.5458 33.6882C37.2526 35.3773 37.8616 36.7009 39.8938 37.0536C37.8616 36.7009 36.7836 38.0797 36.5198 39.5999C36.7836 38.0797 36.3906 36.4456 33.9821 36.0276Z"
      fill="#B6D6F1"
    />
    <path
      d="M25.1713 25.4513C25.1264 25.1528 25.0495 24.8653 24.945 24.5927C24.8022 24.2182 24.6066 23.8703 24.36 23.5563L23.7344 24.4547C23.4119 24.918 23.5764 25.414 24.0565 25.4258L25.1692 25.453L25.1713 25.4513Z"
      fill="#1A2644"
    />
    <path
      d="M26.5503 20.4144L26.5996 20.3881C29.9899 20.6903 29.6855 23.8127 29.0769 25.3523L28.9455 25.4398L28.7669 25.5297L25.1807 25.4396C25.134 25.1413 25.0589 24.8536 24.9544 24.581C24.8098 24.2067 24.616 23.8586 24.3694 23.5446L26.5503 20.4144Z"
      fill="white"
    />
    <path
      d="M45.4703 16.4999L28.8679 25.464L28.8662 25.4642C28.6228 24.7524 27.8287 24.5156 27.8287 24.5156C27.8287 24.5156 28.4916 23.5205 28.1166 22.5405C27.7416 21.5606 26.4946 21.7658 26.4946 21.7658C26.4946 21.7658 26.8262 20.814 26.6083 20.3475L43.2046 11.3871C44.498 11.8217 46.7619 13.4527 45.4703 16.4999Z"
      fill="#1A2644"
    />
  </svg>
))
