import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Card, CardBody, Text, VStack } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import {
  DotLottie,
  DotLottieReact,
  setWasmUrl,
} from '@lottiefiles/dotlottie-react'

import { generateAssistantPathFromId } from '~features/assistants'

setWasmUrl(`${window.location.origin}/lottiefiles/dotlottie-player.wasm`)

type AssistantPromoCardProps = {
  title: string
  description: string
  lottieAnimationSrc: string
  id: string
}

export const AssistantPromoCard = ({
  title,
  description,
  lottieAnimationSrc,
  id,
}: AssistantPromoCardProps) => {
  const [dotLottie, setDotLottie] = useState<DotLottie | null>(null)

  return (
    <Card
      maxWidth="164px"
      borderRadius="8px"
      borderColor="base.divider.medium"
      onMouseEnter={() => {
        dotLottie?.setMode('forward')
        dotLottie?.play()
      }}
      onMouseLeave={() => {
        dotLottie?.setMode('reverse')
        dotLottie?.play()
      }}
      as={Link}
      to={generateAssistantPathFromId(id)}
      onClick={() =>
        datadogRum.addAction('click_assistant_promo_card', {
          assistant_id: id,
        })
      }
      height="100%"
    >
      <CardBody padding="12px">
        <VStack spacing="8px">
          <Box overflow="hidden" borderRadius="8px">
            <DotLottieReact
              style={{
                // maintain square aspect ratio to ensure that the component has consistent border radius
                aspectRatio: '1/1',
              }}
              src={lottieAnimationSrc}
              // Hover behaviour is self-managed via callbacks to the player
              playOnHover={false}
              dotLottieRefCallback={(ref) => setDotLottie(ref)}
            />
          </Box>
          <VStack spacing="2px" alignItems="flex-start">
            <Text textStyle="caption-1" color="base.content.strong">
              {title}
            </Text>
            <Text textStyle="legal" color="base.content.medium">
              {description}
            </Text>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  )
}
