import {
  Button,
  HStack,
  Link,
  LinkProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

export const PopoutLink = (linkProps: LinkProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Link onClick={onOpen}>{linkProps.children}</Link>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent paddingX="8px" paddingY="32px">
          <ModalBody>
            <VStack spacing="16px" alignItems="flex-start">
              <VStack spacing="8px" alignItems="flex-start">
                <Text textStyle="h5">Open a link from the web</Text>
                <Text textStyle="body-1">
                  You are about to be navigated to{' '}
                  <strong>{linkProps.href}</strong>. Please double check the
                  link before continuing.
                </Text>
              </VStack>

              <HStack alignSelf="flex-end">
                <Button size="sm" onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  as="a"
                  target="__blank"
                  href={linkProps.href}
                  rel="noopener noreferrer"
                  size="sm"
                  color="white"
                >
                  Continue
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
