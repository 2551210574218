import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { VerifyOtpRequestDto, WhoAmIResponseDto } from '~shared/types/auth.dto'

import { useBrowserId } from '~/store'

import {
  fetchUser,
  logout as logoutApi,
  sendLoginOtp,
  verifyLoginOtp as verifyLoginOtpApi,
} from '~features/auth'

import { getDevicePixelRatio } from '../utils/user-agent'

import { isAuthenticationError } from './api'
import {
  startRumSessionMonitoring,
  stopRumSessionMonitoring,
} from './monitoring'

type AuthContextProps = {
  isAuthenticated?: boolean
  user?: WhoAmIResponseDto | null
  isLoading: boolean
  sendLoginOtp: typeof sendLoginOtp
  verifyLoginOtp: (params: VerifyOtpRequestDto) => Promise<void>
  refetchUser: () => void
  logout: () => void
}
// TODO: Split up auth actions into separate hooks

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

/**
 * Provider component that wraps your app and makes auth object available to any
 * child component that calls `useAuth()`.
 */
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

/**
 * Hook for components nested in ProvideAuth component to get the current auth object.
 */
export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider component`)
  }
  return context
}

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const browserId = useBrowserId()
  const queryClient = useQueryClient()

  // Login check will run on first visit to root page
  // TODO: Figure out a clean way to deal with this
  const [isLoggedInCheckRunning, setIsLoginCheckRunning] = useState(true)

  const {
    data: user,
    isInitialLoading,
    refetch,
  } = useQuery(
    ['currentUser'],
    fetchUser,
    // 1 minutes staletime, do not need to retrieve so often.
    {
      staleTime: 60_000,
      enabled: isLoggedInCheckRunning,
      retry: (_failureCount, err) => {
        // Allow for retries if not authentication error
        return !isAuthenticationError(err)
      },
      onError: (err) => {
        if (isAuthenticationError(err)) {
          stopRumSessionMonitoring()

          // Disable query from running
          setIsLoginCheckRunning(false)
        }
      },

      onSuccess: (user) => {
        startRumSessionMonitoring({
          id: user.id.toString(),
          email: user.email,
          // Only retrieve deviceRatio at login time for consistency
          pixelRatioPreference: getDevicePixelRatio().toString(),
          browserId,
        })

        setIsLoginCheckRunning(true)
      },
    },
  )

  const verifyLoginOtp = useCallback(
    async (params: VerifyOtpRequestDto) => {
      await queryClient.invalidateQueries(['currentUser'])
      await verifyLoginOtpApi(params)
      await refetch()
    },
    [queryClient, refetch],
  )

  const logout = useCallback(async () => {
    const logoutData = await logoutApi()

    // Ensure subsequent login has to approve Terms of Usage again
    // TODO: Move TOU acceptance validation to a backend call

    // Proceed to do an azure logout for WOG users
    // NOTE: This causes a slight visual glitch where TOU appears for a brief period before redirect
    // Can be fixed by maintaining another non-persisting state, but not worth the effort.
    if (logoutData.session_auth_type === 'wog_ad') {
      window.location.href = logoutData.logout_url
      return
    }

    // Stop RUM session
    stopRumSessionMonitoring()

    // Prevent query from unnecessary fetching when in root page
    setIsLoginCheckRunning(false)

    queryClient.clear()
  }, [queryClient])

  // Return the user object and auth methods
  return {
    isAuthenticated: !!user,
    user: user,
    // This fixes against an edge case where is loading is true when clearing cache
    isLoading: isInitialLoading,
    sendLoginOtp,
    verifyLoginOtp,
    refetchUser: refetch,
    logout,
  }
}
