import { useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { ThemeProvider } from '@opengovsg/design-system-react'
import { MotionConfig } from 'framer-motion'

import { theme } from '~/theme'

type AppThemeProviderProps = {
  children: React.ReactNode
}

export const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  // Manually set toast container ref to ensure its placed above modals
  // https://github.com/chakra-ui/chakra-ui/issues/6276
  const toastContainerRef = useRef<HTMLDivElement | null>(null)

  return (
    <ThemeProvider
      theme={theme}
      toastOptions={{
        portalProps: { containerRef: toastContainerRef },
      }}
      resetCSS
    >
      {/* Reduce motion from framer-motion for JS animations in SIS/GSIB */}
      <MotionConfig reducedMotion="always">
        {children}
        <Box id="toast-container" ref={toastContainerRef} />
      </MotionConfig>
    </ThemeProvider>
  )
}
