import {
  SendLoginOtpRequestDto,
  SendLoginOtpResponseDto,
} from '~shared/types/auth.dto'

import { ApiService } from '~lib/api'

export const sendLoginOtp = async (params: SendLoginOtpRequestDto) => {
  params.email = params.email.toLowerCase()
  return ApiService.post<SendLoginOtpResponseDto>('/auth', params).then(
    (response) => response.data,
  )
}
