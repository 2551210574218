import { SimpleGrid } from '@chakra-ui/react'

import { ChatInputType } from '~features/chatv2/types'

import { PromptCard } from '../Prompts/PromptCard'

type PromptHighlightsProps = {
  onSelect: (content: ChatInputType['text']) => void
  promptStarters: string[]
}

export const AssistantPromptHighlights = ({
  onSelect,
  promptStarters,
}: PromptHighlightsProps) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing="12px"
      justifyContent="center"
      padding="16px"
      width="80%"
    >
      {promptStarters.map((promptStarter, idx) => (
        <PromptCard
          key={idx}
          title={promptStarter}
          onClick={() => onSelect(promptStarter)}
        />
      ))}
    </SimpleGrid>
  )
}
