import { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { GetAssistantResponseDto } from '~shared/types/assistants.dto'

import { routes } from '~constants/routes'

import { useAssistantPreviewContext } from '~features/assistants/hooks/useAssistantPreviewContext'
import { useChat } from '~features/chatv2'

export const useAssistantPreview = ({
  assistant,
}: {
  assistant?: GetAssistantResponseDto
}) => {
  const navigate = useNavigate()
  const {
    setIsUpdatingPreview,
    setPreviewChatHistory,
    setCurrentChatId,
    state: { currentChatId, previewChatHistory },
  } = useAssistantPreviewContext(assistant?.id)

  // TODO: This is just for state management, deprecate when we refactor
  // useChat to handle this automatically
  const previewSessionId = useRef(crypto.randomUUID()).current
  const chatStateAndMutation = useChat({
    id: currentChatId,
    onLoadChatError: (err) => {
      if (err.response?.status === 404) {
        navigate(routes.assistants)
      }
    },
    placeholderIdPrefix: `previews-${previewSessionId}-`,
    setIdOnCreate: setCurrentChatId,
    ...(currentChatId ? null : { assistantId: assistant?.id }),
  })
  const {
    isStreaming,
    isMutationLoading,
    isChatLoading,
    messages: currentMessages,
  } = chatStateAndMutation
  const isChatMutating = isStreaming || isMutationLoading || isChatLoading

  const refreshPreviewChat = useCallback(() => {
    if (currentChatId && currentMessages.length > 0) {
      setPreviewChatHistory([
        ...previewChatHistory,
        {
          id: currentChatId,
          messages: currentMessages,
        },
      ])
    }
    setIsUpdatingPreview(true)
    setCurrentChatId(undefined)
    setTimeout(() => setIsUpdatingPreview(false), 1000)
  }, [
    currentChatId,
    currentMessages,
    previewChatHistory,
    setPreviewChatHistory,
    setIsUpdatingPreview,
    setCurrentChatId,
  ])

  const debouncedRefreshPreviewChat = useMemo(
    () => _.debounce(refreshPreviewChat, 500),
    [refreshPreviewChat],
  )

  return {
    chatStateAndMutation,
    isChatMutating,
    debouncedRefreshPreviewChat,
    currentChatId,
    previewChatHistory,
    setCurrentChatId,
    setPreviewChatHistory,
  }
}
