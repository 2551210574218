import {
  CreateAssistantFeedbackRequestDto,
  CreateAssistantFeedbackResponseDto,
} from '~shared/types/assistant-feedback.dto'
import {
  ChatHistoryResponseDto,
  DeleteChatResponseDto,
  ListPreviewChatsResponseDto,
  UpdateChatTitleRequestDto,
  UpdateChatTitleResponseDto,
} from '~shared/types/chats.dto'
import {
  CreateFeedbackRequestDto,
  CreateFeedbackResponseDto,
} from '~shared/types/feedbacks.dto'

import { ApiService } from '~lib/api'

export const getFullChatHistory = async (chatId: string) => {
  return ApiService.get<ChatHistoryResponseDto>(`/chats/${chatId}`).then(
    (response) => response.data,
  )
}

export const deleteChat = async (chatId: string) => {
  return ApiService.delete<DeleteChatResponseDto>(`/chats/${chatId}`).then(
    (response) => response.data,
  )
}

export const updateChatTitle = async ({
  chatId,
  data,
}: {
  chatId: string
  data: UpdateChatTitleRequestDto
}) => {
  return ApiService.patch<UpdateChatTitleResponseDto>(
    `/chats/${chatId}`,
    data,
  ).then((response) => response.data)
}

export const createFeedback = async (data: CreateFeedbackRequestDto) => {
  return ApiService.post<CreateFeedbackResponseDto>('/feedbacks', data).then(
    (response) => response.data,
  )
}

export const getAssistantPreviewChatHistory = async (assistantId: string) => {
  return ApiService.get<ListPreviewChatsResponseDto>(
    `/preview/chats/${assistantId}`,
  ).then((response) => response.data)
}

export const createAssistantFeedback = async ({
  assistantId,
  ...data
}: {
  assistantId: string
} & CreateAssistantFeedbackRequestDto) => {
  return ApiService.post<CreateAssistantFeedbackResponseDto>(
    `assistants/${assistantId}/feedbacks`,
    data,
  ).then((response) => response.data)
}
