import { Text, VStack } from '@chakra-ui/react'

import { SharedAssistantDto } from '~shared/types/assistants.dto'

import { AssistantAccessTag, AssistantAvatar } from '~features/assistants'

type CreateAssistantChatWindowProps = {
  assistant: SharedAssistantDto
}

export const AssistantInfoDisplay = ({
  assistant,
}: CreateAssistantChatWindowProps) => {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      spacing="8px"
      flexGrow={1}
      paddingX={{ base: '36px', sm: '60px', md: '100px', lg: '120px' }}
      maxWidth="800px"
    >
      <AssistantAvatar avatarSize="md" />
      <Text textStyle="h5" color="base.content.strong" textAlign="center">
        {assistant.name}
      </Text>
      <AssistantAccessTag access={assistant.view_access} />
      <Text textStyle="body-2" color="base.content.default" textAlign="center">
        {assistant.description}
      </Text>
      <Text
        textStyle="caption-1"
        color="base.content.medium"
        textAlign="center"
      >
        By {assistant.created_by}
      </Text>
    </VStack>
  )
}
