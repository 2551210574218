import { Box, Text } from '@chakra-ui/react'
import { Banner } from '@opengovsg/design-system-react'
import { useVariant } from '@unleash/proxy-client-react'
import { z } from 'zod'

const featureFlagBannerPayloadSchema = z.object({
  type: z.enum(['info', 'error', 'warn']),
  title: z.string().nullable(),
  content: z.string(),
  isDismissable: z.boolean().optional(),
})

type FeatureFlagBannerProps = {
  // TODO: Add more variants as and when required
  variant: 'public-banner' | 'private-banner'
}

export const FeatureFlagBanner = ({ variant }: FeatureFlagBannerProps) => {
  const { feature_enabled, payload } = useVariant(variant)

  // In the case where not enabled or has no payload, do not show banner
  if (!feature_enabled || !payload) {
    return null
  }

  const result = featureFlagBannerPayloadSchema.safeParse(
    JSON.parse(payload.value),
  )
  if (!result.success) {
    return null
  }

  const { isDismissable, type, title, content } = result.data

  // NOTE: There is something wrong with the design system banner that it gets flex shrunk on mobile view.
  return (
    <Box flexShrink={0}>
      <Banner isDismissable={isDismissable} variant={type}>
        <Text variant="bodyLight">
          {title && <b>{title}&nbsp;</b>}
          {content}
        </Text>
      </Banner>
    </Box>
  )
}
