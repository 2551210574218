import { BiSolidMessageRoundedAdd, BiX } from 'react-icons/bi'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  IconButton,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'

import PairLogo from '~/assets/pair-logo-white.svg'

import { routes } from '~constants/routes'

interface SidebarHeaderProps {
  isDrawer?: boolean
  onClickCreate: () => void
  onClickCloseSidebar?: () => void
}

/**
 * Header for showing logo + creation button
 */
export const SidebarHeader = ({
  isDrawer = false,
  onClickCreate,
  onClickCloseSidebar,
}: SidebarHeaderProps): JSX.Element => {
  const location = useLocation()
  return (
    <VStack
      alignItems="start"
      spacing="4px"
      paddingX="0px"
      paddingTop="4px"
      justifyContent="space-between"
    >
      {isDrawer && (
        <Box position="absolute" left="100%" top="10px">
          <IconButton
            width="fit-content"
            marginLeft="20px"
            size="sm"
            onClick={onClickCloseSidebar}
            icon={<BiX />}
            aria-label="New chat"
            backgroundColor="transparent"
            color="white"
            borderColor="white"
            _hover={{
              backgroundColor: 'interaction.tinted.inverse.hover',
            }}
          />
        </Box>
      )}

      <Box paddingLeft="8px" paddingY="10px">
        <Link as={RouterLink} to={routes.chat}>
          <Image
            src={PairLogo}
            height="16px"
            alt="Pair Logo"
            cursor="pointer"
          />
        </Link>
      </Box>

      <Button
        leftIcon={<BiSolidMessageRoundedAdd fontSize="20px" />}
        size="sm"
        color="base.content.inverse"
        fontSize="16px"
        aria-label="New Chat"
        // To make sure this selected state does not match against /chat?asst="loremipsum"
        aria-selected={location.pathname === routes.chat && !location.search}
        variant="clear"
        onClick={onClickCreate}
        alignItems="center"
        justifyContent="start"
        width="100%"
        paddingLeft="10px"
        _hover={{
          backgroundColor: 'interaction.tinted.inverse.hover',
        }}
        _active={{
          backgroundColor: 'interaction.tinted.inverse.active',
        }}
        _selected={{
          backgroundColor: 'interaction.tinted.inverse.active',
        }}
      >
        <Text textStyle="body-1">New chat</Text>
      </Button>
    </VStack>
  )
}
