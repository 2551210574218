export const DEFAULT_ASSISTANT_PLACEHOLDER_PROMPT = `You are a [ROLE] expert whose primary goal is to [MAIN OBJECTIVE].

Follow these steps when responding to queries:
[STEP 1]
[STEP 2] [Add more steps as needed]

Format your output as follows:
**[BOLDED HEADER]**
- Key point 1
- Key point 2 [Add more points as needed, or specify if bullet points should not be used]`
