import { BiLogOutCircle } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import {
  Button,
  ButtonProps,
  Flex,
  forwardRef,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
} from '@chakra-ui/react'

import { UnanimatedMenuList } from '~components/UnanimatedComponents'

import { SidebarFooter } from '../SidebarFooter'

import { SidebarNavigationMenuItem } from './SidebarNavigationMenuItem'

type SidebarNavigationMenuProps = {
  onLogout: () => void
}

const FooterButton = forwardRef<ButtonProps, 'div'>((props, ref) => {
  return (
    <Flex paddingX="8px" ref={ref}>
      <Button
        alignItems="center"
        justifyContent="stretch"
        leftIcon={<SidebarFooter />}
        rightIcon={<Icon as={BsThreeDots} />}
        background="transparent"
        paddingX="16px"
        height="52px"
        color="white"
        border="none"
        borderRadius="4px"
        {...props}
      />
    </Flex>
  )
})

export const SidebarNavigationMenu = ({
  onLogout,
}: SidebarNavigationMenuProps) => {
  return (
    <Menu placement="top" matchWidth={true}>
      <MenuButton
        as={FooterButton}
        width="100%"
        _active={{ backgroundColor: 'interaction.sub.default' }}
        _hover={{ backgroundColor: 'interaction.sub.default' }}
      />
      <UnanimatedMenuList borderRadius="4px" marginX="6px">
        <MenuDivider />
        <SidebarNavigationMenuItem
          text="Log out"
          leftIcon={BiLogOutCircle}
          onClick={onLogout}
        />
      </UnanimatedMenuList>
    </Menu>
  )
}
