import { DefaultOptions, QueryClient } from '@tanstack/react-query'

import { isAuthenticationError, isClientError } from './api'

// Adds a default error handler scenario for
const globalErrorHandler = (err: unknown) => {
  if (isAuthenticationError(err)) {
    // Automatically sign out on 401s
    queryClient.setQueryData(['currentUser'], null)
  }
}

const queryConfig: DefaultOptions = {
  queries: {
    onError: globalErrorHandler,
    retry: (failureCount, error) => {
      // Don't retry for client errors
      if (isClientError(error)) {
        return false
      }
      return failureCount < 3
    },
  },
  mutations: {
    onError: globalErrorHandler,
  },
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })
