/**
 * Do not edit directly
 * Generated on Fri, 17 Mar 2023 09:07:38 GMT
 */

export const colours = {
  blue: {
    '50': '#F4F8FE',
    '75': '#E6F0FF',
    '100': '#CCE0FC',
    '200': '#A7C9F9',
    '300': '#7CAFF7',
    '400': '#4E93F4',
    '500': '#1F76F1',
    '600': '#065EDB',
    '700': '#054DB4',
    '800': '#043D8F',
    '900': '#022B63',
    '1000': '#1A2644',
  },
  brand: {
    primary: {
      '50': '#F4F8FE',
      '75': '#E6F0FF',
      '100': '#CCE0FC',
      '200': '#A7C9F9',
      '300': '#7CAFF7',
      '400': '#4E93F4',
      '500': '#1F76F1',
      '600': '#065EDB',
      '700': '#054DB4',
      '800': '#043D8F',
      '900': '#022B63',
      '1000': '#1A2644',
    },
    secondary: {
      '50': '#f8f9fa',
      '100': '#e9eaee',
      '200': '#babecb',
      '300': '#9aa0b3',
      '400': '#7b849c',
      '500': '#5d6785',
      '600': '#465173',
      '700': '#3c4764',
      '800': '#333c56',
      '900': '#272d41',
    },
  },
  base: {
    canvas: {
      alt: '#f8f9fa',
      backdrop: '#e9eaee',
      inverse: '#3c4764',
      overlay: 'rgba(60, 71, 100, 0.8)',
      default: '#ffffff',
      'brand-subtle': '#F4F8FE',
      'alt-light': '#F4F4F4',
      'brand-light': '#E6F0FF',
    },
    content: {
      default: '#454953',
      strong: '#2C2E34',
      medium: '#666C7A',
      brand: '#1F76F1',
      inverse: '#ffffff',
    },
    divider: {
      default: '#E0E0E0',
      subtle: '#F8F9F9',
      medium: '#EDEDED',
      strong: '#BFC2C8',
      inverse: '#ffffff',
      brand: '#1F76F1',
    },
  },
  interaction: {
    links: {
      'neutral-default': '#3c4764',
      'neutral-hover': '#272d41',
      default: '#1F76F1',
      hover: '#065EDB',
      'inverse-default': '#ffffff',
      'inverse-hover': '#EDEDED',
    },
    main: {
      default: '#1F76F1',
      hover: '#065EDB',
      active: '#054DB4',
    },
    'main-subtle': {
      default: '#CCE0FC',
      hover: '#A7C9F9',
      active: '#7CAFF7',
    },
    sub: {
      default: '#5d6785',
      hover: '#465173',
      active: '#3c4764',
    },
    'sub-subtle': {
      default: '#e9eaee',
      hover: '#babecb',
      active: '#9aa0b3',
    },
    critical: {
      default: '#c03434',
      hover: '#992a2a',
      active: '#852424',
    },
    'critical-subtle': {
      default: '#FBE9E9',
      hover: '#f4acac',
      active: '#ee8080',
    },
    warning: {
      default: '#FFDA68',
      hover: '#E2B73E',
      active: '#C4992A',
    },
    'warning-subtle': {
      default: '#fffae1',
      hover: '#FFDA68',
      active: '#E2B73E',
    },
    success: {
      default: '#0F796F',
      hover: '#0C5F57',
      active: '#0A524B',
    },
    'success-subtle': {
      default: '#E2EEED',
      hover: '#98CCC7',
      active: '#63B1AA',
    },
    neutral: {
      default: '#666C7A',
      hover: '#505660',
      active: '#454953',
    },
    'neutral-subtle': {
      default: '#F8F9F9',
      hover: '#EDEDED',
      active: '#BFC2C8',
    },
    muted: {
      main: {
        hover: '#F4F8FE',
        active: '#CCE0FC',
      },
      sub: {
        hover: '#f8f9fa',
        active: '#e9eaee',
      },
      critical: {
        hover: '#fef7f7',
        active: '#FBE9E9',
      },
      neutral: {
        hover: '#F8F9F9',
        active: '#EDEDED',
      },
    },
    tinted: {
      main: {
        hover: 'rgba(31, 118, 241, 0.04)',
        active: 'rgba(31, 118, 241, 0.12)',
      },
      sub: {
        hover: 'rgba(93, 103, 133, 0.04)',
        active: 'rgba(93, 103, 133, 0.12)',
      },
      critical: {
        hover: 'rgba(192, 52, 52, 0.04)',
        active: 'rgba(192, 52, 52, 0.12)',
      },
      neutral: {
        hover: 'rgba(0, 0, 0, 0.1)',
        active: 'rgba(0, 0, 0, 0.2)',
      },
      inverse: {
        hover: 'rgba(255, 255, 255, 0.1)',
        active: 'rgba(255, 255, 255, 0.2)',
      },
    },
    support: {
      unselected: '#A0A4AD',
      selected: '#666C7A',
      'unselected-strong': '#666C7A',
      disabled: '#EDEDED',
      'disabled-content': '#A0A4AD',
      placeholder: '#A0A4AD',
    },
  },
  red: {
    '50': '#fef7f7',
    '100': '#FBE9E9',
    '200': '#f4acac',
    '300': '#ee8080',
    '400': '#e74f4f',
    '500': '#c03434',
    '600': '#992a2a',
    '700': '#852424',
    '800': '#721f1f',
    '900': '#571717',
  },
  green: {
    '50': '#F5FAFA',
    '100': '#E2EEED',
    '200': '#98CCC7',
    '300': '#63B1AA',
    '400': '#2D978C',
    '500': '#0F796F',
    '600': '#0C5F57',
    '700': '#0A524B',
    '800': '#09463F',
    '900': '#073430',
  },
  yellow: {
    '50': '#fffae1',
    '100': '#FFDA68',
    '200': '#E2B73E',
    '300': '#C4992A',
    '400': '#A77C18',
    '500': '#8B6005',
    '600': '#704C00',
    '700': '#624200',
    '800': '#533800',
    '900': '#3f2b00',
  },
  slate: {
    '50': '#f8f9fa',
    '100': '#e9eaee',
    '200': '#babecb',
    '300': '#9aa0b3',
    '400': '#7b849c',
    '500': '#5d6785',
    '600': '#465173',
    '700': '#3c4764',
    '800': '#333c56',
    '900': '#272d41',
  },
  grey: {
    '50': '#F8F9F9',
    '75': '#F4F4F4',
    '100': '#EDEDED',
    '150': '#E0E0E0',
    '200': '#BFC2C8',
    '300': '#A0A4AD',
    '400': '#838894',
    '500': '#666C7A',
    '600': '#505660',
    '700': '#454953',
    '800': '#3A3E46',
    '900': '#2C2E34',
  },
  standard: {
    white: '#ffffff',
    black: '#000000',
  },
  skin: {
    '1base': '#FFCAA4',
    '1shadow': '#F5B896',
    '2base': '#EBA687',
    '2shadow': '#E29B7B',
    '3base': '#D98F6F',
    '3shadow': '#CE8361',
    '4base': '#BF7657',
    '4shadow': '#AF6D53',
    '5base': '#9F634F',
    '5shadow': '#8B5544',
    '6base': '#764738',
    '6shadow': '#633D32',
  },
  utility: {
    feedback: {
      info: '#1F76F1',
      'info-subtle': '#F4F8FE',
      warning: '#FFDA68',
      'warning-subtle': '#fffae1',
      success: '#0F796F',
      'success-subtle': '#F5FAFA',
      critical: '#c03434',
      'critical-subtle': '#fef7f7',
    },
    'focus-default': '#1F76F1',
    'focus-inverse': '#ffffff',
    'input-prefilled': '#fffae1',
    ui: '#ffffff',
    'ui-clear': 'rgba(255, 255, 255, 0)',
  },
}
