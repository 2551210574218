import { BiGlobe, BiLink, BiLock, BiPencil } from 'react-icons/bi'
import { Tag, TagLabel, TagLeftIcon } from '@opengovsg/design-system-react'

import { ViewAccessType } from '~shared/types/assistants.dto'

type AssistantAccessTagProps = {
  access: ViewAccessType
}

const ViewAccessTags = {
  private: {
    label: 'Only me',
    icon: BiLock,
    bgColor: 'interaction.sub-subtle.default',
    color: 'interaction.sub.default',
  },
  agency: {
    label: 'Only my agency',
    icon: BiLink,
    bgColor: 'interaction.main-subtle.default',
    color: 'interaction.main.default',
  },
  custom: {
    label: 'Specific agencies',
    icon: BiLink,
    bgColor: 'interaction.main-subtle.default',
    color: 'interaction.main.default',
  },
  public: {
    label: 'All Pair users',
    icon: BiGlobe,
    bgColor: 'interaction.success-subtle.default',
    color: 'interaction.success.default',
  },
  draft: {
    label: 'Draft',
    icon: BiPencil,
    bgColor: 'utility.feedback.warning-subtle',
    color: 'yellow.500',
  },
} as const

export const AssistantAccessTag = ({ access }: AssistantAccessTagProps) => {
  return (
    <Tag
      size="xs"
      variant="subtle"
      borderRadius="full"
      bgColor={ViewAccessTags[access].bgColor}
      color={ViewAccessTags[access].color}
      _hover={{}}
    >
      <TagLeftIcon boxSize="12px" as={ViewAccessTags[access].icon} />
      <TagLabel>{ViewAccessTags[access].label}</TagLabel>
    </Tag>
  )
}
