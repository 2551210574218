import { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form'
import { BiSolidHelpCircle, BiUpload, BiX } from 'react-icons/bi'
import {
  Box,
  FormControl,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Textarea,
} from '@opengovsg/design-system-react'
import _ from 'lodash'

import { MAX_PROMPT_STARTERS_SIZE } from '~shared/constants/assistants'
import {
  ASSISTANT_DOCUMENTS_CONTEXT_LIMIT,
  ASSISTANT_DOCUMENTS_COUNT_LIMIT,
} from '~shared/constants/documents'
import { RiskCategoryType, ViewAccessType } from '~shared/types/assistants.dto'
import { isCustomDomainSharingEnabled } from '~shared/utils/emails'

import { useAuth } from '~lib/auth'
import { useTrackedFlag } from '~lib/feature-flag'
import { PAIR_PROMPT_GUIDES_URL, PROMPT_ASSISTANT_URL } from '~constants/config'
import { InputFieldWithIconButton } from '~components/FormComponents/InputFieldWithIconButton'

import { AssistantInputType } from '~features/assistants/schema/assistant-schema'
import { DocumentTag } from '~features/chatv2/components/DocumentTag'
import { DocumentsUploadModal } from '~features/documents/components/DocumentsUploadModal'
import { useGetDocuments } from '~features/documents/hooks'

import { Radio } from '../../../../components/ui/Radio/Radio'
import { AssistantAccessTag } from '../AssistantAccessTag'

import { APPROVAL_CONSENT_MESSAGE } from './constants/warning-messages'
import { AssistantDomainSharingSelector } from './AssistantDomainSharingSelector'
import { AssistantRiskAcceptanceSelector } from './AssistantRiskAcceptanceSelector'
/**
 * TODO: Implement proper theming of formControl and other form components to avoid repetition
 * @date 01/12/2023 - 12:59:16
 */

// NOTE: This exists to reduce likeliness of id clashes
function generateFormElementId(name: string) {
  return `asst_update_form_${name}`
}

interface AssistantFormProps {
  assistant?: {
    name: string
    description: string
    prompt: string
    prompt_starters: string[]
    view_access: ViewAccessType
    document_ids: string[]
    access_patterns: string[]
    risk_category: RiskCategoryType | null
  }
  // passed in as a prop to support using form values outside of form (e.g. publishing)
  formMethods: UseFormReturn<AssistantInputType>
  uploadedDocumentIds: string[]
  setUploadedDocumentIds: Dispatch<SetStateAction<string[]>>
  onBehaviorUpdate?: () => void
  isInputDisabled?: boolean
}

export const AssistantForm = ({
  assistant,
  formMethods,
  uploadedDocumentIds,
  setUploadedDocumentIds,
  isInputDisabled,
  onBehaviorUpdate,
}: AssistantFormProps): JSX.Element => {
  const { user } = useAuth()
  if (!user?.email) {
    throw new Error('Unexpected user error has occurred')
  }
  const { isEnabled: isDocumentsEnabled } = useTrackedFlag('documents_enable')

  const {
    register,
    formState: { errors },
    control,
    getValues,
  } = formMethods

  const {
    isOpen: isDocumentUploadModalOpen,
    onClose: onDocumentUploadModalClose,
    onOpen: onDocumentUploadModalOpen,
  } = useDisclosure()

  // NOTE: RHF's useFieldArray does not support flat arrays
  // This is why prompt_starters have a structure of [{value: string}]
  const { fields, append, remove } = useFieldArray({
    name: 'prompt_starters',
    control,
  })

  // TODO: Refactor to utilise refs and shouldFocusError https://react-hook-form.com/docs/useform#shouldFocusError
  // This handles cases where ref does not exist
  useEffect(() => {
    const firstError = Object.keys(formMethods.formState.errors)[0]

    if (firstError) {
      const errorElement = document.getElementById(
        generateFormElementId(firstError),
      )
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [formMethods.formState.errors])

  const documentTokenLimit = ASSISTANT_DOCUMENTS_CONTEXT_LIMIT
  const documentCountLimit = ASSISTANT_DOCUMENTS_COUNT_LIMIT
  const { completedDocuments } = useGetDocuments({
    documentIds: uploadedDocumentIds,
    tokenLimit: documentTokenLimit,
    countLimit: documentCountLimit,
  })

  const onDeleteDocument = ({ documentId }: { documentId: string }) => {
    setUploadedDocumentIds(_.without(uploadedDocumentIds, documentId))
  }

  return (
    <FormProvider {...formMethods}>
      <VStack
        as="form"
        align="stretch"
        width="100%"
        boxSizing="content-box"
        spacing="24px"
      >
        {isDocumentsEnabled && (
          <DocumentsUploadModal
            isOpen={isDocumentUploadModalOpen}
            onClose={onDocumentUploadModalClose}
            uploadedDocumentIds={uploadedDocumentIds}
            setUploadedDocumentIds={setUploadedDocumentIds}
            countLimit={documentCountLimit}
            tokenLimit={documentTokenLimit}
            // If they've already uploaded documents, just auto click for them
            defaultSensitivityAcknowledged={uploadedDocumentIds.length > 0}
            returnFocusOnClose={false}
            submitButtonLabel="Update reference documents"
          />
        )}
        <HStack spacing="16px" alignItems="center">
          <Text textStyle="h5">
            {assistant ? 'Edit' : 'Create new'} Assistant
          </Text>
          {assistant && <AssistantAccessTag access={assistant.view_access} />}
        </HStack>
        <FormControl
          as={VStack}
          spacing="8px"
          alignItems="stretch"
          isInvalid={!!errors.name}
          isRequired
        >
          <FormLabel marginBottom={0}>Name</FormLabel>
          <Input
            placeholder="Name your assistant"
            {...register('name', {
              onChange: () => {
                onBehaviorUpdate?.()
              },
            })}
            size="sm"
            isDisabled={!!isInputDisabled}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          as={VStack}
          spacing="8px"
          alignItems="stretch"
          isInvalid={!!errors.description}
          isRequired
        >
          <FormLabel marginBottom={0}>Description</FormLabel>
          <Input
            placeholder="Add a short description on what this Assistant does"
            size="sm"
            isDisabled={!!isInputDisabled}
            {...register('description')}
          />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          as={VStack}
          spacing="8px"
          isInvalid={!!errors.prompt}
          isRequired
          alignItems="stretch"
        >
          <VStack width="100%" spacing="4px" alignItems="stretch">
            <HStack justifyContent="space-between" alignItems="center">
              <FormLabel marginBottom={0}>Instructions</FormLabel>
            </HStack>
            <Text textStyle="body-2" color="base.content.medium">
              Pair can&apos;t read URLs. To reference web content, save it as a
              PDF and upload it.
            </Text>
          </VStack>
          <Textarea
            placeholder={
              'What does this Assistant do? How does it behave? What should it avoid doing? You can choose from ‘Learn how to write prompts’ or compose your own prompt.'
            }
            minAutosizeRows={12}
            maxAutosizeRows={12}
            {...register('prompt', {
              onChange: () => {
                onBehaviorUpdate?.()
              },
            })}
            size="md"
            isDisabled={!!isInputDisabled}
          />
          <FormErrorMessage>{errors.prompt?.message}</FormErrorMessage>
          <HStack
            color="blue.500"
            textStyle="body-2"
            backgroundColor="blue.75"
            paddingX="10px"
            paddingY="8px"
            borderRadius="4px"
            spacing="8px"
          >
            <Icon as={BiSolidHelpCircle} fontSize="16px" />
            <Text>
              <Link
                textDecoration="underline"
                href={PAIR_PROMPT_GUIDES_URL}
                target="_blank"
              >
                View examples
              </Link>{' '}
              or use our{' '}
              <Link
                textDecoration="underline"
                href={PROMPT_ASSISTANT_URL}
                target="_blank"
              >
                prompt assistant
              </Link>{' '}
              to generate your instruction
            </Text>
          </HStack>
        </FormControl>

        {isDocumentsEnabled && (
          <VStack alignItems="start" width="100%" spacing="8px">
            <FormLabel marginBottom={0}>Reference documents</FormLabel>
            <Button
              variant="outline"
              colorScheme="neutral"
              aria-label="Upload document"
              size="xs"
              leftIcon={<BiUpload fontSize="20px" />}
              onClick={onDocumentUploadModalOpen}
              isDisabled={!!isInputDisabled}
            >
              Upload documents
            </Button>
            {completedDocuments.length > 0 && (
              <Wrap width="100%" paddingTop="8px">
                {completedDocuments.map((completedDocument) => (
                  <WrapItem key={completedDocument.id}>
                    <DocumentTag
                      documentName={completedDocument.name}
                      onDelete={() =>
                        onDeleteDocument({ documentId: completedDocument.id })
                      }
                      isDeleteButtonDefaultVisible
                      isDisabled={!!isInputDisabled}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </VStack>
        )}

        <FormControl
          as={VStack}
          spacing="8px"
          alignItems="stretch"
          isInvalid={!!errors.prompt_starters}
        >
          <FormLabel
            marginBottom={0}
            description={`Maximum ${MAX_PROMPT_STARTERS_SIZE} prompts allowed.`}
          >
            Conversation starters
          </FormLabel>
          <VStack alignItems="flex-start" spacing="8px">
            {fields.map((field, index) => {
              const { onChange, ...inputProps } = register(
                `prompt_starters.${index}.value`,
              )

              const errorValue = errors.prompt_starters?.[index]?.value

              return (
                <>
                  <InputFieldWithIconButton
                    key={field.id}
                    isInvalid={!!errorValue}
                    {...inputProps}
                    onChange={(e) => {
                      void onChange(e)
                      if (e.target.value === '') {
                        remove(index)
                      }
                    }}
                    buttonAriaLabel="Remove Entry"
                    icon={<BiX />}
                    onButtonClick={() => remove(index)}
                    isDisabled={!!isInputDisabled}
                  />
                  {errorValue && (
                    <FormErrorMessage>{errorValue?.message}</FormErrorMessage>
                  )}
                </>
              )
            })}
            {/* Placeholder component for empty prompt starter input. */}
            {getValues('prompt_starters').length < MAX_PROMPT_STARTERS_SIZE && (
              <InputFieldWithIconButton
                onChange={(e) => {
                  append(
                    { value: e.target.value },
                    {
                      focusName: `prompt_starters.${fields.length}`,
                    },
                  )
                  e.target.value = ''
                }}
                buttonAriaLabel="Remove Entry"
                isInvalid={false}
                icon={<BiX />}
                isDisabled={!!isInputDisabled}
              />
            )}
          </VStack>
        </FormControl>

        <FormControl
          as={VStack}
          spacing="8px"
          alignItems="stretch"
          isInvalid={!!errors.view_access || !!errors.access_patterns}
          isRequired
        >
          <FormLabel marginBottom={0}>Who can see your assistant</FormLabel>
          <Controller
            control={control}
            name="view_access"
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Radio.RadioGroup
                id={generateFormElementId(name)}
                size="md"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                isDisabled={!!isInputDisabled}
              >
                <VStack alignItems="stretch" spacing="0px">
                  <Radio value="private" allowDeselect={false} size="sm">
                    Only me
                  </Radio>
                  <Radio value="agency" allowDeselect={false} size="sm">
                    <VStack alignItems="start" spacing="4px">
                      <Text>Only my agency (via shared link)</Text>
                      {value === 'agency' && (
                        <Text
                          textStyle="caption-2"
                          color="base.content.medium"
                          marginTop="8px"
                        >
                          {APPROVAL_CONSENT_MESSAGE}
                        </Text>
                      )}
                    </VStack>
                  </Radio>
                  {isCustomDomainSharingEnabled(user.email) && (
                    <Radio.OthersWrapper
                      value="custom"
                      label="Selected agencies / emails domains (via shared link)"
                      allowDeselect={false}
                      size="sm"
                    >
                      <Controller
                        control={control}
                        name="access_patterns"
                        render={({ field }) => (
                          <Box paddingRight="8px">
                            <AssistantDomainSharingSelector
                              name={field.name}
                              values={field.value}
                              onChange={field.onChange}
                              errorMessage={errors.access_patterns?.message}
                            />
                          </Box>
                        )}
                      />
                    </Radio.OthersWrapper>
                  )}
                </VStack>
              </Radio.RadioGroup>
            )}
          />
        </FormControl>

        <FormControl
          as={VStack}
          paddingBottom="64px"
          spacing="8px"
          alignItems="stretch"
          isInvalid={!!errors.risk_category}
          isRequired
        >
          <FormLabel marginBottom={0}>
            Does your assistant fall under any of these specialised fields?
          </FormLabel>
          <Text textStyle="body-2" color="base.content.medium">
            If your assistant provides guidance in these fields, we’ll give
            users a general reminder to verify critical information.
          </Text>
          <Controller
            control={control}
            name="risk_category"
            render={({ field }) => (
              <AssistantRiskAcceptanceSelector
                id={generateFormElementId(field.name)}
                name={field.name}
                value={field.value ?? ''}
                onChange={field.onChange}
                errorMessage={errors.risk_category?.message}
                isDisabled={!!isInputDisabled}
              />
            )}
          />
        </FormControl>
      </VStack>
    </FormProvider>
  )
}
