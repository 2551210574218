import _ from 'lodash'

import {
  AssistantFeedbackMessageLog,
  FeedbackTypesFilterEnumType,
} from '~shared/types/assistant-feedback.dto'

import { FEEDBACK_TYPE_FILTER_LIST } from './constants'

export function getTextAndStyleForFeedbackType({
  isAssistantLevelFeedback,
  isMessageLevelFeedback,
  feedbackNumericValue,
}: {
  isAssistantLevelFeedback: boolean
  isMessageLevelFeedback: boolean
  feedbackNumericValue: number | null | undefined
}): { text: string; colorScheme: 'warning' | 'critical' | 'success' } {
  // Important to check message level feedback first as feedback numeric value will be null
  if (isMessageLevelFeedback) {
    return {
      text: 'Message report',
      colorScheme: 'warning',
    }
  }

  if (
    isAssistantLevelFeedback &&
    feedbackNumericValue &&
    1 <= feedbackNumericValue &&
    feedbackNumericValue <= 5
  ) {
    return {
      text: `${feedbackNumericValue} stars review`,
      colorScheme: feedbackNumericValue <= 3 ? 'critical' : 'success',
    }
  }

  // 0 is also an invalid feedback numeric value
  throw new Error('Invalid feedback type value')
}

export function isFeedbackTypeFilter(
  parsedFeedbackTypeFilter: string,
): parsedFeedbackTypeFilter is FeedbackTypesFilterEnumType {
  return FEEDBACK_TYPE_FILTER_LIST.includes(parsedFeedbackTypeFilter)
}

export function formatMessageLogs(messageLogs: AssistantFeedbackMessageLog[]) {
  return _(messageLogs)
    .map(({ response_data, request_data, message_id, document_names }) => [
      {
        content: request_data,
        id: `user_${message_id}`,
        isAi: false,
        documentNames: document_names,
      },
      { content: response_data, id: message_id, isAi: true },
    ])
    .flatMap()
    .value()
}
