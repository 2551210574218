import { BiSolidStarHalf } from 'react-icons/bi'
import { Tooltip, useDisclosure } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import { AssistantFeedbackModal } from './AssistantFeedbackModal'

export const ReviewAssistantButton = ({
  assistantId,
  messageId,
}: {
  assistantId: string
  messageId: string | undefined
}) => {
  const {
    isOpen: isOpenAssistantFeedbackModal,
    onOpen: onOpenAssistantFeedbackModal,
    onClose: onCloseAssistantFeedbackModal,
  } = useDisclosure()

  const isReviewButtonDisabled = !messageId

  return (
    <>
      <Tooltip
        closeDelay={200}
        label="Chat with the assistant before you can leave a feedback"
        hasArrow
        placement="bottom"
        // We only show the tooltip when the overall component is disabled
        isDisabled={!isReviewButtonDisabled}
      >
        <Button
          size="xs"
          variant="clear"
          colorScheme="sub"
          color="base.content.default"
          leftIcon={<BiSolidStarHalf fontSize="20px" />}
          isDisabled={isReviewButtonDisabled}
          padding="8px"
          onClick={onOpenAssistantFeedbackModal}
          _active={{
            bg: 'interaction.sub-subtle.hover',
          }}
          _hover={{
            bg: isReviewButtonDisabled
              ? 'transparent'
              : 'interaction.sub-subtle.default',
          }}
        >
          Review Assistant
        </Button>
      </Tooltip>
      {!isReviewButtonDisabled && (
        <AssistantFeedbackModal
          onClose={onCloseAssistantFeedbackModal}
          isOpen={isOpenAssistantFeedbackModal}
          assistantId={assistantId}
          feedbackType="assistant_level"
          messageId={messageId}
        />
      )}
    </>
  )
}
