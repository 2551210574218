import { useMemo } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { Text } from '@chakra-ui/react'

import { useTrackedFlag } from '~lib/feature-flag'

import { AssistantAvatar } from '~features/assistants'
import { useAssistantPreviewContext } from '~features/assistants/hooks/useAssistantPreviewContext'
import { AssistantPreviewVisualProps } from '~features/assistants/types/info'
import { AssistantPreviewNewChat } from '~features/chatv2/components/AssistantChat/AssistantPreviewNewChat'
import { InputToolbarIconButton } from '~features/chatv2/components/InputToolbarIconButton'
import { HIGH_RISK_ASSISTANT_WARNING_MESSAGE } from '~features/chatv2/constants'
import { ChatScreenProps } from '~features/chatv2/types'

import { InputToolbar } from '../../../chatv2/components/InputToolbar'

import { PreviewChatMessageWindow } from './PreviewChatMessageWindow'

type CreateAssistantChatWindowProps = ChatScreenProps & {
  isUpdatingPreview: boolean
  previewVisualParameters: AssistantPreviewVisualProps
  assistantId?: string
  resetChats?: () => void
}

// TODO: Consider wrapping in an assistant context for child components to reduce prop drilling
export const AssistantPreviewChatScreen = ({
  assistantId,
  messages,
  isMutationLoading,
  isStreaming,
  isUpdatingPreview,
  errorMessage,
  editor,
  isSubmissionDisabled,
  submissionDisabledMessage,
  previewVisualParameters,
  resetChats,
}: CreateAssistantChatWindowProps) => {
  const {
    state: { previewChatHistory },
  } = useAssistantPreviewContext(assistantId)

  const { isEnabled: isDocumentsInAssistantChatEnabled } = useTrackedFlag(
    'documents_assistant_chat_enable',
  )

  // Memoize the PreviewChatMessageWindow to prevent changes to editor
  // or inputs from re-rendering the whole thing.
  // Note, this is more of a hot fix, ideally we'd want to introduce some
  // proper debouncing too.
  const isChatsEmpty = messages.length === 0 && previewChatHistory.length === 0
  const memoizedPreviewChatMessageWindow = useMemo(() => {
    if (isChatsEmpty) {
      return null
    }

    return (
      <PreviewChatMessageWindow
        isAiResponseLoading={isMutationLoading}
        isAiStreaming={isStreaming}
        previousChats={previewChatHistory}
        currentChatMessages={messages}
        errorMessage={errorMessage}
        defaultAiAvatar={<AssistantAvatar avatarSize="2xs" />}
        isUpdatingPreview={isUpdatingPreview}
      />
    )
  }, [
    previewChatHistory,
    messages,
    isMutationLoading,
    isStreaming,
    errorMessage,
    isUpdatingPreview,
    isChatsEmpty,
  ])

  const inputToolbarPlaceholder = 'Send test message'
  const isPendingAiResponse = isMutationLoading || isStreaming
  return (
    <>
      <Text
        textStyle="subhead-3"
        color="base.content.medium"
        marginTop="30px"
        paddingBottom="8px"
      >
        PREVIEW ASSISTANT
      </Text>
      {memoizedPreviewChatMessageWindow || (
        <AssistantPreviewNewChat
          editor={editor}
          previewVisual={previewVisualParameters}
        />
      )}

      <InputToolbar
        editor={editor}
        isSubmissionDisabled={
          isUpdatingPreview || isSubmissionDisabled || !editor.isEditable
        }
        isAllowDocumentUploads={isDocumentsInAssistantChatEnabled}
        submissionDisabledMessage={
          isUpdatingPreview
            ? 'Please wait for the preview to finish updating'
            : submissionDisabledMessage
        }
        placeholder={inputToolbarPlaceholder}
        // Don't need to show this for preview chats
        isShowChatTooLongAlert={false}
        {...(previewVisualParameters.isHighRisk && {
          warningMessage: HIGH_RISK_ASSISTANT_WARNING_MESSAGE,
        })}
        innerWidth="90%"
        // Used so we restrict the input toolbar disclaimer to just
        // a single line on GSIB default zoom for previews
        sx={{
          '.input-toolbar-disclaimer': {
            fontSize: '11px',
          },
        }}
        additionalToolbarIconButtons={
          !isChatsEmpty && resetChats
            ? [
                <InputToolbarIconButton
                  key="refresh-chats-button"
                  tooltipLabel="Refresh chat"
                  aria-label="Refresh chat"
                  icon={<BiRefresh />}
                  onClick={resetChats}
                  isDisabled={isUpdatingPreview || isPendingAiResponse}
                  isTooltipDisabled={isUpdatingPreview || isPendingAiResponse}
                  fontSize="24px"
                />,
              ]
            : undefined
        }
      />
    </>
  )
}
