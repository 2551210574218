import { useNavigate } from 'react-router-dom'

import { CreateOrUpdateAssistantRequestDto } from '~shared/types/assistants.dto'

import { useAuth } from '~lib/auth'
import { useCustomToast } from '~hooks/useCustomToast'

import { AssistantEditorScreen } from '../components/AssistantEditor/AssistantEditorScreen'
import { useCreateAssistant } from '../hooks'
import { generateAssistantPathFromId } from '../utils'

export const AssistantCreatePage = () => {
  const { user } = useAuth()

  if (!user?.email) {
    throw new Error('Unexpected user error has occurred')
  }

  const navigate = useNavigate()

  const handlePublishClose = (assistantId: string) =>
    navigate(generateAssistantPathFromId(assistantId))

  const { mutate, isLoading: isCreateLoading } = useCreateAssistant()
  const { createToast } = useCustomToast()

  const onPublish = (data: CreateOrUpdateAssistantRequestDto) => {
    mutate(data, {
      onError: () => {
        createToast({
          content:
            'An unexpected error has occurred while creating your assistant. Please try again.',
          type: 'error',
        })
      },
      onSuccess: (data) => {
        createToast({
          content: 'Your Assistant has been successfully created.',
          type: 'success',
          duration: 2_500,
        })
        handlePublishClose(data.id)
      },
    })
  }

  return (
    <AssistantEditorScreen
      onPublish={onPublish}
      isPublishLoading={isCreateLoading}
    />
  )
}
