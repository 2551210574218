import { extendTheme } from '@chakra-ui/react'
// Importing from main so @chakra-cli can work properly without complaining about ESM.
import { theme as baseTheme } from '@opengovsg/design-system-react/build/main/theme/theme'

import { colours } from './foundations/colours'
import { shadows } from './foundations/shadows'
import { spacing } from './foundations/spacing'
import { textStyles } from './foundations/textStyles'
import { typography } from './foundations/typography'
import { components } from './components'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(baseTheme, {
  components,
  colors: colours,
  shadows: shadows,
  space: spacing,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  textStyles,
})
