import {
  BiConversation,
  BiDotsHorizontalRounded,
  BiInfoCircle,
  BiMessageRoundedAdd,
  BiSolidStarHalf,
} from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Icon,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { Menu, ModalCloseButton } from '@opengovsg/design-system-react'

import { SharedAssistantDto } from '~shared/types/assistants.dto'

import { useTrackedFlag } from '~lib/feature-flag'
import { UnanimatedModal } from '~components/UnanimatedComponents'

import { generateAssistantPathFromId } from '~features/assistants'
import { AssistantFeedbackModal } from '~features/chatv2/components/AssistantChat/AssistantFeedbackModal'
import { AssistantInfoDisplay } from '~features/chatv2/components/AssistantChat/AssistantInfoDisplay'

import { DeleteAssistantButton } from './DeleteAssistantButton'

type AssistantChatHeaderMoreButtonProps = {
  assistant: SharedAssistantDto
  isAssistantOwner: boolean
  messageId: string | undefined
}

export const AssistantChatHeaderMoreButton = ({
  assistant,
  isAssistantOwner,
  messageId,
}: AssistantChatHeaderMoreButtonProps) => {
  const {
    isOpen: isOpenAssistantInfoDisplay,
    onOpen: onOpenAssistantInfoDisplay,
    onClose: onCloseAssistantInfoDisplay,
  } = useDisclosure()
  const {
    isOpen: isOpenAssistantFeedbackModal,
    onOpen: onOpenAssistantFeedbackModal,
    onClose: onCloseAssistantFeedbackModal,
  } = useDisclosure()

  const navigate = useNavigate()
  const location = useLocation()

  const { isEnabled: isAssistantsFeedbackViewable } = useTrackedFlag(
    'enable_creator_assistants_feedback',
  )

  const assistantId = assistant.id
  const isReviewButtonDisabled = !messageId

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="more"
          icon={<BiDotsHorizontalRounded />}
          variant="clear"
          color="base.content.default"
          size="xs"
          _active={{
            bg: 'interaction.sub-subtle.hover',
          }}
          _hover={{
            bg: 'interaction.sub-subtle.default',
          }}
        />
        <MenuList>
          {isAssistantOwner && (
            <>
              <Tooltip
                closeDelay={200}
                label="Chat with the assistant before you can leave a feedback"
                hasArrow
                placement="left"
                // We only show the tooltip when the overall component is disabled
                isDisabled={!isReviewButtonDisabled}
              >
                <MenuItem
                  isDisabled={isReviewButtonDisabled}
                  textStyle="body-2"
                  iconSpacing="8px"
                  icon={
                    <Icon
                      as={BiSolidStarHalf}
                      fontSize="16px"
                      verticalAlign="middle"
                    />
                  }
                  onClick={onOpenAssistantFeedbackModal}
                >
                  Review Assistant
                </MenuItem>
              </Tooltip>
              {isAssistantsFeedbackViewable && (
                <MenuItem
                  textStyle="body-2"
                  iconSpacing="8px"
                  icon={
                    <Icon
                      as={BiConversation}
                      fontSize="16px"
                      verticalAlign="middle"
                    />
                  }
                  // TODO: add state for navigation to prevent unwanted accessing of feedback
                  onClick={() =>
                    navigate(`/assistants/${assistantId}/feedbacks`, {
                      state: {
                        from: `${location.pathname}${location.search}`,
                      },
                    })
                  }
                >
                  View all feedback
                </MenuItem>
              )}
            </>
          )}

          {assistant && (
            <>
              <MenuItem
                textStyle="body-2"
                iconSpacing="8px"
                onClick={onOpenAssistantInfoDisplay}
                icon={
                  <Icon
                    as={BiInfoCircle}
                    fontSize="16px"
                    verticalAlign="middle"
                  />
                }
              >
                About
              </MenuItem>
              <MenuItem
                textStyle="body-2"
                iconSpacing="8px"
                onClick={() =>
                  navigate(generateAssistantPathFromId(assistantId))
                }
                icon={
                  <Icon
                    as={BiMessageRoundedAdd}
                    fontSize="16px"
                    verticalAlign="middle"
                  />
                }
              >
                New chat with Assistant
              </MenuItem>
              {isAssistantOwner && !messageId && (
                <DeleteAssistantButton
                  assistantId={assistantId}
                  iconSpacing="8px"
                  onAssistantDeleted={() => {
                    navigate('/assistants')
                  }}
                />
              )}
            </>
          )}
        </MenuList>
      </Menu>
      {assistant && (
        <UnanimatedModal
          onClose={onCloseAssistantInfoDisplay}
          isOpen={isOpenAssistantInfoDisplay}
          isCentered
        >
          <ModalOverlay />
          <ModalContent m="0px">
            <ModalBody my="24px" px="0px">
              <ModalCloseButton insetEnd="4px" top="4px" size="lg" />
              <AssistantInfoDisplay assistant={assistant} />
            </ModalBody>
          </ModalContent>
        </UnanimatedModal>
      )}
      {messageId && (
        <AssistantFeedbackModal
          onClose={onCloseAssistantFeedbackModal}
          isOpen={isOpenAssistantFeedbackModal}
          assistantId={assistantId}
          feedbackType="assistant_level"
          messageId={messageId}
        />
      )}
    </>
  )
}
