import { BiPlus } from 'react-icons/bi'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { Button, Link } from '@opengovsg/design-system-react'

import { PAIR_CHAT_OR_ASSISTANTS_URL } from '~constants/config'

export const AssistantBanner = ({
  onCreateAssistant,
}: {
  onCreateAssistant: () => void
}) => {
  return (
    <Flex
      width="full"
      py="32px"
      px="72px"
      bgColor="base.canvas.brand-light"
      gap={{ base: '36px', md: '72px' }}
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <VStack align="flex-start" gap="8px" maxWidth="600px">
        <Text textStyle="h4">Assistants to automate repetitive work</Text>
        <Text textStyle="body-2">
          Shareable AI chat bots customised for specific tasks like referencing
          specialised documents or following strict instructions to ensure
          consistent responses
        </Text>
      </VStack>
      <VStack
        gap={{ base: '8px', md: '16px' }}
        minW={{ base: '0px', md: '224px' }}
        width={{ base: '100%', md: '224px' }}
        alignItems="end"
      >
        <Button
          leftIcon={<BiPlus size="24px" />}
          color="white"
          size="md"
          onClick={onCreateAssistant}
        >
          Create new assistant
        </Button>
        <Link
          textStyle="body-2"
          href={PAIR_CHAT_OR_ASSISTANTS_URL}
          target="_blank"
        >
          Chat or Assistants: Which to use?
        </Link>
      </VStack>
    </Flex>
  )
}
