import { Box, Image } from '@chakra-ui/react'

import OgpLogo from '~/assets/ogp-logo.svg'

export const SidebarFooter = () => {
  return (
    <Box>
      <Image src={OgpLogo} height="28px" />
    </Box>
  )
}
