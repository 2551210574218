import { Text, VStack } from '@chakra-ui/react'

import { AssistantAvatar } from '~features/assistants'
import { AssistantPreviewVisualProps } from '~features/assistants/types/info'
import { IBasicEditor } from '~features/chatv2/types'

import { AssistantPromptHighlights } from './AssistantPromptHighlights'

export const AssistantPreviewNewChat = ({
  editor,
  previewVisual,
}: {
  editor: IBasicEditor
  previewVisual: AssistantPreviewVisualProps
}) => {
  return (
    <VStack
      flexGrow={1}
      width="90%"
      paddingX="16px"
      h="100vh"
      maxH="100vh"
      overflowY="hidden"
      justify="center"
    >
      <AssistantAvatar avatarSize="md" />
      <Text textStyle="h5">{previewVisual.name}</Text>
      <Text textStyle="body-2" color="base.content.default" textAlign="center">
        {previewVisual.description}
      </Text>
      <AssistantPromptHighlights
        onSelect={(content) => editor.setTemplate(content)}
        promptStarters={previewVisual.promptStarters ?? []}
      />
    </VStack>
  )
}
