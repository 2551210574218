import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

/**
 * Reference https://github.com/remix-run/react-router/issues/8200#issuecomment-1193097483\
 * Throws error when invariant is violated. Ensure the parent containers of the current component calling this has an error boundary
 */
export function useTypedParams<T extends string>(
  parameterNames: T[],
): Record<T, string> {
  const params = useParams()
  const typedParams: Record<string, string> = {}
  parameterNames.forEach((paramName) => {
    const currentParam = params[paramName]
    invariant(
      currentParam,
      `${paramName} not found in useParams. Check the parent route to make sure nothing changed`,
    )
    typedParams[paramName] = currentParam
  })
  return typedParams
}
