import { AssistantSchemaKeys } from '~features/assistants/schema/assistant-schema'

export const FieldNameToLabelDict: Record<AssistantSchemaKeys, string> = {
  name: 'Name',
  description: 'Description',
  prompt: 'Instructions',
  prompt_starters: 'Conversation starters',
  view_access: 'Who can see this',
  document_ids: 'Reference document(s)',
  access_patterns: 'Assistants shared link access',
  risk_category: 'Assistant risk area',
}
