import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosError, isAxiosError } from 'axios'
import produce from 'immer'
import _ from 'lodash'

import { APPROXIMATE_CHARACTERS_PER_TOKEN } from '~shared/constants'
import { TruncatedAssistantDto } from '~shared/types/assistants.dto'
import {
  AssistantPreviewInputsDto,
  ChatHistoryResponseDto,
  ChatMessageDto,
  ChatMessageEventResponseDto,
  CreateChatMessageDto,
} from '~shared/types/chats.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'
import { routes } from '~constants/routes'

import { useGetSharedAssistant } from '~features/assistants/hooks/useGetSharedAssistant'

import { CREATE_CHAT_PLACEHOLDER_ID } from '../constants'
import { ChatHistoryTokenCounts, ChatInputType } from '../types'

import { useChatErrorHandler } from './useChatErrorHandler'
import { useChatHistory } from './useChatHistory'
import { useConversationContext } from './useConversationContext'

/**
 * TODO: Add a seperate hook for conversation management
 * - Add a seperate hook for tracking conversation states
 * - Wrap the appendFunction into a mutation. This allows us to more easily manage mutation states
 * - Fix error handling.
 */
type UseChatProps = {
  id?: string
  onLoadChatError?: (error: AxiosError) => void
  assistantId?: string
  setIdOnCreate?: (id: string | undefined) => void
  placeholderIdPrefix?: string
}

export function useChat({
  id,
  onLoadChatError,
  assistantId,
  setIdOnCreate,
  placeholderIdPrefix,
}: UseChatProps) {
  if (id && assistantId) {
    throw new Error('Both chat id and assistantId provided to useChat.')
  }

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { updateConversationState, getConversationState } =
    useConversationContext()

  const placeholderId = placeholderIdPrefix
    ? `${placeholderIdPrefix}-${CREATE_CHAT_PLACEHOLDER_ID}`
    : CREATE_CHAT_PLACEHOLDER_ID
  const chatOrPlaceholderId = id || placeholderId

  const { isAppendLoading, isStreaming, errorMessage } =
    getConversationState(chatOrPlaceholderId)

  const { data: chatHistoryData, isLoading: isChatLoading } = useChatHistory({
    id: chatOrPlaceholderId,
    isNewChat: !id,
    onLoadChatError,
  })

  // TODO: Replace with endpoint and hook that returns truncated assistant if more sensitive data is added
  const {
    data: sharedAssistantData,
    isLoading: isAssistantLoading,
    error: assistantError,
  } = useGetSharedAssistant({ assistantId })

  const messages = useMemo(
    () => chatHistoryData?.messages || [],
    [chatHistoryData],
  )
  const assistant = useMemo(
    () =>
      chatHistoryData?.chat?.assistant ||
      sharedAssistantData?.assistant ||
      null,
    [chatHistoryData?.chat?.assistant, sharedAssistantData?.assistant],
  )
  // Note:
  // 20240923, 16:46
  // This can be optimized further so we only update the total count on new
  // message, but I tested with a document with 10,000 messages (simulated)
  // and it rendered fine so deferring this for sake of simplicity.
  // P.S. as of this date, max number of messages in single convo is 4000
  const totalTokenCounts: ChatHistoryTokenCounts = useMemo(() => {
    return messages.reduce(
      (acc, message) => {
        const messageTokens =
          (message.content?.length || 0) / APPROXIMATE_CHARACTERS_PER_TOKEN
        const documentTokens = _.sumBy(message.documents, 'token_count')
        return {
          messageTokenCount: acc.messageTokenCount + messageTokens,
          documentTokenCount: acc.documentTokenCount + documentTokens,
        }
      },
      { messageTokenCount: 0, documentTokenCount: 0 },
    )
  }, [messages])

  // Queue to hold the messages
  const messageQueueRef = useRef<EventSourceMessage[]>([])
  const handleError = useChatErrorHandler({
    id,
    assistant,
    updateConversationState,
    chatOrPlaceholderId,
  })

  // Keep the latest messages in a ref.
  const messagesRef = useRef<ChatMessageDto[]>(messages)
  const assistantRef = useRef<TruncatedAssistantDto | null>(assistant)
  useEffect(() => {
    messagesRef.current = messages
    assistantRef.current = assistant
  }, [messages, assistant, id])

  const append = useCallback(
    async ({
      chatInput,
      assistantPreviewInputs,
    }: {
      chatInput: ChatInputType
      assistantPreviewInputs?: AssistantPreviewInputsDto
    }) => {
      const messageQueue = messageQueueRef.current

      const documents = chatInput.documents
      const documentIds: string[] = _.map(documents, (document) => document.id)
      const message: CreateChatMessageDto = {
        content: chatInput.text,
        document_ids: documentIds,
      }

      // Do an optimisitic update of the chat state
      const previousMessages = messagesRef.current
      const assistant = assistantRef.current
      const assistantId = assistant?.id
      let isProcessing = false
      queryClient.setQueryData(
        queries.chat.detail(chatOrPlaceholderId).queryKey,
        (oldConversation?: ChatHistoryResponseDto) => {
          return produce(oldConversation, (draft: ChatHistoryResponseDto) => {
            draft.messages = [
              ...previousMessages,
              {
                ...message,
                id: crypto.randomUUID(), // Temporary id
                created_at: Date.now().toString(),
                author: 'user',
                // Hardcode the tool to be LLM for user messages
                tool: 'llm',
                documents,
              },
            ]
          })
        },
      )
      updateConversationState({
        conversationId: chatOrPlaceholderId,
        update: {
          isAppendLoading: true,
        },
      })

      const isNewChat = !id
      // Function to process messages sequentially
      const processMessageQueue = () => {
        if (messageQueue.length === 0) {
          isProcessing = false
          return
        }

        isProcessing = true
        const messageEvent = messageQueue.shift()

        // Process the message here, using synchronous code or promises
        const processMessage = async () => {
          if (messageEvent?.data && messageEvent.id) {
            const data: ChatMessageEventResponseDto = JSON.parse(
              messageEvent.data,
            )

            // On complete signal
            if (data.finish_reason === 'completed') {
              if (isNewChat) {
                // Note, we can technically do away with this, but it will lead
                // to a brief "loading" state right after we do the navigate
                // but before the new data comes in. Instead here we directly
                // write to the cache
                queryClient.setQueryData(
                  queries.chat.detail(data.chat_id).queryKey,
                  () => {
                    return {
                      // Note, at this point we have a brief instance where the
                      // cache contains an empty message ''.
                      chat: {
                        id: data.chat_id,
                        title: _.truncate(data.content, {
                          length: 200,
                        }),
                        created_at: data.created_at,
                        updated_at: data.created_at,
                        assistant,
                      },
                      messages: [
                        {
                          ...message,
                          id: data.chat_id,
                          created_at: data.created_at,
                          author: 'user',
                          // Hardcode the tool to be LLM for user messages
                          tool: 'llm',
                          documents,
                        },
                        data,
                      ],
                    }
                  },
                )

                await queryClient.invalidateQueries({
                  queryKey: queries.chat.list.queryKey,
                })

                if (assistant) {
                  void queryClient.invalidateQueries(
                    queries.assistants.list._ctx.recent,
                  )
                }

                // Cleans up the placeholder query key
                // TODO: implement a cleaner way to deal with this
                updateConversationState({
                  conversationId: placeholderId,
                  update: {
                    isStreaming: false,
                    isAppendLoading: false,
                  },
                })
                if (setIdOnCreate) {
                  setIdOnCreate(data.chat_id)
                }
                // Only navigating out to chat page when not in preview
                if (!assistantPreviewInputs) {
                  // TODO: Move over this conditional check into a separate hook
                  navigate(`${routes.chat}/${data.chat_id}`)
                }
                return void queryClient.resetQueries({
                  ...queries.chat.detail(placeholderId),
                  exact: true,
                })
              }

              return updateConversationState({
                conversationId: data.chat_id,
                update: {
                  isAppendLoading: false,
                  isStreaming: false,
                  errorMessage: null,
                },
              })
            }

            // On timeout signal
            if (
              data.finish_reason === 'timeout' ||
              data.finish_reason === 'error'
            ) {
              return handleError({ finishReason: data.finish_reason })
            }

            // First message
            if (messageEvent.id === '1') {
              updateConversationState({
                conversationId: chatOrPlaceholderId,
                update: {
                  isStreaming: true,
                  isAppendLoading: false,
                },
              })

              // Because the message has not been created on the backend yet,
              // we need to update the query cache directly
              return queryClient.setQueryData(
                queries.chat.detail(chatOrPlaceholderId).queryKey,
                (oldChat?: ChatHistoryResponseDto) => {
                  return produce(oldChat, (draft: ChatHistoryResponseDto) => {
                    // Note, at this point we have a brief instance where the
                    // cache contains an empty message ''.
                    draft.messages.push(data)
                  })
                },
              )
            }

            // Second or later token
            if (data.id) {
              queryClient.setQueryData(
                queries.chat.detail(chatOrPlaceholderId).queryKey,
                (oldChat?: ChatHistoryResponseDto) => {
                  return produce(oldChat, (draft: ChatHistoryResponseDto) => {
                    draft.messages[draft.messages.length - 1].content +=
                      data.content
                  })
                },
              )
              return
            }
          }
        }

        void processMessage().then(() => {
          // process next message after the current one is done
          processMessageQueue()
        })
      }

      // if chatId is not defined, this will generate a new chatId and a new
      // chat in the backend.
      // TODO: move over to react-query mutations w/ typesafety
      // [TODO-VAPT] 20241025 - adding an extra tag here to fix this, although
      // it's non urgent since our controllers will handle malformed input.
      const url = `${ApiService.getUri()}/${
        assistantPreviewInputs ? 'preview/' : ''
      }chats/${id ?? ''}`
      await fetchEventSource(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          preview_inputs: assistantPreviewInputs,
          ...(isNewChat && {
            title: _.truncate(message.content, {
              length: 200,
            }),
          }),
          ...(assistantId !== undefined && { assistant_id: assistantId }),
        }),
        openWhenHidden: true,

        // eslint-disable-next-line @typescript-eslint/require-await
        onopen: async (response) => {
          if (response.ok) {
            return
          }
          handleError({ errorCode: response.status })
        },

        onmessage: (messageEvent) => {
          // Add new messages to the queue
          messageQueue.push(messageEvent)

          // If not already processing, start processing
          if (!isProcessing) {
            processMessageQueue()
          }
        },

        onerror: (error: unknown) => {
          if (isAxiosError(error)) {
            const statusCode = error.response?.status ?? null
            if (statusCode) {
              handleError({ errorCode: statusCode })
            }
          }

          // Thrown errors will stop retries. By default this handler, will retry with returned interval
          throw error
        },

        onclose: () => {
          if (chatOrPlaceholderId) {
            updateConversationState({
              conversationId: chatOrPlaceholderId,
              update: {
                isStreaming: false,
                isAppendLoading: false,
              },
            })
          }
        },
      })
    },
    [
      placeholderId,
      queryClient,
      chatOrPlaceholderId,
      updateConversationState,
      id,
      messageQueueRef,
      setIdOnCreate,
      navigate,
      handleError,
    ],
  )

  const handleSubmit = useCallback(
    (
      input: ChatInputType,
      assistantPreviewInputs?: AssistantPreviewInputsDto,
    ) => {
      void append({ chatInput: input, assistantPreviewInputs })
    },
    [append],
  )

  return {
    totalTokenCounts,
    isChatLoading,
    isAssistantLoading,
    chat: chatHistoryData?.chat,
    assistant,
    assistantError,
    messages: messages || [],
    isMutationLoading: isAppendLoading,
    isStreaming,
    handleSubmit,
    errorMessage,
  }
}
