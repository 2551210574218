import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { PrivateElement } from '~/app/PrivateElement'

import { useTrackedFlag } from '~lib/feature-flag'
import { routes } from '~constants/routes'
import { LeftSidebarTemplatePage } from '~templates/LeftSidebarTemplatePage'

import { AssistantPreviewStateProvider } from '../hooks/useAssistantPreviewContext'

import { AssistantCreatePage } from './AssistantCreatePage'
import { AssistantFeedbacksPage } from './AssistantFeedbacksPage'
import { AssistantsDiscoveryPage } from './AssistantsDiscoveryPage'
import { AssistantUpdatePage } from './AssistantUpdatePage'

// TODO: There should be a single source of truth for routes. Clean this up
// see routes.ts
export const AssistantsRoutes = () => {
  const { isEnabled: isAssistantsFeedbackViewable } = useTrackedFlag(
    'enable_creator_assistants_feedback',
  )

  return (
    <AssistantPreviewStateProvider>
      <Routes>
        {/*
        Errors thrown will redirect to base `/chatV2` page.
        TODO: Add better fallback error component. This should be a static element
      */}

        {/* NOTE: Only the discovery page currently has a sidebar */}
        <Route
          element={<PrivateElement element={<LeftSidebarTemplatePage />} />}
          errorElement={<Navigate to={routes.chat} />}
        >
          <Route index element={<AssistantsDiscoveryPage />} />
        </Route>

        <Route element={<PrivateElement element={<Outlet />} />}>
          <Route path="create" element={<AssistantCreatePage />} />
          <Route path=":assistantId" element={<AssistantUpdatePage />} />
          {isAssistantsFeedbackViewable && (
            <Route
              path=":assistantId/feedbacks"
              element={<AssistantFeedbacksPage />}
            />
          )}
          <Route path="*" element={<Navigate to="." />} />
        </Route>
      </Routes>
    </AssistantPreviewStateProvider>
  )
}
