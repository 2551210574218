import { datadogLogs, LogsEvent } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import {
  APP_ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
} from '~constants/config'
import { getBrowserInfo } from '~utils/user-agent'

const isMonitoredEnvironment = APP_ENV === 'production' || APP_ENV === 'staging'
const browserInfo = getBrowserInfo()

export function startRumSessionMonitoring({
  id,
  email,
  browserId,
  pixelRatioPreference,
}: {
  id: string
  email?: string
  browserId?: string
  pixelRatioPreference?: string
}) {
  if (
    isMonitoredEnvironment &&
    DATADOG_APPLICATION_ID &&
    DATADOG_CLIENT_TOKEN
  ) {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'pair-frontend',
      env: APP_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: import.meta.env.VITE_APP_VERSION || '1.0.0',
      // Sample rate in % (i.e 100 refers to all sessions)
      sessionSampleRate: 100,
      forwardErrorsToLogs: true,
      beforeSend: (event: LogsEvent) => {
        if (event.http?.status_code === 0) {
          // Don't log XHR aborted requests
          return false
        }
        return true
      },
    })

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'pair-frontend',
      env: APP_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: import.meta.env.VITE_APP_VERSION || '1.0.0',
      sessionSampleRate: 100,
      // Disable session replay
      sessionReplaySampleRate: 0,
      // Turn off all forms of interaction tracking
      trackUserInteractions: false,
      trackResources: false,
      trackLongTasks: false,

      // Forces all elements to be masked by default.
      // See https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#mask-mode
      defaultPrivacyLevel: 'mask',

      // Allows integration with DataDog Traces
      allowedTracingOrigins: [/https:\/\/.*(\.)?pair\.gov\.sg/],

      enableExperimentalFeatures: ['feature_flags'],
    })

    datadogRum.startSessionReplayRecording()
    // Allows setting of user information on session metadata
    datadogRum.setUser({
      id,

      ...(!!email && { email }),
      ...(!!browserId && { browser_id: browserId }),
      ...(!!pixelRatioPreference && {
        pixel_ratio_preference: pixelRatioPreference,
      }),
      // TODO: Figure out how to cleanly override datadog's UA properties
      ...browserInfo,
    })
  }
}

export function stopRumSessionMonitoring() {
  // Checks that datadogrum has been configured correctly before calling RUM
  if (datadogRum.getInitConfiguration()?.clientToken)
    return datadogRum.stopSessionReplayRecording()
}
