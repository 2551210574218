import {
  FeedbackTypesFilterEnumType,
  GetAssistantStatisticsResponseDto,
} from '~shared/types/assistant-feedback.dto'

export const PAGE_SIZE = 10
export const BASE_PAGE = 1

type FieldAndTitle = {
  title: string
  field: keyof GetAssistantStatisticsResponseDto
  fallback?: '-'
}

export const FeedbackStatisticsFields: FieldAndTitle[] = [
  {
    title: 'Unique users',
    field: 'number_of_unique_users',
  },
  {
    title: 'Messages sent',
    field: 'number_of_messages',
  },
  {
    title: 'Chats',
    field: 'number_of_conversations',
  },
  {
    title: 'Overall rating',
    field: 'overall_rating',
    fallback: '-',
  },
  {
    title: 'Message reports',
    field: 'number_of_message_reports',
    fallback: '-',
  },
]

type FeedbackFilterAndLabel = {
  label: string
  value: FeedbackTypesFilterEnumType
}

export const FEEDBACK_TYPES_FILTER: FeedbackFilterAndLabel[] = [
  {
    label: 'All feedback types',
    value: 'all',
  },
  {
    label: 'Assistant reviews with 4-5 stars',
    value: 'four_to_five_rating',
  },
  {
    label: 'Assistant reviews with 1-3 stars',
    value: 'one_to_three_rating',
  },
  {
    label: 'Message reports',
    value: 'message_reports',
  },
]

// Able to typed back to string[] array but yet enforcing the values in the string array are valid. Rationale is that typescript cannot do type check probably with narrowed array
export const FEEDBACK_TYPE_FILTER_LIST: string[] = [
  'all',
  'four_to_five_rating',
  'message_reports',
  'one_to_three_rating',
] satisfies FeedbackTypesFilterEnumType[]
