import { ReactNode, useRef, useState } from 'react'
import { BiCopy, BiDislike } from 'react-icons/bi'
import {
  Button,
  ButtonGroup,
  forwardRef,
  PopoverAnchor,
  PopoverContent,
  Text,
  useClipboard,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Textarea } from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'

import { textStyles } from '~/theme/foundations/textStyles'

import { useCustomToast } from '~hooks/useCustomToast'
import { IconButtonWithTooltip } from '~components/IconButtonWithTooltip'
import { UnanimatedPopover } from '~components/UnanimatedComponents'

import { createFeedback } from '~features/chatv2/api'

import { AssistantFeedbackPopover } from '../AssistantChat/AssistantFeedbackPopover'

interface ReportPopoverProps {
  messageId: string
  children: (props: { onOpen: () => void }) => ReactNode
}

const ReportPopover = ({ messageId, children }: ReportPopoverProps) => {
  const { createErrorToast, createSuccessToast } = useCustomToast()

  const [feedbackContent, setFeedbackContent] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const submitFeedbackMutation = useMutation(createFeedback)

  // For referencing text area for initial focus on popover
  const textAreaFieldRef = useRef<HTMLTextAreaElement>(null)

  // Handlers
  const handleFeedbackSubmit = () => {
    submitFeedbackMutation.mutate(
      { messageId, rating: 'negative', content: feedbackContent },
      {
        onSuccess: () => {
          createSuccessToast({
            content: "Thank you! We've sent your feedback to the Pair Team.",
          })
          onClose()
        },
        onError: () => {
          createErrorToast({
            content:
              'An unexpected error has occurred while submitting feedback.',
          })
        },
      },
    )
  }

  return (
    <UnanimatedPopover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={textAreaFieldRef}
    >
      <PopoverAnchor>{children({ onOpen })}</PopoverAnchor>
      <PopoverContent
        marginRight="24px"
        padding="16px"
        // Prevents undesirable overflow
        // See https://github.com/chakra-ui/chakra-ui/issues/4109
        rootProps={{ style: { transform: 'scale(0)' } }}
      >
        <VStack alignItems="stretch" spacing="10px">
          <Text textStyle="subhead-2">
            What was the issue with this message?
          </Text>
          <Textarea
            {...textStyles['body-2']}
            resize="none"
            minAutosizeRows={2}
            maxAutosizeRows={5}
            ref={textAreaFieldRef}
            value={feedbackContent}
            onChange={(e) => {
              e.preventDefault()
              setFeedbackContent(e.target.value)
            }}
          />
          <ButtonGroup width="100%" justifyContent="flex-end" spacing="8px">
            <Button
              {...textStyles['subhead-1']}
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              {...textStyles['subhead-1']}
              isLoading={submitFeedbackMutation.isLoading}
              color="white"
              onClick={handleFeedbackSubmit}
            >
              Send Feedback
            </Button>
          </ButtonGroup>
        </VStack>
      </PopoverContent>
    </UnanimatedPopover>
  )
}

type ChatMessageIconButtonWithToolTipProps = {
  onOpen: () => void
  tooltipText: string
}

const ChatMessageIconButtonWithToolTip = forwardRef<
  ChatMessageIconButtonWithToolTipProps,
  'button'
>(({ onOpen, tooltipText }, ref) => {
  return (
    <IconButtonWithTooltip
      tooltipText={tooltipText}
      aria-label="report"
      onClick={onOpen}
      color="base.content.medium"
      icon={<BiDislike />}
      size="xs"
      fontSize="16px"
      ref={ref}
    />
  )
})

type ChatMessageToolbarProps = {
  messageId: string
  textToCopy: string
  assistantId?: string
}

export const ChatMessageToolbar = ({
  messageId,
  textToCopy,
  assistantId,
}: ChatMessageToolbarProps) => {
  const { onCopy, hasCopied } = useClipboard(textToCopy)

  return (
    <ButtonGroup
      flexDir={{ base: 'column', lg: 'row' }}
      spacing="0px"
      variant="clear"
      alignItems="center"
    >
      {assistantId ? (
        messageId && (
          <AssistantFeedbackPopover
            assistantId={assistantId}
            headerText="What was the issue with this message?"
            feedbackType="message_level"
            feedbackRating="negative"
            messageId={messageId}
            placeholder="Describe the problem (optional)"
          >
            {({ onOpen }) => (
              <ChatMessageIconButtonWithToolTip
                tooltipText="Report message"
                onOpen={onOpen}
              />
            )}
          </AssistantFeedbackPopover>
        )
      ) : (
        <ReportPopover messageId={messageId}>
          {({ onOpen }) => (
            <ChatMessageIconButtonWithToolTip
              tooltipText="Report message"
              onOpen={onOpen}
            />
          )}
        </ReportPopover>
      )}

      <IconButtonWithTooltip
        tooltipText={hasCopied ? 'Copied!' : 'Copy'}
        aria-label="copy"
        tooltipCloseDelay={1000}
        onClick={onCopy}
        icon={<BiCopy />}
        color="base.content.medium"
        size="xs"
        fontSize="16px"
      />
    </ButtonGroup>
  )
}
