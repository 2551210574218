import { Checkbox } from './Checkbox'
import { inlineMenuTheme } from './InlineMenu'
import { Pagination } from './Pagination'
import { SingleSelect } from './SingleSelect'
import { tabsTheme } from './Tabs'
import { Textarea } from './Textarea'

export const components = {
  Tabs: tabsTheme,
  InlineMenu: inlineMenuTheme,
  Textarea,
  Checkbox,
  SingleSelect,
  Pagination,
}
