import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'

import { ListPromotionalAssistantsResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const listPromotionalAssistants = async () => {
  return ApiService.get<ListPromotionalAssistantsResponseDto>(
    `/assistants/promotions/list`,
  ).then((response) => response.data)
}

export function useListPromotionalAssistants() {
  return useQuery(
    queries.assistants.listPromotional.queryKey,
    listPromotionalAssistants,
    {
      select: (data) =>
        _.filter(data.assistants, (asst) => !!asst.promotion_info),
      refetchOnWindowFocus: false,
    },
  )
}
