import { useQuery } from '@tanstack/react-query'

import { ListAssistantSharingOptionsDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

const listSharingOptions = async () => {
  return ApiService.get<ListAssistantSharingOptionsDto>(
    '/assistants/sharing-options',
  ).then((response) => {
    return response.data
  })
}

export function useListSharingOptions() {
  return useQuery(
    queries.assistants.sharingOptions.queryKey,
    listSharingOptions,
    {
      refetchOnWindowFocus: false,
    },
  )
}
