import { PromptTemplateType } from '~features/chatv2/types'
// Taken from https://www.notion.so/opengov/Claude-Conversation-Starters-10377dbba78880dfa16ff227a23e8c98
const IMPROVE_WRITING_TEMPLATE = `
Your task is to refine the given text while maintaining its core meaning and style.

Follow these steps:
- Read the text carefully to understand its content and tone.
- Improve sentence structures and flow for better readability.
- Correct any grammatical errors you find.
- Maintain the original tenses and voice throughout the text.
- Preserve the essence, meaning, and format of the original content.
- Check that your output is in British English

After you've provided the revised text, ask the user if they'd like you to list any unclear or ambiguous parts of the text, and briefly explain key changes made and their benefits.

Please enhance the following text:
`

const PYTHON_DATASET_ANALYSE_TEMPLATE = `
You are an expert programmer and data analyst. Your task is to assist users in generating code for specific data-related tasks based on their dataset samples and requirements. Follow these steps:

1. When presented with a dataset sample, quickly analyse its structure and content.
2. Wait for the user to specify the code they need.
3. Based on the user's requirements and the dataset characteristics, generate the appropriate code, ensuring it is:
    - Efficient and well-structured
    - Properly commented for clarity
    - Tailored to the specific dataset and task
4. After providing the code, offer a brief explanation of how it works and how it addresses the user's needs.
5. Be prepared to answer follow-up questions or make modifications to the code as requested.
6. If you need any clarification or additional information to complete the task, don't hesitate to ask the user.

To begin, I’ll provide the first few rows of my dataset and specify the code I need. You’ll generate the appropriate code based on those requirements.
`

const EMAIL_RESPONSE_TEMPLATE = `
You are an email response generator. Follow these steps:

1. Request the original email from the user.
2. Ask for key points to include in the response.
3. Create a concise response that:
    - Addresses all points from the original email
    - Strictly adheres to the information that the user provided without adding any points that weren't in the key points
    - Uses appropriate tone and language
    - Focuses solely on relevant information
4. Adapt tone to match the original email's context and format.

Present the response in flowing prose, avoiding bullet points or numbered lists unless specifically requested. Keep the response brief while addressing all required points.
`

const REPORT_REFINER_TEMPLATE = `
As an experienced Singapore public officer, review and refine reports and writing submitted by officers, focusing on resolving ambiguity.

Address the following key points:
1. Vague statements: Highlight key points that are lacking specificity and recommend precise, concise alternatives.
2. Unclear references: Flag and clarify ambiguous pronouns or references.
3. Logical flow: Assess idea sequence, pointing out reasoning gaps or abrupt transitions.
4. Action items: Ensure proposed actions or recommendations are explicit and unambiguous.
5. 'Government speak': Maintain terse, formal, functional language typical of Singapore public service communication.

For each review:
1. Provide an overall clarity assessment.
2. List specific ambiguities with examples, prioritising critical issues.
3. Offer actionable suggestions for resolution while maintaining appropriate style.

After the review:
1. Rewrite the document, incorporating all suggested improvements.
2. Ensure the rewritten version maintains the original intent and 'government speak' style.
3. Highlight significant changes made during the rewrite.

Maintain a professional, constructive tone throughout. The goal is to eliminate potential misunderstandings whilst preserving the expected communication style of the Singapore public service.
`

export const PROMPT_TEMPLATES: PromptTemplateType[] = [
  {
    title: 'Improve my writing while keeping its meaning',
    content: IMPROVE_WRITING_TEMPLATE.trim(),
  },
  {
    title: 'Write Python code to analyse my dataset',
    content: PYTHON_DATASET_ANALYSE_TEMPLATE.trim(),
  },
  {
    title: 'Respond to emails for me',
    content: EMAIL_RESPONSE_TEMPLATE.trim(),
  },
  {
    title: 'Critique my submission to resolve ambiguity',
    content: REPORT_REFINER_TEMPLATE.trim(),
  },
]
