import { RefObject, useEffect, useState } from 'react'

export function useAtBottom(
  offset = 0,
  elementRef: RefObject<HTMLElement> | null = null,
) {
  const [isAtBottom, setIsAtBottom] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      let scrollY: number
      let innerHeight: number
      let offsetHeight: number

      if (elementRef?.current) {
        scrollY = elementRef.current.scrollTop
        innerHeight = elementRef.current.clientHeight
        offsetHeight = elementRef.current.scrollHeight
      } else {
        scrollY = window.scrollY
        innerHeight = window.innerHeight
        offsetHeight = document.body.offsetHeight
      }

      setIsAtBottom(innerHeight + scrollY >= offsetHeight - offset)
    }

    const target = elementRef?.current ? elementRef.current : window

    target.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [offset, elementRef])

  return isAtBottom
}
