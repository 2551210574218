// Height of single line message ~ 72px, set as 80 as a best guess for a

import { DependencyList, useEffect } from 'react'

// good snap distance
const SINGLE_LINE_MESSAGE_HEIGHT = 72
const SNAP_BUFFER_HEIGHT = 8
const SCROLL_SNAP_DISTANCE = SINGLE_LINE_MESSAGE_HEIGHT + SNAP_BUFFER_HEIGHT

type useAutoScrollToBottomProps = {
  scrollElement?: HTMLDivElement | null
}

export function useAutoScrollToBottom(
  { scrollElement }: useAutoScrollToBottomProps,
  deps: DependencyList,
) {
  useEffect(() => {
    if (scrollElement) {
      const distanceToBottom =
        scrollElement.scrollHeight -
        scrollElement.scrollTop -
        scrollElement.clientHeight
      // We want to disable auto-scroll when the user has scrolled up past
      // the snap distance, so they can read older messages
      if (distanceToBottom < SCROLL_SNAP_DISTANCE) {
        scrollElement.scrollTo(0, scrollElement.scrollHeight)
      }
    }
  }, [scrollElement, deps])
}
