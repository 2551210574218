import { useCallback } from 'react'
import { Center, Spinner, VStack } from '@chakra-ui/react'

import { useAssistantPreviewContext } from '~features/assistants/hooks/useAssistantPreviewContext'
import { useBasicChatInputEditor, useChat } from '~features/chatv2'
import { ChatInputType } from '~features/chatv2/types'

import { AssistantPreviewChatInputsProps } from '../../types/info'
import { AssistantPreviewChatScreen } from '../AssistantPreview/AssistantPreviewChatScreen'

export const AssistantPreview = ({
  previewChatInputs,
  chatStateAndMutation,
  assistantId,
  isBlockSubmission,
  blockSubmissionMessage,
  resetChats,
}: {
  previewChatInputs: AssistantPreviewChatInputsProps
  chatStateAndMutation: ReturnType<typeof useChat>
  assistantId?: string
  isBlockSubmission?: boolean
  blockSubmissionMessage?: string
  resetChats?: () => void
}): JSX.Element => {
  const {
    state: { currentChatId, isUpdatingPreview },
  } = useAssistantPreviewContext(assistantId)

  // TODO: Refactor this out, just pass it down from AssistantEditorScreen
  const {
    handleSubmit,
    messages,
    isChatLoading,
    isMutationLoading,
    isStreaming,
    errorMessage,
    totalTokenCounts,
  } = chatStateAndMutation
  const onSubmit = useCallback(
    (input: ChatInputType) => handleSubmit(input, previewChatInputs),
    [handleSubmit, previewChatInputs],
  )

  const editor = useBasicChatInputEditor(
    {
      onSubmit,
    },
    [currentChatId, assistantId],
  )

  const isSubmissionDisabled =
    isMutationLoading || isStreaming || !!errorMessage || isBlockSubmission

  if (isChatLoading) {
    return (
      <Center flex={1}>
        <Spinner size="xl" />
      </Center>
    )
  }

  return (
    <VStack
      width="100%"
      justifyContent="space-between"
      spacing="0px"
      backgroundColor="base.canvas.alt-light"
    >
      <AssistantPreviewChatScreen
        assistantId={assistantId}
        messages={messages}
        isMutationLoading={isMutationLoading}
        isStreaming={isStreaming}
        isUpdatingPreview={isUpdatingPreview}
        errorMessage={errorMessage}
        editor={editor}
        isSubmissionDisabled={!!isSubmissionDisabled}
        submissionDisabledMessage={blockSubmissionMessage}
        totalTokenCounts={totalTokenCounts}
        // TODO: refactor this so we split previewChatInputs into the visual
        // only parts and the parts that affect editor
        previewVisualParameters={previewChatInputs}
        resetChats={resetChats}
      />
    </VStack>
  )
}
