import { useMutation, useQueryClient } from '@tanstack/react-query'

import { DeleteAssistantResponseDto } from '~shared/types/assistants.dto'

import { ApiService } from '~lib/api'
import { queries } from '~constants/queries'

export const deleteAssistant = async ({
  assistantId,
  uploadedConfi,
  deleteRelatedMessages,
}: {
  assistantId: string
  uploadedConfi: boolean
  deleteRelatedMessages: boolean
}) => {
  return ApiService.delete<DeleteAssistantResponseDto>(
    `/assistants/${assistantId}`,
    { params: { uploadedConfi, deleteRelatedMessages } },
  ).then((response) => response.data)
}

export function useDeleteAssistant() {
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation(deleteAssistant, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queries.assistants.list.queryKey,
      })
      await queryClient.invalidateQueries({
        queryKey: queries.chat.list.queryKey,
      })
      // Difficult to target which conversations to re-query, so invalidate all
      await queryClient.invalidateQueries({
        queryKey: queries.chat.detail._def,
      })
    },
  })

  return {
    isLoading,
    mutate,
  }
}
