import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const CreatedByMeHeroIcon = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.25"
      y="0.75"
      width="46.5"
      height="46.5"
      rx="23.25"
      stroke="#BFC2C8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="4 6"
    />
    <path
      d="M15.8359 24.1956C15.5598 24.1956 15.3359 24.4194 15.3359 24.6956C15.3359 24.9717 15.5598 25.1956 15.8359 25.1956C18.2821 25.1956 19.7771 26.168 20.687 27.5925C21.6198 29.0529 21.9709 31.0435 21.9709 33.1044C21.9709 33.3806 22.1948 33.6044 22.4709 33.6044C22.7471 33.6044 22.9709 33.3806 22.9709 33.1044C22.9709 31.0608 23.401 29.0608 24.3254 27.5893C25.2349 26.1414 26.6237 25.1956 28.632 25.1956C28.9081 25.1956 29.132 24.9717 29.132 24.6956C29.132 24.4194 28.9081 24.1956 28.632 24.1956C26.5744 24.1956 25.1957 23.4409 24.3103 22.188C23.4044 20.9062 22.9709 19.0401 22.9709 16.7539C22.9709 16.4778 22.7471 16.2539 22.4709 16.2539C22.1948 16.2539 21.9709 16.4778 21.9709 16.7539C21.9709 19.0588 21.6161 20.9132 20.7023 22.1848C19.8142 23.4207 18.3299 24.1956 15.8359 24.1956Z"
      stroke="#666C7A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6289 17.3742C28.4326 17.3742 28.2735 17.5334 28.2735 17.7297C28.2735 17.926 28.4326 18.0851 28.6289 18.0851C29.6925 18.0851 30.3142 18.4562 30.6845 18.9667C31.0696 19.4974 31.2223 20.2332 31.2223 21.0201C31.2223 21.2164 31.3815 21.3755 31.5778 21.3755C31.7741 21.3755 31.9332 21.2164 31.9332 21.0201C31.9332 20.2467 32.1184 19.5036 32.5032 18.9644C32.877 18.4404 33.4541 18.0851 34.316 18.0851C34.5123 18.0851 34.6715 17.926 34.6715 17.7297C34.6715 17.5334 34.5123 17.3742 34.316 17.3742C33.4221 17.3742 32.8499 17.086 32.491 16.6389C32.121 16.178 31.9332 15.4933 31.9332 14.6221C31.9332 14.4258 31.7741 14.2666 31.5778 14.2666C31.3815 14.2666 31.2223 14.4258 31.2223 14.6221C31.2223 15.5078 31.0667 16.1835 30.6968 16.6366C30.3396 17.0743 29.7231 17.3742 28.6289 17.3742Z"
      stroke="#838894"
      strokeWidth="0.710892"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
