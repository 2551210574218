import { forwardRef, MenuList, MenuListProps } from '@chakra-ui/react'

// Exports just the menulist for now
// NOTE: MenuList allows the passing of ref, hence a forwardRef to ensure same behaviour
export const UnanimatedMenuList = forwardRef<MenuListProps, typeof MenuList>(
  (props, ref) => (
    <MenuList
      {...props}
      ref={ref}
      motionProps={{
        initial: 'none',
        animate: 'none',
        exit: 'none',
      }}
    />
  ),
)
