import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  InputGroup,
  InputLeftAddon,
  Stack,
  useInterval,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import _ from 'lodash'
import { z } from 'zod'

import { OTP_TOKEN_LENGTH } from '~shared/constants'

import { useIsDesktop } from '~hooks/useIsDesktop'

import { UNKNOWN_LOGIN_ERROR_MESSAGE } from '../constants'

import { ResendOtpButton } from './ResendOtpButton'

const schema = z.object({
  token: z
    .string()
    .trim()
    .min(1, 'OTP is required.')
    .regex(/^[a-zA-Z0-9\b]+$/, {
      message: 'Only alpha-numeric characters are allowed.',
    })
    .length(
      OTP_TOKEN_LENGTH,
      `Please enter a ${OTP_TOKEN_LENGTH} length alphanumeric OTP.`,
    ),
})

export type OtpFormInputs = {
  token: string
}

interface OtpFormProps {
  email: string
  prefix: string
  onSubmit: (inputs: OtpFormInputs) => Promise<void>
  onResendOtp: () => Promise<void>
}

export const OtpForm = ({
  email,
  prefix,
  onSubmit,
  onResendOtp,
}: OtpFormProps): JSX.Element => {
  const { handleSubmit, register, formState, setError } =
    useForm<OtpFormInputs>({
      resolver: zodResolver(schema),
    })

  const isDesktop = useIsDesktop()

  const [timeoutTimer, setTimeoutTimer] = useState(0)

  useInterval(
    () => setTimeoutTimer(timeoutTimer - 1),
    // Stop interval if timer hits 0.
    timeoutTimer <= 0 ? null : 1000,
  )

  const onSubmitForm = async (inputs: OtpFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      const message = _.get(
        e,
        'response.data.message',
        UNKNOWN_LOGIN_ERROR_MESSAGE,
      )
      const errorStatus = _.get(e, 'response.status')

      setError('token', { type: 'server', message })
      // In the case of rate limits, we kick in a timeout counter
      if (errorStatus === 429) {
        const retryAfter = _.get(e, 'response.headers.retry-after')
        setTimeoutTimer(Number(retryAfter))
      }
    })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void handleSubmit(onSubmitForm)(e)
  }

  return (
    <form noValidate onSubmit={handleFormSubmit}>
      <FormControl isRequired isInvalid={!!formState.errors.token} mb="2.5rem">
        <FormLabel htmlFor="token">
          {`Enter OTP sent to ${email.toLowerCase()}`}
        </FormLabel>
        <InputGroup>
          <InputLeftAddon pointerEvents="none">{`${prefix}-`}</InputLeftAddon>
          <Input
            type="text"
            maxLength={OTP_TOKEN_LENGTH}
            inputMode="text"
            autoComplete="one-time-code"
            autoFocus
            isDisabled={timeoutTimer > 0}
            {...register('token')}
          />
        </InputGroup>

        <FormErrorMessage>{formState.errors.token?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          isDisabled={timeoutTimer > 0}
          type="submit"
          color="white"
        >
          Log in
        </Button>
        <ResendOtpButton onResendOtp={onResendOtp} />
      </Stack>
    </form>
  )
}
