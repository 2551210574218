import { defineStyleConfig } from '@chakra-ui/react'

import { textStyles } from '../foundations/textStyles'

export const Textarea = defineStyleConfig({
  baseStyle: {
    _placeholder: {
      ...textStyles['body-2'],
      color: 'interaction.support.placeholder',
    },
  },
  sizes: {
    md: textStyles['body-2'],
  },
})
