import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ChatInputType, IBasicEditor } from '../types'

type UseBasicChatInputEditorProps = {
  onSubmit: (input: ChatInputType) => void
  editable?: boolean
  placeholder?: string
}

// TODO: Deprecate this, will not need tip tap editor functionality anytime soon
/**
 * This is a placeholder component. The interfaces are made to resemble
 * tiptap's editor as far as possible, so its easy to migrate
 * @date 29/11/2023 - 00:07:52
 *
 * @export
 */
export function useBasicChatInputEditor(
  {
    onSubmit,
    editable = true,
    placeholder = 'Send a message.',
  }: UseBasicChatInputEditorProps,
  deps?: DependencyList,
): IBasicEditor {
  const [template, setTemplate] = useState('')

  // Use effect hook to clear templates on dismount and based on provided deps
  useEffect(() => {
    return () => setTemplate('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const handleTemplateChange = useCallback((content: string) => {
    setTemplate(content)
    inputRef.current?.focus()
  }, [])

  const submit = useCallback(
    ({ text, documents }: ChatInputType) => {
      onSubmit({ text, documents })
      // Clears template on submit
      setTemplate('')
    },
    [onSubmit],
  )

  const editor = useMemo(
    () => ({
      setTemplate: handleTemplateChange,
      template,
      submit,
      isEditable: editable,
      placeholder,
      inputRef,
    }),
    [
      handleTemplateChange,
      template,
      editable,
      placeholder,
      submit,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...(deps ?? []),
    ],
  )

  return editor
}
