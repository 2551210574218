import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { FeedbackTypesFilterEnumType } from '~shared/types/assistant-feedback.dto'

import {
  BASE_PAGE,
  FEEDBACK_TYPE_FILTER_LIST,
  PAGE_SIZE,
} from '../components/AssistantFeedbacks/constants'

const BASE_10 = 10
const BASE_FEEDBACK_TYPE_FILTER: FeedbackTypesFilterEnumType = 'all'

function parseFeedbackTypeFilterAndReturnDefault(
  parsedFeedbackTypeFilter: string | null,
): FeedbackTypesFilterEnumType {
  if (
    !parsedFeedbackTypeFilter ||
    !FEEDBACK_TYPE_FILTER_LIST.includes(parsedFeedbackTypeFilter)
  ) {
    return BASE_FEEDBACK_TYPE_FILTER
  }

  return parsedFeedbackTypeFilter as FeedbackTypesFilterEnumType
}

function getPreviousSearchParams(searchParams: URLSearchParams) {
  const params: Record<string, string> = {}
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }
  return params
}

export type AssistantFeedbacksSearchParams = Partial<{
  newPage: string | number
  feedbackTypeFilter: FeedbackTypesFilterEnumType
}>

export function useAssistantFeedbacksPaginationController({
  allowDynamicPageSize,
}: {
  allowDynamicPageSize: boolean
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  // Extract page and pageSize from the URL (searchParams)
  const page = parseInt(
    searchParams.get('page') || BASE_PAGE.toString(),
    BASE_10,
  )
  const pageSize = allowDynamicPageSize
    ? parseInt(searchParams.get('pageSize') || PAGE_SIZE.toString(), BASE_10)
    : PAGE_SIZE
  const feedbackTypesFilter = parseFeedbackTypeFilterAndReturnDefault(
    searchParams.get('feedback_type_filter'),
  )

  const handlePaginationClick = ({
    newPage,
    feedbackTypeFilter,
  }: AssistantFeedbacksSearchParams) => {
    setSearchParams(
      () => {
        return {
          ...getPreviousSearchParams(searchParams),
          ...(newPage && { page: String(newPage) }),
          ...(feedbackTypeFilter && {
            feedback_type_filter: feedbackTypeFilter,
          }),
        }
      },
      { state: location.state, replace: true },
    )
  }

  useEffect(() => {
    // Only run on mount to ensure full synchronisation of the url
    setSearchParams(
      {
        page: searchParams.get('page') || BASE_PAGE.toString(),
        pageSize: allowDynamicPageSize
          ? searchParams.get('pageSize') || PAGE_SIZE.toString()
          : PAGE_SIZE.toString(),
        feedback_type_filter: parseFeedbackTypeFilterAndReturnDefault(
          searchParams.get('feedback_type_filter'),
        ),
      },
      // Preserve any states
      { replace: true, state: location.state },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    page,
    pageSize,
    handlePaginationClick,
    feedbackTypesFilter,
  }
}
