import {
  BANNED_GOVERNMENT_DOMAINS,
  HEALTHCARE_EMAIL_SUFFIXES,
  VAPT_EMAIL_SUFFIXES,
  WHITELISTED_EMAIL_SUFFIXES,
} from '../constants'

import {
  createBulkEmailValidator,
  createEmailValidator,
} from './email-validator'

export { IsValidEmail } from './email-validator'

export const INVALID_LOGIN_EMAIL_DOMAIN_MESSAGE =
  'Please sign in with a .gov.sg or other allowed email address.'

export const INVALID_EMAIL_DOMAIN_MESSAGE =
  'One or more of the emails is not a .gov.sg or other allowed email address.'

export const isOpenGovSgEmail = createEmailValidator('@open.gov.sg')
export const isGovSgEmail = createEmailValidator('.gov.sg')
export const isBannedGovEmail = createBulkEmailValidator(
  BANNED_GOVERNMENT_DOMAINS,
)
export const isHealthcareEmail = createBulkEmailValidator(
  HEALTHCARE_EMAIL_SUFFIXES,
)

export const isAllowedToSignupEmailSuffix = createBulkEmailValidator([
  ...WHITELISTED_EMAIL_SUFFIXES,
  ...HEALTHCARE_EMAIL_SUFFIXES,
])

export const isAllowedToAttemptLoginEmailSuffix = createBulkEmailValidator([
  ...WHITELISTED_EMAIL_SUFFIXES,
  ...HEALTHCARE_EMAIL_SUFFIXES,
  ...VAPT_EMAIL_SUFFIXES,
])
