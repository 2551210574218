import { useMutation, useQueryClient } from '@tanstack/react-query'

import { CreateAssistantFeedbackRequestDto } from '~shared/types/assistant-feedback.dto'

import { queries } from '~constants/queries'
import { useCustomToast } from '~hooks/useCustomToast'

import { createAssistantFeedback } from '../api'

export function useCreateAssistantFeedback(assistantId: string) {
  const { createErrorToast, createSuccessToast } = useCustomToast()
  const queryClient = useQueryClient()

  const { isLoading: isCreateAssistantFeedbackLoading, mutate } = useMutation(
    async ({ ...data }: CreateAssistantFeedbackRequestDto) =>
      await createAssistantFeedback({ assistantId, ...data }),
    {
      onSuccess: async () => {
        createSuccessToast({
          content: 'Your feedback for this assistant has been sent.',
        })
        await queryClient.invalidateQueries({
          queryKey: queries.assistants.feedbacks(assistantId).queryKey,
        })
      },
      onError: () => {
        createErrorToast({
          content:
            'An unexpected error has occurred while submitting assistant feedback.',
        })
      },
    },
  )

  return {
    isCreateAssistantFeedbackLoading,
    createAssistantFeedback: mutate,
  }
}
